export default {
  // Base strat with default values, used in strategy editors
  // Does not include extras like MM
  defaultStrat: {
    ADX_ENABLED: false,
    ADX_LEVEL: 25,
    ATRX: 0.5,
    ATR_PERIOD: 14,
    BTC_MONEY_FLOW: 35,
    BTC_PND_PERIOD: 14,
    BTC_PND_PROTECTION: false,
    BUYLVL: 1,
    BUYLVL1: 0.6,
    BUYLVL2: 2,
    BUYLVL3: 70,
    BUY_ENABLED: true,
    SINGLE_BUY: false,
    BUY_LEVEL: 1,
    BUY_METHOD: 'gain',
    BUY_RANGE: 0.5,
    CANDLES_LENGTH: 99,
    COUNT_SELL: 9999,
    DISPLACEMENT: 26,
    DI_PERIOD: 14,
    DOUBLE_CHECK_GAIN: true,
    DOUBLE_UP: false,
    DOUBLE_UP_CAP: 1,
    DU_BUYDOWN: 2,
    DU_CAP_COUNT: 0,
    DU_METHOD: 'HIGHBB',
    EMA1: 16,
    EMA2: 8,
    EMA3: 150,
    EMASPREAD: false,
    EMA_LENGTH: 50,
    EMAx: 0.5,
    FAST_SMA: 1,
    FUNDS_RESERVE: 0,
    GAIN: 0.5,
    HIGH_BB: 0,
    ICHIMOKU_PROTECTION: true,
    IGNORE_TRADES_BEFORE: 0,
    LIQUIDITY: false,
    LIQUIDITY_TAKER: false,
    LIQUIDITY_GAIN: true,
    MAX_INVESTMENT: 99999999999999999,
    IS_MARGIN_STRAT: false,
    KEEP_QUOTE: 0,
    KIJUN_BUY: false,
    KIJUN_CLOSE: false,
    KIJUN_PERIOD: 26,
    KIJUN_SELL: false,
    KIJUN_STOP: false,
    KUMO_BUY: false,
    KUMO_CLOSE: false,
    KUMO_SELL: false,
    KUMO_SENTIMENTS: true,
    KUMO_STOP: true,
    LEVERAGE: 0,
    LONG_LEVEL: 1,
    LOW_BB: 0,
    MACD_LONG: 20,
    MACD_SHORT: 5,
    MACD_SIGNAL: 10,
    MAKER_FEES: false,
    TAKER_FEES: false,
    MARKET_BUY: false,
    MARKET_BUYBACK: false,
    MARKET_CLOSE: false,
    MARKET_DU: false,
    MARKET_FOK: false,
    MARKET_RTBUY: false,
    MARKET_RTSELL: false,
    MARKET_SELL: false,
    MARKET_STOP: false,
    MEAN_REVERSION: false,
    MFI_BUY_LEVEL: 30,
    MFI_ENABLED: false,
    MFI_LENGTH: 14,
    MFI_SELL_LEVEL: 70,
    MIN_VOLUME_TO_BUY: 0.001,
    MIN_VOLUME_TO_SELL: 0.001,
    NBA: 0,
    PANIC_SELL: false,
    PERIOD: 15,
    PP_BUY: 0,
    PP_SELL: 99999,
    PRE_ORDER: false,
    PRE_ORDER_GAP: 0,
    RENKO_ATR: false,
    RENKO_BRICK_SIZE: 0.0001,
    RENKO_PERIOD: 15,
    ROE: 1,
    ROE_CLOSE: false,
    ROE_LIMIT: 1,
    ROE_TRAILING: false,
    ROE_SCALPER: false,
    ROE_SPREAD: 0,
    RSI_BUY_ENABLED: false,
    RSI_BUY_LEVEL: 30,
    RSI_DU_BUY: 30,
    RSI_LENGTH: 14,
    RSI_METHOD: 'oscillator',
    RSI_SELL_ENABLED: false,
    RSI_SELL_LEVEL: 70,
    RT_BUY_LEVEL: 2,
    RT_BUY_UP_LEVEL: 0,
    RT_ENABLED: false,
    RT_GAIN: 1.5,
    RT_MAXBAG_PROTECTION: 10,
    RT_ONCE: false,
    RT_ONCE_AND_CONTINUE: false,
    RT_SELL_UP: 0.3,
    RT_TREND_ENABLED: false,
    SELLLVL: 1,
    SELLLVL1: 0.6,
    SELLLVL2: 2,
    SELLLVL3: 70,
    SELL_ENABLED: true,
    SELL_METHOD: 'gain',
    SELL_RANGE: 0.5,
    SENKOUSPAN_PERIOD: 52,
    SHORT_LEVEL: 1,
    SLOW_SMA: 2,
    SLOW_STOCH_K: 3,
    SL_DISABLE_BUY: false,
    SL_DISABLE_SELL: false,
    SMAPERIOD: 50,
    STDV: 2,
    STOCHRSI_BUY_LEVEL: 0.2,
    STOCHRSI_ENABLED: false,
    STOCHRSI_LENGTH: 14,
    STOCHRSI_METHOD: 'oscillator',
    STOCHRSI_SELL_LEVEL: 0.8,
    STOCH_BUY_LEVEL: 20,
    STOCH_D: 3,
    STOCH_ENABLED: false,
    STOCH_K: 14,
    STOCH_METHOD: 'oscillator',
    STOCH_SELL_LEVEL: 80,
    STOP_LIMIT: 99999,
    TAKE_BUY: false,
    TAKE_PROFIT: false,
    TBUY_RANGE: 0.5,
    TENKAN_BUY: true,
    TENKAN_CLOSE: true,
    TENKAN_PERIOD: 9,
    TENKAN_SELL: true,
    TENKAN_STOP: false,
    TL_ALLIN: false,
    TL_PERC: 0,
    TM_RT_SELL: false,
    TP_PROFIT_ONLY: true,
    TP_RANGE: 0.5,
    TRADES_TIMEOUT: 0,
    TRADING_LIMIT: 0.002,
    TRAIL_ME_BUY: false,
    TRAIL_ME_BUY_RANGE: 0.5,
    TRAIL_ME_DU: false,
    TRAIL_ME_RT: false,
    TRAIL_ME_RT_SELL_RANGE: 0.5,
    TRAIL_ME_SELL: false,
    TRAIL_ME_SELL_RANGE: 0.5,
    TSSL_TARGET_ONLY: true,
    USE_RENKO: false,
    XTREND_ENABLED: true,
    STOP_BUY: 0,
    STOP_SELL: 0,
    PND: false,
    PND_PROTECTION: 1.5,
    SupportResistance: false,
    SupRes_ALLOW_DCA: true,
    SupRes_SPREAD: 0.1,
    SupRes_LVL_SPREAD: 1,
    SupRes_MAX: 0,
    SupRes_TIMER: 300,
    SupResMinROE: 20,
    MAX_BUY_COUNT: 20,
    GRID_MULTIPLIER: 1,
    STOP_AFTER_SELL: false,
    AUTO_GAIN: true,
    TRAILING_MULTIPLIER: 1,
    START_CONT_TRADING: 3,
    CT_TL_MULTIPLIER: 0.5,
    CT_RESTART_MULTIPLIER: 1,
    TL_MULTIPLIER: 1,
    MAX_OPEN_CONTRACTS: 1,
    DCA_METHOD: 'NATIVE',
    DCA_SPREAD: 2,
    SAFETY_TIMER: 1800,
    TREND_OPEN: false,
    TREND_BLOCK_DCA: false,
    TREND_LOWER_DCA: false,
    DIRECTION: 'LONG',
    TREND_CT_MULTIPLIER: 2,
    TREND_GRID_MULTIPLIER: 2,
    AUTO_STEP_SIZE: true,
    STEP_SIZE: 500,
    ENFORCE_STEP: false,
    STRAT_FILENAME: 'filename.js',
    unit_cost: true,
    DYNAMIC_EXIT_LOGIC: false,
    bitRage: false,
    FIRST_ORDER_EXTRA_DELAY: 30,
    TREND_TRAILING: true,
    TREND_TRAILING_MULTIPLIER: 1,
    TREND_TRAILING_BEARISH_MULTIPLIER: 2,
    AUTO_TREND_ORDERS: true,
    GAIN_PARTIAL: 0.5,
    PARTIAL_SELL_CAP: false,
    PARTIAL_SELL_CAP_RATIO: 1,
    SUPPORT_TL_RATIO: 2,
    TREND_PLUS: true,
    TREND_PLUS_BUY_MULTIPLIER_SMALL: 1,
    TREND_PLUS_BUY_MULTIPLIER_MEDIUM: 2,
    TREND_PLUS_BUY_MULTIPLIER_LARGE: 5,
    TREND_PLUS_SELL_MULTIPLIER_SMALL: 0.5,
    TREND_PLUS_SELL_MULTIPLIER_MEDIUM: 2,
    TREND_PLUS_SELL_MULTIPLIER_LARGE: 5,
    TREND_SCALPING: true,
    SCALP_TL_RATIO: 0.625,
    EXHAUSTION_SENSITIVITY: 'SHORT',
    STRICT_ENTRY: true,
    PERIOD_MEDIUM: 60,
    PERIOD_LONG: 240,
    TRADE_SUPPORTS: true,
    SUPPORT2_TL_RATIO: 2,
    TREND_SYNC: true,
    MULTIPLE_TIMEFRAMES_MODE: false,
    LOWER_PERIOD_LOW: 5,
    LOWER_PERIOD_MEDIUM: 15,
    LOWER_PERIOD_HIGH: 30,
    MIDDLE_PERIOD_LOW: 15,
    MIDDLE_PERIOD_MEDIUM: 60,
    MIDDLE_PERIOD_HIGH: 240,
    ACCUMULATION_CYCLE: false,
    MTF_TL_RATIO: 1,
    PRICE_ACTION_TL_RATIO: 1,
    PRICE_ACTION_THRESHOLD: 0,
    ALWAYS_USE_TL_MULTIPLIER: false,
    PANIC_CLOSE: false,
  },

  // Defaults for MM related strategy parameters
  defaultStratMM: {
    MAX_BUY: 5,
    MAX_SELL: 5,
    CANCEL_SPREAD: 0.05,
    FIRST_ORDER_SPREAD: 0.02,
    FLIP: true,
    MM_SPREAD: 0.02,
    SR_RATIO: 1,
    SR_INVESTED_PERC: 0.01,
    SR_SPREAD: 0.05,
    SR_LIMIT_LONG: 1,
    SR_LIMIT_SHORT: 1,
    REDUCING_RATIO: 4,
    INITIAL_CAPITAL: 0.01,
    CAPITAL_RISK: 0.01,
    INVESTED_PERC: 0.01,
    MAX_OPEN_CONTRACTS: 999999,
    MAX_LOSS: 999999,
    FIBONACCI: false,
    FIB_DCA_PERC: 0.3,
    DCA_METHOD: 'NATIVE',
    SupportResistance: false,
    SupRes_ALLOW_DCA: false,
    SupRes_SPREAD: 0.2,
    SupRes_LVL_SPREAD: 1,
    SupRes_MAX: 200,
    SupRes_TIMER: 300,
    SupResMinROE: 20,
    GRID: false,
    GRID_SPREAD: 0.03,
    GRID_SIDE: 'LONG',
    GRID_MAX: 200,
    GRID_TIMER: 20,
    x125: false,
    DCA_PRICE_SPREAD: false,
    DCA_SPREAD: 2,
    MAX_DCA_ORDERS: 10,
    DCA_SPREAD_CAP: 5,
    MOTION_OF_THE_OCEAN: false,
    oneSCALPER: false,
    oneSCALPER_DCA: false,
    oneSCALPER_TIMER: 1,
    MOTO_DCA_PERC: 0.5,
    MOTO_SIDE: 'BOTH',
    MOTO_TIMER: 900,
    MOTO_DCA_MULTIPLIER: 1,
    PULLBACK: false,
    PULLBACK_DCA_PERC: 0.5,
    PULLBACK_SIDE: 'BOTH',
    PULLBACK_DCA_MULTIPLIER: 1,
    PULLBACK_ENABLED: false,
    PRE_ORDER_GAP: 0,
    HEDGE_MODE: 'BOTH',
    MM_DCA: false,
    MIN_VOLUME_TO_SELL: 0.001,
    IS_MARGIN_STRAT: true,
    GAIN: 0.1,
    INTER_ARB: false,
    LIQUIDITY_MINING: false,
    EWO: false,
  },

  // Defaults for BR related strategy parameters
  defaultStratBR: {
    bitRage: true,
    WAIT_OPEN_ORDERS: true,
    bitRage_MODE: 'safe',
    AUTOSELL: false,
    MAIN_BASE: 'BTC',
    BR_PANIC_SELL: false,
    PSAR_PERIOD: 360,
    PSAR_STEP: 0.02,
    PSAR_MAX: 0.2,
    CLOSE_SPREAD: false,
    BUY_ENABLED: false,
    MAX_BUY: 1,
    MAX_SELL: 1,
    BR_SMART_MODE: true,
    BR_SLOW_MODE: true,
  },

  // List of buy and sell methods, used in strategy editor (bitRage is fictional, used to open generate a strat that uses the bitrage strat editor)
  buySellMethods: [
    'gridbot',
    'gridbotadvanced',
    'emotionless',
    'bb',
    'gain',
    'ichimoku',
    'pp',
    'stepgain',
    'spotGrid',
    'spotGridAdvanced',
    'stepGrid',
    'futuresGrid',
    'tsa',
    'tssl',
    'TENKAN',
    'ADX',
    'ATRTS',
    'BBTA',
    'EMASPREAD',
    'MACD',
    'MACDH',
    'SMACROSS',
    'SupportResistance',
    'Market_Maker',
    'bitRage',
    'custom',
  ],

  // List of available pair overrides
  overrides: [
    'IS_MARGIN_STRAT',
    'STOP_BUY',
    'STOP_SELL',
    'SINGLE_BUY',
    'ROE_LIMIT',
    'ROE_TRAILING',
    'ROE_SCALPER',
    'ROE_SPREAD',
    'MAKER_FEES',
    'TAKER_FEES',
    'MARKET_BUY',
    'MARKET_SELL',
    'MARKET_CLOSE',
    'MARKET_STOP',
    'MARKET_RTBUY',
    'MARKET_RTSELL',
    'MARKET_BUYBACK',
    'MARKET_DU',
    'MARKET_FOK',
    'USE_RENKO',
    'SHORT_LEVEL',
    'LONG_LEVEL',
    'ROE',
    'TENKAN_CLOSE',
    'KUMO_CLOSE',
    'KIJUN_CLOSE',
    'TENKAN_STOP',
    'KUMO_STOP',
    'KIJUN_STOP',
    'RENKO_PERIOD',
    'RENKO_BRICK_SIZE',
    'RENKO_ATR',
    'LEVERAGE',
    'ICHIMOKU_PROTECTION',
    'XTREND_ENABLED',
    'DISPLACEMENT',
    'RT_BUY_UP_LEVEL',
    'DOUBLE_CHECK_GAIN',
    'NBA',
    'SL_DISABLE_BUY',
    'COUNT_SELL',
    'BTC_PND_PERIOD',
    'STOCHRSI_ENABLED',
    'STOCHRSI_LENGTH',
    'STOCHRSI_BUY_LEVEL',
    'STOCHRSI_SELL_LEVEL',
    'STOCHRSI_METHOD',
    'RT_ONCE',
    'RT_ONCE_AND_CONTINUE',
    'TP_PROFIT_ONLY',
    'TAKE_BUY',
    'TBUY_RANGE',
    'TENKAN_PERIOD',
    'KIJUN_PERIOD',
    'SENKOUSPAN_PERIOD',
    'KEEP_QUOTE',
    'TL_PERC',
    'TL_ALLIN',
    'TAKE_PROFIT',
    'TP_RANGE',
    'EMAx',
    'BOUGHT_PRICE',
    'PP_BUY',
    'PP_SELL',
    'BUYLVL',
    'BUYLVL1',
    'BUYLVL2',
    'BUYLVL3',
    'SELLLVL',
    'SELLLVL1',
    'SELLLVL2',
    'SELLLVL3',
    'ATRX',
    'ATR_PERIOD',
    'RSI_METHOD',
    'STOCH_METHOD',
    'KUMO_SENTIMENTS',
    'CANDLES_LENGTH',
    'EMA_LENGTH',
    'MFI_LENGTH',
    'RT_SELL_UP',
    'RT_TREND_ENABLED',
    'RT_BUY_LEVEL',
    'RT_GAIN',
    'BUY_METHOD',
    'BUY_ENABLED',
    'BUY_LEVEL',
    'BUY_RANGE',
    'SELL_METHOD',
    'SELL_ENABLED',
    'GAIN',
    'SELL_RANGE',
    'TSSL_TARGET_ONLY',
    'TRADING_LIMIT',
    'FUNDS_RESERVE',
    'PERIOD',
    'EMA1',
    'EMA2',
    'EMA3',
    'HIGH_BB',
    'LOW_BB',
    'STDV',
    'SMAPERIOD',
    'SLOW_SMA',
    'FAST_SMA',
    'MACD_SHORT',
    'MACD_LONG',
    'MACD_SIGNAL',
    'STOCH_ENABLED',
    'STOCH_BUY_LEVEL',
    'STOCH_SELL_LEVEL',
    'STOCH_K',
    'SLOW_STOCH_K',
    'STOCH_D',
    'DI_PERIOD',
    'EMASPREAD',
    'ADX_LEVEL',
    'ADX_ENABLED',
    'RSI_BUY_ENABLED',
    'RSI_SELL_ENABLED',
    'RSI_BUY_LEVEL',
    'RSI_SELL_LEVEL',
    'RSI_LENGTH',
    'MFI_ENABLED',
    'MFI_SELL_LEVEL',
    'MFI_BUY_LEVEL',
    'BTC_PND_PROTECTION',
    'BTC_MONEY_FLOW',
    'DOUBLE_UP',
    'DOUBLE_UP_CAP',
    'DU_CAP_COUNT',
    'DU_METHOD',
    'RSI_DU_BUY',
    'RT_ENABLED',
    'RT_MAXBAG_PROTECTION',
    'DU_BUYDOWN',
    'TRAIL_ME_BUY',
    'TRAIL_ME_SELL',
    'TRAIL_ME_RT_SELL_RANGE',
    'TM_RT_SELL',
    'TRAIL_ME_RT',
    'TRAIL_ME_DU',
    'TRAIL_ME_SELL_RANGE',
    'TRAIL_ME_BUY_RANGE',
    'PANIC_SELL',
    'STOP_LIMIT',
    'MIN_VOLUME_TO_BUY',
    'MIN_VOLUME_TO_SELL',
    'TRADES_TIMEOUT',
    'IGNORE_TRADES_BEFORE',
    'LIQUIDITY',
    'LIQUIDITY_TAKER',
    'LIQUIDITY_GAIN',
    'MAX_INVESTMENT',
    'MEAN_REVERSION',
    'GRID',
    'MAX_BUY',
    'MAX_SELL',
    'TIMEOUT_CLOSE',
    'CAPITAL_RISK',
    'REDUCING_RATIO',
    'INVESTED_PERC',
    'MAX_LOSS',
    'SR_INVESTED_PERC',
    'SR_RATIO',
    'SR_LIMIT_LONG',
    'SR_LIMIT_SHORT',
    'INITIAL_CAPITAL',
    'bitRage',
    'WAIT_OPEN_ORDERS',
    'bitRage_MODE',
    'BR_SMART_MODE',
    'BR_SLOW_MODE',
    'CLOSE_SPREAD',
    'AUTOSELL',
    'MAIN_BASE',
    'BR_PANIC_SELL',
    'PSAR_PERIOD',
    'PSAR_STEP',
    'PSAR_MAX',
    'SupportResistance',
    'SupRes_ALLOW_DCA',
    'SupRes_SPREAD',
    'SupRes_LVL_SPREAD',
    'CANCEL_SPREAD',
    'FIRST_ORDER_SPREAD',
    'FLIP',
    'MM_SPREAD',
    'SR_SPREAD',
    'MAX_OPEN_CONTRACTS',
    'FIBONACCI',
    'FIB_DCA_PERC',
    'DCA_METHOD',
    'SupRes_MAX',
    'SupRes_TIMER',
    'SupResMinROE',
    'GRID_SPREAD',
    'GRID_SIDE',
    'GRID_MAX',
    'GRID_TIMER',
    'x125',
    'DCA_PRICE_SPREAD',
    'DCA_SPREAD',
    'MAX_DCA_ORDERS',
    'DCA_SPREAD_CAP',
    'HEDGE_MODE',
    'MOTION_OF_THE_OCEAN',
    'oneSCALPER',
    'oneSCALPER_DCA',
    'oneSCALPER_TIMER',
    'MOTO_DCA_PERC',
    'MOTO_SIDE',
    'MOTO_DCA_MULTIPLIER',
    'MOTO_TIMER',
    'PND',
    'PND_PROTECTION',
    'PULLBACK',
    'PULLBACK_DCA_PERC',
    'PULLBACK_SIDE',
    'PULLBACK_DCA_MULTIPLIER',
    'PULLBACK_ENABLED',
    'MM_DCA',
    'LIQUIDITY_MINING',
    'INTER_ARB',
    'MAX_BUY_COUNT',
    'GRID_MULTIPLIER',
    'STOP_AFTER_SELL',
    'AUTO_GAIN',
    'EWO',
    'TRAILING_MULTIPLIER',
    'START_CONT_TRADING',
    'TL_MULTIPLIER',
    'CT_TL_MULTIPLIER',
    'SAFETY_TIMER',
    'CT_RESTART_MULTIPLIER',
    'TREND_OPEN',
    'TREND_BLOCK_DCA',
    'TREND_LOWER_DCA',
    'DIRECTION',
    'TREND_CT_MULTIPLIER',
    'TREND_GRID_MULTIPLIER',
    'AUTO_STEP_SIZE',
    'STEP_SIZE',
    'ENFORCE_STEP',
    'STRAT_FILENAME',
    'TREND_TRAILING',
    'TREND_TRAILING_MULTIPLIER',
    'TREND_TRAILING_BEARISH_MULTIPLIER',
    'AUTO_TREND_ORDERS',
    'PROTECT_PARTIAL_SELL',
    'TREND_VARIANT',
    'BUYDOWN_EXPONENT',
    'MIN_BUYDOWN',
    'KEEP_ONE_TL',
    'MIN_STEP_PCT',
    'MIN_STEP_UP_PCT',
    'MIN_STEP_DOWN_PCT',
    'GAIN_PARTIAL',
    'PARTIAL_SELL_CAP',
    'PARTIAL_SELL_CAP_RATIO',
    'SUPPORT_TL_RATIO',
    'TREND_PLUS',
    'TREND_PLUS_BUY_MULTIPLIER_SMALL',
    'TREND_PLUS_BUY_MULTIPLIER_MEDIUM',
    'TREND_PLUS_BUY_MULTIPLIER_LARGE',
    'TREND_PLUS_SELL_MULTIPLIER_SMALL',
    'TREND_PLUS_SELL_MULTIPLIER_MEDIUM',
    'TREND_PLUS_SELL_MULTIPLIER_LARGE',
    'TREND_SCALPING',
    'SCALP_TL_RATIO',
    'EXHAUSTION_SENSITIVITY',
    'STRICT_ENTRY',
    'PERIOD_MEDIUM',
    'PERIOD_LONG',
    'unit_cost',
    'TRADE_SUPPORTS',
    'SUPPORT2_TL_RATIO',
    'MULTIPLE_TIMEFRAMES_MODE',
    'LOWER_PERIOD_LOW',
    'LOWER_PERIOD_MEDIUM',
    'LOWER_PERIOD_HIGH',
    'MIDDLE_PERIOD_LOW',
    'MIDDLE_PERIOD_MEDIUM',
    'MIDDLE_PERIOD_HIGH',
    'ACCUMULATION_CYCLE',
    'MTF_TL_RATIO',
    'PRICE_ACTION_TL_RATIO',
    'PRICE_ACTION_THRESHOLD',
    'ALWAYS_USE_TL_MULTIPLIER',
    'PANIC_CLOSE',
    'BTFD_MODE',
    'BTFD_TREND_FILTER',
    'BTFD_AUTO_TARGET',
    'BTFD_DIP_TARGET',
    'BTFD_MAX_DIP_TARGET',
    'BTFD_TL_RATIO',
    'BTFD_MAX_ORDERS',
    'BTFD_GAIN',
    'BTFD_PCT_STEP_SIZE',
    'BTFD_STEP_SIZE',
    'SCALP_INIT_BUY_MULTIPLIER'
  ],

  // Tooltip texts
  tooltips: {
    SUBACCOUNT: 'Subaccount name for the API key',
    TOURNAMENT_AGREEMENT: 'Opt-in to share strategy data for trading tournaments',
    TOURNAMENT_NICKNAME: 'Nickname for tournament leaderboard',
    gunthy_wallet: 'Your registered ERC-20 wallet address',
    SINGLE_BUY: 'Allow one strategy buy order',
    ADX_ENABLED: 'Use ADX as a confirming indicator',
    ADX_LEVEL: 'Set minimum trend level for trades (1-99)',
    ATRX: 'Multiplier for calculating ATRTS trade limits',
    ATR_PERIOD: 'Number of candles used to calculate ATR',
    BEST_CURRENCIES: 'True/False - get the best pairs by volume and variance',
    BOT_CCLEAN: 'Clear internal cache every x hours',
    BOT_DELAY: 'Delay processing new pairs for a random amount of seconds',
    BTC_MONEY_FLOW:
      "Sets the MFI value used for BTC PND protection \n\nWhen BTC/USD MFI drops below this value buy orders won't be placed",
    BTC_PND_PERIOD: 'Define MFI period to use for BTC PND protection',
    BTC_PND_PROTECTION: 'Prevent buy orders when BTC/USD MFI is below a set level',
    BUYLVL: 'Defines which step should be considered for buying',
    BUYLVL1: 'Defines the first level below the lowest EMA to buy \n\nValue represents a percentage',
    BUYLVL2: 'Defines the second level below the lowest EMA to buy \n\nValue represents a percentage',
    BUYLVL3: 'Defines the third level below the lowest EMA to buy \n\nValue represents a percentage',
    BUY_ENABLED: 'Allow buy or long orders',
    BUY_LEVEL: 'Percentage below the lowest EMA to allow buy orders',
    BUY_METHOD: 'Sets the method used for strategy buy orders',
    BUY_RANGE: 'Sets the buy range for trailing \n\nValue represents a percentage',
    CANCEL_ORDERS_CYCLE_CAP: 'Number of rounds to wait before open orders are cancelled',
    CANCEL_ORDERS_ENABLED: 'Allow the bot to cancel open orders for active trading pairs when price moves away',
    CANCEL_ONCAP: 'Change the behavior of cancelling orders: orders are cancelled after CANCEL_ORDERS_CYCLE_CAP passes',
    CANDLES_LENGTH:
      'The number of candles to request from the exchange \n\nMake sure this value is at least equal to the highest period setting of any of your indicators',
    COUNT_SELL:
      'Disable a pair after a number of sell orders \n\nValue represents a number of sell orders since adding the pair',
    DEPTH_PRESSURE_LEVEL:
      'Filter for bullish pairs: 99 means all orders on the ask side. 1 means all orders on the bid side, percentage',
    DISPLACEMENT: 'Number of candles to displace Chikou-span and Kumo',
    DI_PERIOD: 'Number of candles used to calculate ADX',
    DOUBLE_CHECK_GAIN:
      'Makes sure a positive gain is reached before selling\n\nDisable to allow indicators to sell at loss',
    DOUBLE_UP: 'Use Dollar Cost Averaging (DCA)',
    DOUBLE_UP_CAP:
      'Number of units to buy, based on quote balance\n\nSetting to 1 leads to each DCA order buying the same number of quote units as you already own',
    DU_BUYDOWN: 'Minimum price drop between last buy and next DCA order\n\nValue is a percentage',
    DU_CAP_COUNT: 'Maximum number of DCA orders allowed',
    DU_METHOD:
      'Method for triggering DCA orders\n\nOptions:\n\nHIGHBB: DCA when upper bollinger band drops below last buy price\n\nRSI: DCA when RSI is lower than set\n\nNumerical: DCA from a set percentage below last buy price',
    EMA1: 'The number of candles used to calculate medium EMA',
    EMA2: 'The number of candles used to calculate fast EMA',
    EMA3: 'The number of candles used to calculate long EMA',
    EMASPREAD: 'Use EMASPREAD as a confirming indicator for both buy and sell orders',
    EMA_LENGTH: 'The number of candles used to calculate EMA',
    EMAx: 'Sets the minimum difference between fast and medium for EMASPREAD \n\nValue represents a percentage',
    FAST_SMA: 'The number of candles used to calculate fast SMA',
    FUNDS_RESERVE:
      'The amount of base currency that will not be traded \n\nNo more buy orders are allowed when the available funds drops below this value',
    GAIN: 'Gain target above break-even \n\nValue represents a percentage',
    HIGH_BB: 'The HIGH_BB target \n\nValue represents a percentage: 0 - 100, top to bottom',
    ICHIMOKU_PROTECTION:
      'Prevent closing positions if the current candle touches kumo on opposite direction of your position',
    IGNORE_TRADES_BEFORE:
      'Optional parameter to force Gunbot to not consider any trades before the time set \n\nValue represents a unix timestamp in milliseconds. Get a current timestamp on currentmillis.com',
    LIQUIDITY:
      'Enables the liquidity provider strategy at spot exchanges \n\nWarning: this will generate an extremely high number of trades, only use this when you want to provide liquidity and know the risks',
    LIQUIDITY_TAKER: 'Enables the taker side of liquidity strategy',
    MAX_INVESTMENT: ' The max amount of base value you want to invest in liquidity strategy',
    LIQUIDITY_GAIN: 'Enforce profitable sell orders. Turn off for liquidity at possible loss',
    IS_MARGIN_STRAT: 'Enable if this a futures strategy?\n\nNeeded for interface elements',
    KEEP_QUOTE: 'Number of quote units to keep in balance',
    KIJUN_BUY: 'Buy using Kijun',
    KIJUN_CLOSE: 'Close position after crossing Kijun-sen',
    KIJUN_PERIOD: 'Number of candles to calculate Kijun-sen',
    KIJUN_SELL: 'Sell using Kijun',
    KIJUN_STOP: 'Stop position after crossing Kijun-sen',
    KUMO_BUY: 'Buy using Kumo',
    KUMO_CLOSE: 'Close position after crossing Kumo',
    KUMO_SELL: 'Sell using Kumo',
    KUMO_SENTIMENTS: 'Turn off to disregard Kumo when trading with ichimoku',
    KUMO_STOP: 'Stop position after crossing Kumo',
    LEVERAGE: 'Desired leverage level for a position\n\nSet 0 for cross margin',
    LONG_LEVEL: 'Percentage from EMA to allow long orders',
    LOW_BB: 'The LOW_BB target\n\nValue is a percentage: 0 - 100, bottom to top',
    MACD_LONG: 'The number of candles used to calculate the long-term moving average for MACD',
    MACD_SHORT: 'The number of candles used to calculate the short-term moving average for MACD',
    MACD_SIGNAL: 'The number of candles used to calculate the MACD Signal line',
    MAKER_FEES: 'Use Post Only orders at Bitmex \n\nShould normally be used together with a negative pre order gap',
    MARKET_BUY: 'Place buy orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_BUYBACK: 'Place RT buyback orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_CLOSE: 'Place close orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_DU: 'Place Double Up orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_FOK: 'Place FOK orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_RTBUY: 'Place RT Buy orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_RTSELL: 'Place RT Sell orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_SELL: 'Place sell orders as market \n\nWhen disabled: orders are sent as limit',
    MARKET_STOP: 'Place stop orders as market \n\nWhen disabled: orders are sent as limit',
    MEAN_REVERSION: 'Use the mean reversion variant of the selected buy and sell methods',
    MERGE_AUTOCONFIG: 'Set this true and the autoconfig module will merge pairs in your config.js',
    MFI_BUY_LEVEL:
      'Set MFI_BUY_LEVEL, range 1 - 99 \n\nSets max allowed MFI level for strategy buy orders \n\nSet -1001 to disable',
    MFI_ENABLED: 'Use MFI as a confirming indicator',
    MFI_LENGTH: 'The number of candles used to calculate MFI',
    MFI_SELL_LEVEL:
      'Set MFI_SELL_LEVEL, range 1 - 99. Set min allowed MFI level for strategy sell orders \n\nSet -1001 to disable',
    MINIMUM_VARIATION_SUGGEST_TRADE: 'Numeric - Sets the minimum 24h variance for pair suggestions in BTC',
    MINIMUM_VOLUME_GOOD_CURRENCY: 'Numeric - Sets the trading volume threshold for pair suggestions',
    MIN_VOLUME_TO_BUY:
      'Sets threshold for buy orders \n\nSet this to the minimum trade size for your trading pair, defined in base currency',
    MIN_VOLUME_TO_SELL:
      "Sets threshold for sell orders to ignore small balances, Gunbot doesn't sell if quote value is less than defined \n\nSet this to the minimum trade size for your trading pair, defined in base currency",
    MULTIPLE_BASE:
      'Change selling behavior. \n\nDefault: each sell order will sell all available funds \n\nMultiple base: each sell order will sell only as much as the trading limit for a pair defines',
    NBA: 'Only allow buy orders when ask is at least x% below the last sell rate, set 0 to disable this feature \n\nValue represents a percentage',
    PANIC_SELL: 'Immediately sell all quote units at market price',
    PERIOD:
      'The "size" of the candlesticks used for indicator calculation, make sure to use a supported value for your exchange \n\nValue represents minutes',
    PP_BUY: 'Sets the exact price target for buying with pingpong',
    PP_SELL: 'Sets the exact price target for selling with pingpong',
    PRE_ORDER: 'Use an offset percentage for limit orders, compared to the current best price in the order book',
    PRE_ORDER_GAP:
      'Offset value for limit orders, use a negative value to place an order deeper in the order book \n\nValue represents a percentage',
    RENKO_ATR: 'Use ATR for Renk brick size',
    RENKO_BRICK_SIZE: 'Renko candles bricks size',
    RENKO_PERIOD: 'Period to draw RENKO candles, must be identical to PERIOD',
    RESERVE_PILE_UP: 'Automatically add trading gains to the reserve',
    RETRY_TV_ORDER: 'Retry placing orders. Only use as a last resort, otherwise it can cause double orders',
    ROE: 'ROE target to close positions. Value is (leveraged) ROE',
    ROE_CLOSE: 'Use reaching ROE as closing trigger',
    ROE_LIMIT: 'Trailing range for ROE trailing. Value is a percentage of actual ROE',
    ROE_TRAILING: 'Use ROE trailing to close positions',
    ROE_SCALPER:
      'Use different style of ROE trailing: initial trailing stop is ROE_LIMIT below ROE target. ROE trailing must be enabled to use this feature',
    ROE_SPREAD:
      'Increase open position with additional order worth Trading limit when ROE has reached target and increases by ROE_SPREAD. 0 disables this feature',
    RSI_BUY_ENABLED: 'Use RSI as confirming indicator for strategy buy orders',
    RSI_BUY_LEVEL: 'Set RSI_BUY_LEVEL range 1-99. Sets max RSI level for allowing strategy buy orders',
    RSI_DU_BUY: 'RSI level for buying when DU_METHOD is set to RSI',
    RSI_LENGTH: 'Number of candles to calculate RSI',
    RSI_METHOD: 'Method to use RSI. Oscillator: RSI must be in range. Cross: RSI must cross set level',
    RSI_SELL_ENABLED: 'Use RSI as confirming indicator for strategy sell orders',
    RSI_SELL_LEVEL: 'Set RSI_BUY_LEVEL range 1-99. Sets max RSI level for allowing strategy sell orders',
    RT_BUY_LEVEL: 'Percentage the price has to drop after RT_SELL to trigger RT_BUY \n\nValue represents a percentage',
    RT_BUY_UP_LEVEL:
      'Reverse stop loss. Percentage above the last RT_SELL to place an RT_BUY higher than latest RT_SELL. \n\nValue represents a percentage, set 0 to disable',
    RT_ENABLED:
      'Reversal Trading will be used if true. \nWARNING: if you disable RT while in RT process, you might need to set IGNORE_TRADES_BEFORE and delete the pairs json files!',
    RT_GAIN:
      'Defines a % after initial buy or RT_BUY to trigger an RT_SELL. \n\nSet this higher than the bid/ask spread in the orderbook!',
    RT_MAXBAG_PROTECTION:
      'Sets the maximum allowed difference between the break-even point and current price for starting RT',
    RT_ONCE: 'Disable pair after succesfully ending an RT cycle at profit',
    RT_ONCE_AND_CONTINUE: 'Trading will continue without RT after succesfully ending an RT cycle at profit',
    RT_SELL_UP: 'Sets the starting point for trailing up an RT_SELL. Only works when TM_RT_SELL is enabled',
    RT_TREND_ENABLED:
      ' Enables the use of forecast for placing RT_BUY or RT_SELL orders when using TM_RT_SELL and/or TRAIL_ME_BUY',
    SELLLVL: 'Defines which step should be considered for selling',
    SELLLVL1: 'Defines the first level above break-even to sell \n\nValue represents a percentage',
    SELLLVL2: 'Defines the second level above break-even to sell \n\nValue represents a percentage',
    SELLLVL3: 'Defines the third level above break-even to sell \n\nValue represents a percentage',
    SELL_ENABLED: 'Allow sell or short orders',
    SELL_METHOD: 'Sets the method used for strategy sell orders',
    SELL_RANGE: 'Sets the sell range for trailing \n\nValue represents a percentage',
    SENKOUSPAN_PERIOD: 'Defines the number of candles used to calculate Senkou-span',
    SHORT_LEVEL: 'Percentage from EMA to allow short orders \n\nSell method dependent',
    SLOW_SMA: 'The number of candles used to calculate slow SMA',
    SLOW_STOCH_K: 'The number of candles used to calculate slow STOCH %K',
    SL_DISABLE_BUY: 'Disable buy orders when STOP LIMIT is reached',
    SL_DISABLE_SELL: 'Disable sell orders when STOP LIMIT is reached for a short position',
    SMAPERIOD:
      'The number of candles used to calculate Bollinger Bands, trend watcher, support and resistance and Fibonacci retracements',
    STDV: 'Multiplier used to calculate Bollinger Bands',
    STOCHRSI_BUY_LEVEL:
      'Sets the maximumKIJUN_STOP Stoch RSI value to allow strategy buy orders \n\nValue between 0.01 - 0.99 \n\nSet -1001 to disable',
    STOCHRSI_ENABLED: 'Use Stoch RSI as confirming indicator',
    STOCHRSI_LENGTH: 'The number of candles used to calculate Stoch RSI',
    STOCHRSI_METHOD:
      'The method to use Stoch RSI \n\nOscillator: Stoch RSI must be in range \n\nCross: Stoch RSI must cross the set level',
    STOCHRSI_SELL_LEVEL:
      'Sets the minimum Stoch RSI value to allow strategy sell orders \n\nValue between 0.01 - 0.99 \n\nSet -1001 to disable',
    STOCH_BUY_LEVEL:
      'Sets the maximum Stoch value to allow strategy buy orders \n\nValue between 1 - 99 \n\nSet -1001 to disable',
    STOCH_D: 'The number of candles used to calculate Stoch %D',
    STOCH_ENABLED: 'Use Stoch as a confirming indicator',
    STOCH_K: 'The number of candles used to calculate Stoch %K',
    STOCH_METHOD:
      'The method to use Stoch \n\nOscillator: Stoch must be in range \n\nCross: Stoch must cross the set level',
    STOCH_SELL_LEVEL:
      'Sets the minimum Stoch value to allow strategy sell orders \n\nValue between 1 - 99 \n\nSet -1001 to disable',
    STOP_LIMIT:
      'Sets the threshold for when to sell or close at loss \n\nSpot: value represents a percentage below break even \nFutures: value represents ROE below break even',
    STRATEGY: 'Name of the autoconfig strategy',
    TAKE_BUY: 'Enable TakeBuy',
    TAKE_PROFIT: 'Enable TakeProfit',
    TAKER_FEES: 'Allow taker side orders in Nash League',
    TBUY_RANGE: 'Set TakeBuy range in %',
    TELEGRAM_ENABLED: 'Enable Telegram trade notifications',
    TELEGRAM_NICK: 'Alphanumeric - Name for this bot instance',
    TENKAN_BUY: 'Buy using Tenkan',
    TENKAN_CLOSE: 'Close position at Tenkan-sen cross',
    TENKAN_PERIOD: 'Sets the number of candles for Tenkan-sen calculation',
    TENKAN_SELL: 'Sell using Tenkan',
    TENKAN_STOP: 'Stop position at Tenkan-sen cross',
    TG_ORDER_TIMEOUT: 'Delay order placement for x seconds, confirm via Telegram. Set to 0 to disable',
    TG_PL_ONLY: 'Send Telegram notifications for sells only',
    TG_TEST: 'Test Telegram alerts',
    TL_ALLIN: 'Use all available base balance for each buy order',
    TRAIL_ME_BUY: "Use tssl-style trailing after normal strategy's buy criteria are met",
    TRAIL_ME_BUY_RANGE: 'Trailing range (percentage)',
    TRAIL_ME_DU: 'Use tssl-style trailing for double up orders',
    TRAIL_ME_RT: 'Use tssl-style trailing for RT_BUY orders',
    TRAIL_ME_RT_SELL_RANGE: 'Trailing range (percentage)',
    TRAIL_ME_SELL: "Use tssl-style trailing after normal strategy's sell criteria are met",
    TRAIL_ME_SELL_RANGE: 'Trailing range (percentage)',
    TSSL_TARGET_ONLY:
      'Protects from sell orders below gain when using tssl sell method, making GAIN the starting point for trailing',
    TV_GAIN: 'Minimum gain to reach before selling (works only for spot trading)',
    TV_GB: 'Use mixed mode: process Gunbot strategies and incoming alerts simultaneously',
    TV_LEVERAGE: 'Set leverage for each trade',
    TV_MVTS: 'Required minimum balance (base) for placing a sell order (avoids trading untradable amounts)',
    TV_PROTECTION: 'Check for overall profit before selling, as specified in TV_GAIN (works only for spot trading)',
    TV_PYRAMID: 'Enable pyramid selling',
    TV_STOPLOSS_PERCENTAGE:
      'Percentage below average bought price at which a sell signal should sell in a stop-loss manner, overriding TV_PROTECTION',
    TV_TRADING_LIMIT_ALLIN: 'Uses all available base currency balance for each buy order',
    TV_TRADING_LIMIT_BUY: 'Sets the trading limit for buy orders',
    TV_TRADING_LIMIT_BUY_PYRAMID: 'Sets the trading limit for pyramid buy orders',
    TV_TRADING_LIMIT_CAP: 'Max amount of base currency to be invested in a pair',
    TV_TRADING_LIMIT_SELL: 'Sets the trading limit for sell orders on Tradingview',
    USE_RENKO: 'Use renko candles instead of regular candles',
    VERBOSE: 'Shows detailed info in the console',
    WATCH_MODE:
      'Gunbot processes the configured pairs but does not place buy/sell orders. \n\nFor spot strategies and futuresGrid only',
    XTREND_ENABLED: 'Use XTREND to confirm trades when using stepgain',
    authorized_froms: 'Defines the E-Mail addresses Gunbot is allowed to read and use for Tradingview alerts',
    wh_port: 'Port used for webhooks',
    wh_passphrase: 'Passphrase used for webhooks',
    chat_id: 'ID you received from @myidbot',
    admin_id: 'Telegram ID that is allowed to interact with the bot. Often the same as chat ID',
    clientport: 'Port Gunbot uses to stream data',
    debug: 'Enables detailed console logs',
    enabled:
      'Starts listening for email alerts. Make sure to have at least one pair set per exchange that you expect alerts for. Unless you use GB_TV, this will stop processing regular trading strategies',
    host: 'The hostname of the IMAP server to connect to',
    hostname: 'The name of the host Gunbot is located on',
    interval_ticker_update: 'Sets how often new prices are pulled from the exchange (default 25 sec)',
    password: 'Your email password or app password depending on provider and security settings',
    period_storage_ticker: 'Sets how many received prices are kept in the array for calculating indicators',
    port: 'The port number of the IMAP server',
    timeout_buy: 'Prevents the bot from buying again within the set amount of time after a buy order is placed',
    timeout_sell: 'Prevents the bot from selling again within the set amount of time after a sell order is placed',
    tls: 'Use TLS encryption for the IMAP connection',
    user: 'Your email address',
    withdraw_address: 'Set a valid BTC wallet address to enable automatic withdraws when the threshold is reached',
    withdraw_threshold:
      'Sets the amount of BTC to be accumulated with RESERVE_PILE_UP before an automatic withdraw is executed',
    TG_AUTOCONFIG: 'Activates Telegram notifications for Autoconfig actions',
    OLD_TELEGRAM: 'Activates Telegram notifications for bitRage trades',
    BR_TOKEN: 'The bot token from @botfather\n\nMust use a separate token',
    BR_SMART_MODE: 'bitRage analyzes all triple and spins only profitable ones',
    BR_SLOW_MODE: 'bitRage smart mode waits for previous opportunities to complete before firing a new one',
    TV_LENDING: 'Sets the max lending rate for opening a position on Poloniex',
    STOP_BUY:
      'Places a stop market order at a specified price distance below entry. Value is a % of price, 0 disables. For Bitmex only',
    STOP_SELL:
      'Places a stop market order at a specified price distance above entry. Value is a % of price, 0 disables. For Bitmex only',
    TV_CLOSE_ALL:
      'Closes the entire long or short position, instead of using a specified trading limit. Only works for futures trading',
    INITIAL_CAPITAL:
      'Set initial wallet balance before running MM for the first time. Affects hard reductions by Capital Risk option',
    MAX_BUY: 'Number of orders to place below bid or avg entry price. Set 0 to effectively disable long side',
    MAX_SELL: 'Number of orders to place above ask or avg entry price. Set 0 to effectively disable short side',
    CANCEL_SPREAD: 'Sets how far price must move before open orders are cancelled and replaced. Value is a %',
    FIRST_ORDER_SPREAD: 'Sets how far apart orders are placed when you have no position. Value is a %',
    FLIP: 'When enabled, orders are placed on both long and short side. When disabled, no MM orders are placed on the profit side',
    MM_SPREAD: 'Sets the min distance between avg entry price and where DCA orders are placed. Value is a %',
    SR_RATIO:
      'The ratio used for soft reduction orders. For example, with 5000 contracts open and SR_RATIO: 2, an order will be sent for 2500 contracts, closing half of the position (5000/2 = 2500)',
    SR_INVESTED_PERC:
      'The threshold for allowing soft reduction orders (a reduction while in profit). When Invested Ratio drops below this threshold, an SR order will be placed the next time the position is profitable. Set a low value like 0.01 to effectively disable this',
    SR_LIMIT_LONG: 'Sets how many SR orders may be placed for a single long position. Commonly set to 1',
    SR_LIMIT_SHORT: 'Sets how many SR orders may be placed for a single short position. Commonly set to 1',
    SR_SPREAD:
      'Sets the minimum distance between average entry price and where an SR order is first placed. Used to cover for fees on exchanges with taker fees > 0',
    CAPITAL_RISK:
      'When Capital Risk in grid is lower than set, a part of the position will be closed at loss with a market order. Set a low value like 0.01 to effectively disable this',
    INVESTED_PERC:
      'When Invested Perc in grid is lower than set, a part of the position will be closed at loss with a market order. Set a low value like 0.01 to effectively disable this',
    MAX_OPEN_CONTRACTS:
      'Sets the maximum position size of a futures position. Expressed in quote units.\n\nIf executing a next order would exceed max open contracts, the order will not be fired.\n\nIf position size exceeds max open contracts, a hard reduction will be triggered',
    REDUCING_RATIO:
      'Ratio used for hard reduction orders.\n\nFor example, with 5000 contracts open and REDUCING_RATIO: 2, an order for 2500 contracts will be sent, closing half of the position (5000/2 = 2500)',
    MAX_LOSS:
      'Max loss on a position, compared to Initial Capital, before a hard reduction order is fired.\n\nFor example, MAX_LOSS 5 would close a position if pnl is lower or equal to 5% of initial capital.\n\nSet a high value, like 10000000, to effectively disable this',
    FIBONACCI: 'Use Fibonacci retracements to trigger orders',
    FIB_DCA_PERC:
      'Sets the minimum distance between average entry price and where DCA orders are placed.\n\nValue represents a percentage',
    DCA_METHOD: 'Choose between Native or Tenkan method for Futures Markets DCA orders',
    SupportResistance: 'Use support / resistance to trigger orders',
    SupRes_ALLOW_DCA: 'Allow DCA orders to happen after opening a position',
    SupRes_SPREAD:
      'Sets a range from first support/resistance, orders are allowed after this range is preserved.\n\nApplies to strategy buy AND sell orders.\n\nValue represents a percentage',
    SupRes_LVL_SPREAD:
      'Sets the min distance between last order rate and where DCA orders are placed. Value represents a percentage',
    SupRes_MAX:
      'Use this setting to limit how many times the strategy is allowed to buy. If the combined value of quote balance, open orders and the next order to place are higher than set, then no further buy orders will be placed',
    SupRes_TIMER: 'Timer in seconds between orders of the same type',
    SupResMinROE: 'Minimum ROE before close orders are allowed',
    GRID: 'Use Grid for triggering orders',
    GRID_SPREAD: 'Min distance between grid orders. Value represents a percentage',
    GRID_SIDE: 'Sets the side for new positions',
    GRID_MAX:
      'Max contract size. No further orders that would add to the position are allowed when this value is reached',
    GRID_TIMER: 'Timer in seconds between orders of the same type',
    x125: 'Enables a mixed mode between pure MM and Grid',
    DCA_PRICE_SPREAD: 'Use DCA price spread',
    DCA_SPREAD:
      'Sets the min distance between last order rate and where DCA orders are placed. Value represents a percentage',
    MAX_DCA_ORDERS: 'Max number of DCA orders to place in one round',
    DCA_SPREAD_CAP: 'Max number of DCA rounds',
    bitRage: 'Use bitRage in this strategy',
    WAIT_OPEN_ORDERS: 'Wait for open orders to fill before executing another arbitrage on the same triple',
    bitRage_MODE:
      'Safe mode uses market orders if possible, otherwise limit orders.\n\nCrazybitch mode only allows market orders',
    AUTOSELL: 'Enable for automatic bitRage hedging, or enable before manually triggering hedging',
    MAIN_BASE: 'Set the base currency to hedge to.\n\nOnly important if you want to manually trigger hedging',
    BR_PANIC_SELL: 'Enable to manually trigger hedging to the set main base',
    PSAR_PERIOD: 'Candle period for calculating PSAR. Make sure to use a supported period for your exchange',
    PSAR_STEP: 'PSAR step size',
    PSAR_MAX: 'PSAR max value',
    CLOSE_SPREAD: 'Enable for bitRage to close the bid/ask spread when looking for arbitrage opportunities',
    bitRage_strategy: 'Strategy name used for bitRage',
    DCA_strategy: 'Strategy name used for handling bitRage bags',
    BR_HEDGING: 'Automatically hedge USDT to BTC or vice versa based on PSAR signals',
    MOTION_OF_THE_OCEAN: 'Follow whale movements to trigger orders',
    oneSCALPER: 'Use a MA99 scalper on any supported period',
    oneSCALPER_DCA: 'Allow DCA in 1Scalper using the DCA spread option',
    oneSCALPER_TIMER: 'Number of seconds to wait between 2 consecutive DCA orders in 1scalper strategy',
    EWO: 'Use the Elliot Waves Oscillator strategy for futures markets',
    MOTO_DCA_PERC:
      'Minimum distance between average entry price and where DCA orders are placed. \n\nValue represents a percentage',
    MOTO_SIDE: 'Side to perform MOTO orders. \n\nAccepted values: LONG, SHORT, BOTH',
    MOTO_DCA_MULTIPLIER: 'Multiplier for Moto DCA quantity: Trading Limit/Multiplier',
    MOTO_TIMER: 'Number of seconds to wait before another trade',
    PND: 'Close a position after a pump or dump finishes and price is better than average entry price. Overrides any ROE related setting',
    PND_PROTECTION:
      'Threshold to close a position if it drops below ROE again. \n\nA value of 1.5 means that if ROE reached 1.5x the minimum target, the position will be closed immediately if the trend turns',
    TV_MARKET_ORDERS: 'Send all orders as market orders. Limit orders are sent when disabled',
    PULLBACK: 'Use this strategy to base entry points on pullbacks',
    PULLBACK_DCA_PERC: 'Set the distance between entry point and DCA orders. Value is a percentage',
    PULLBACK_SIDE: 'Set the side to perform pullback orders. Accepted values: LONG, SHORT, BOTH',
    PULLBACK_DCA_MULTIPLIER: 'Multiplier for Pullback DCA quantity: formula is Trading limit / Pullback DCA multiplier',
    PULLBACK_ENABLED: 'Enables PULLBACK entry logic for MM strats',
    HEDGE_MODE: 'Use this to allow for simultaneous positions in both directions. Accepted values: LONG, SHORT, BOTH',
    MM_DCA: 'Allow DCA orders to be placed',
    key: 'API key for trading on this exchange. Same as registered key',
    masterKey: 'Registered API key for this exchange',
    secret: 'API secret for the trading key. Some exchanges call this private key',
    masterSecret: 'API secret for the registered key. Some exchanges call this private key',
    clientId: 'Client ID for this exchange',
    passphrase: 'API passphrase for the registered and trading key',
    master_passphrase: 'API passphrase for the registered and trading key',
    delay: 'Delay time in seconds between processing pairs. 10 works on almost every exchange',
    fees: 'Exchange fees as percentage of a single order. Check your exchange for your fees level',
    market: 'Market type this API key will be used for. Common options are spot or futures',
    MAX_BUY_COUNT:
      'Max number of buy orders. Each order is for the same amount as trading limit. When max is reached, strategy goes into sell mode only',
    GRID_MULTIPLIER:
      'Can be used to increase or decrease the distance between buy orders. Default value 1 means that the hardcoded levels are used. \n\n1.5 would increase the levels with a factor 1.5x, a value of 0.5 would halve the default levels',
    STOP_AFTER_SELL:
      'Do not place a new buy order after the next time the strategy sells. Halts trading after the next sell order. \n\nWorks for both longs and shorts in futuresGrid, after closing any position it will not open a new one',
    AUTO_GAIN:
      'Sets a dynamic sell target for spotGrid. Target is never lower than 2x trading fees, target decreases when position size increases. Overrules a manually set target',
    TRAILING_MULTIPLIER:
      'Can be used to increase or decrease the trailing range for all order types. Default value 1 means that the hardcoded range is used. \n\n1.5 would increase the range with a factor 1.5x, a value of 0.5 would halve the default trailing range',
    START_CONT_TRADING:
      'Continuous trading allows you to continue trading while the current price is below break even, with the aim of lowering break even by accumulating quote and to gain base balance. You need to have a trading limit that is at least 2x the exchange defined minimum order size for this feature to work. \n\n This setting defines from which buy count continuous trading is allowed. \n\n A value of 3 means that when the position is 3x trading limit in size, the next sell target is placed above the last buy rate instead of above break even. Set a very high value to effectively disable this feature',
    TL_MULTIPLIER:
      'Can be used to increase or decrease the invested amount with each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400',
    CT_TL_MULTIPLIER:
      'Sets the ratio between last buy order invest in base, and the amount used for trades during continuous trading. Value between 0 and 1. \n\nWhen set to 0.5, halve of the base amount used for the previous DCA trade will be used for continuous trading. Setting 1 would use the same amount as the last DCA buy order \n\nWhen set too low, it is possible that CT sell orders fail because the order value is below exchange minimum',
    CT_RESTART_MULTIPLIER:
      'Sets the distance between a CT sell and a buy above that. Default is 1, should usually not be changed. \n\nExamples:\n\n1: buy target above last sell rate is placed once price is 1x the distance between sup/res above last sell rate \n\n0.5: buy target above last sell rate is placed once price is 0.5x the distance between sup/res above last sell rate',
    SAFETY_TIMER: 'Attempts to pause trading for x seconds in case incomplete API data is detected on Binance Futures',
    INSTANCE_NAME: 'Optional instance name to show in page title in browser',
    TREND_OPEN:
      'When enabled, new positions are only opened when both 4h and 15m charts indicate a bullish market. \n\nThis check is independent from the PERIOD in your strategy. Requires the exchange to offer both 15m and 4h candle data',
    GRID_MULTIPLIER:
      'Change the distance between buy orders. Default is 1. Higher values increase the levels, lower values decrease them',
    STOP_AFTER_SELL: "Don't place a new buy order after the next sell. Halts trading after the next sell order",
    AUTO_GAIN:
      'Sets a dynamic sell target for spotGrid. Target never goes below 2x fees and decreases with position size. Overrules manual target',
    TRAILING_MULTIPLIER:
      'Change the trailing range for all order types. Default is 1. Higher values increase the range, lower values decrease it',
    START_CONT_TRADING:
      'Continuous trading allows you to keep trading below break even, to gain base balance. You need a trading limit at least 2x the min order size. This setting defines from which buy count continuous trading is allowed. A value of 3 means when position is 3x trading limit, next sell target is above last buy rate instead of break even. Set a high value to disable this feature',
    TL_MULTIPLIER:
      'Use this to increase or decrease the amount invested with each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400',
    CT_TL_MULTIPLIER:
      'Sets the ratio between the last buy order and the amount used for continuous trading. Value is between 0 and 1. \n\nWhen set to 0.5, half of the previous DCA trade amount will be used for continuous trading. Setting 1 will use the same amount as the last DCA buy order. \n\nSetting it too low may cause CT sell orders to fail because the order value is below exchange minimum',
    CT_RESTART_MULTIPLIER:
      'Sets the distance between a CT sell and a buy above that. Default is 1, usually should not be changed. \n\nExamples:\n\n1: buy target above last sell rate is placed once price is 1x the distance between sup/res above last sell rate \n\n0.5: buy target above last sell rate is placed once price is 0.5x the distance between sup/res above last sell rate',
    SAFETY_TIMER: 'Tries to pause trading for x seconds if incomplete API data is detected on Binance Futures',
    INSTANCE_NAME: 'Optional instance name to show in browser page title',
    TREND_OPEN:
      "When enabled, new positions are only opened when both 4h and 15m charts show a bullish market. \n\nThis check is independent of the strategy's PERIOD. Requires the exchange to offer 15m and 4h candle data",
    TREND_BLOCK_DCA:
      "Only allows DCA trades when both 4h and 15m charts show a bullish market. This isn't affected by the PERIOD in your strategy. Requires the exchange to have 15m and 4h candle data",
    TREND_LOWER_DCA:
      "Doubles the DCA distance when the 4h and/or 15m chart doesn't show a bullish market. This isn't affected by the PERIOD in your strategy. Requires the exchange to have 15m and 4h candle data",
    DIRECTION:
      'Direction to trade: LONG, SHORT or AUTO. If you select LONG and have an open short, the strategy will act as if in LONG mode until the position can be closed at profit and a SHORT gets opened. AUTO mode sets the direction based on current trend detected on 4h and 15m chart. If the market is undecided, no new positions are opened',
    TREND_CT_MULTIPLIER:
      'Adjusts CT buy target while trend is not bullish and TREND_LOWER_DCA is used. A value of 2 doubles the distance, a value of 1 has the same behavior as when TREND_LOWER_DCA is disabled.',
    TREND_GRID_MULTIPLIER:
      "Adjusts the next DCA target when trend isn't bullish and TREND_LOWER_DCA is used. A value of 2 doubles the distance, 1 keeps it the same",
    AUTO_STEP_SIZE: 'Automatically sets the step size for buy and sell orders. Mainly based on ATR',
    STEP_SIZE:
      'Sets the step size for buy and sell orders. 500 on USDT-BTC would trade each time the price moves 500 USDT',
    ENFORCE_STEP: 'Prevents trailing from finishing at a worse rate than the currently active step size',
    STRAT_FILENAME: 'Filename of a custom strategy file in the Gunbot root folder',
    unit_cost:
      'Determines how the break even price is calculated. When enabled, the break even line represents the average cost per remaining unit in balance. When disabled, the break even price for the complete order sequence is used. Should not be used with dynamic exit logic',
    DYNAMIC_EXIT_LOGIC:
      'Changes the exit logic used for the strategy. In uptrends, the target is unit cost. In any other market situation, the break even price for the complete order sequence is used. Disable unit cost to use this option',
    futuresGrid:
      'DCA strategy with automatic buydown levels and price trailing. Fixed investment per trade. Next target increases with each order',
    TREND_TRAILING:
      "Automatically adjust buy trailing ranges in bearish situations. \n\nThis aims to save on buy orders when price isn't moving upwards, using much bigger trailing ranges",
    TREND_TRAILING_MULTIPLIER:
      'Trailing range multiplier for when 4h structure is bullish, but 15m structure is bearish. \n\nSets buy trailing range to "multiplier * ATR", or if you use a custom pct trailing range, to "multiplier * pct trailing range"',
    TREND_TRAILING_BEARISH_MULTIPLIER:
      'Trailing range multiplier for when 4h structure is bearish and 15m structure is not bullish or is bearish. \n\nSets buy trailing range to "multiplier * ATR", or if you use a custom pct trailing range, to "multiplier * pct trailing range"',
    AUTO_TREND_ORDERS:
      'When used with "trend orders: true", these trend initiated orders are only allowed during a 4h uptrend',
  },

  // List of readable names for settings parameters
  // Conventions: Only first characters in a line get Caps. Exception: indicator names / abbreviations in ALLCAPS
  title: {
    gunthy_wallet: 'Gunthy wallet',
    TOURNAMENT_AGREEMENT: 'Tournament opt-in',
    TOURNAMENT_NICKNAME: 'Leaderboard nickname',
    IS_MARGIN_STRAT: 'Is margin strategy',
    STRATEGY: 'Strategy',
    KUMO_BUY: 'Kumo buy',
    KIJUN_BUY: 'Kijun buy',
    TENKAN_BUY: 'Tenkan buy',
    KUMO_SELL: 'Kumo sell',
    TENKAN_SELL: 'Tenkan sell',
    KIJUN_SELL: 'Kijun sell',
    MERGE_AUTOCONFIG: 'Merge autoconfig',
    DEPTH_PRESSURE_LEVEL: 'Depth pressure',
    ROE_CLOSE: 'ROE close',
    ROE_TRAILING: 'ROE trailing',
    ROE_LIMIT: 'ROE limit',
    ROE_SCALPER: 'ROE scalper',
    ROE_SPREAD: 'ROE spread',
    PRE_ORDER: 'Pre order',
    PRE_ORDER_GAP: 'Pre order gap',
    MAKER_FEES: 'Maker fees',
    TAKER_FEES: 'Taker fees',
    MEAN_REVERSION: 'Mean reversion',
    MARKET_BUY: 'Market buy',
    MARKET_SELL: 'Market sell',
    MARKET_CLOSE: 'Market close',
    MARKET_STOP: 'Market stop',
    MARKET_RTBUY: 'Market RTbuy',
    MARKET_RTSELL: 'Market RTsell',
    MARKET_BUYBACK: 'Market buyback',
    MARKET_DU: 'Market DU',
    MARKET_FOK: 'Market fok',
    TV_LEVERAGE: 'TV leverage',
    USE_RENKO: 'Use renko',
    SHORT_LEVEL: 'short level',
    LONG_LEVEL: 'Long level',
    ROE: 'ROE',
    TENKAN_CLOSE: 'Tenkan close',
    KUMO_CLOSE: 'Kumo close',
    KIJUN_CLOSE: 'Kijun close',
    TENKAN_STOP: 'Tenkan stop',
    KUMO_STOP: 'Kumo stop',
    KIJUN_STOP: 'Kijun stop',
    RENKO_PERIOD: 'Renko period',
    RENKO_BRICK_SIZE: 'Renko brick size',
    RENKO_ATR: 'Renko ATR',
    LEVERAGE: 'Leverage',
    ICHIMOKU_PROTECTION: 'Ichimoku protection',
    XTREND_ENABLED: 'Xtrend enabled',
    NBA: 'Never buy above',
    DOUBLE_CHECK_GAIN: 'Double check gain',
    DISPLACEMENT: 'Displacement',
    RT_BUY_UP_LEVEL: 'RT buy up level',
    SL_DISABLE_BUY: 'SL disable buy',
    SL_DISABLE_SELL: 'SL disable sell',
    COUNT_SELL: 'Count sell',
    BTC_PND_PERIOD: 'BTC pnd period',
    STOCHRSI_ENABLED: 'Stoch RSI enabled',
    STOCHRSI_LENGTH: 'Stoch RSI length',
    STOCHRSI_BUY_LEVEL: 'Stoch RSI buy level',
    STOCHRSI_SELL_LEVEL: 'Stoch RSI sell level',
    STOCHRSI_METHOD: 'Stoch RSI method',
    TG_PL_ONLY: 'TG pl only',
    RT_ONCE: 'RT once',
    RT_ONCE_AND_CONTINUE: 'RT once and continue',
    RSI_LENGTH: 'RSI length',
    login: 'Login',
    twoFA: 'Two factor authentication',
    trade: 'Trade',
    error: 'Error',
    warning: 'Warning',
    callback: 'Callback',
    TV_GB: 'TV GB',
    TP_PROFIT_ONLY: 'Tp profit only',
    TAKE_BUY: 'Take buy',
    TBUY_RANGE: 'Take buy range',
    TENKAN_PERIOD: 'Tenkan period',
    KIJUN_PERIOD: 'Kijun period',
    SENKOUSPAN_PERIOD: 'Senkouspan period',
    KEEP_QUOTE: 'Keep quote',
    TL_PERC: 'Trading limit pct',
    TL_ALLIN: 'Trading limit all-in',
    TG_TEST: 'TG test',
    TV_MVTS: 'TV mvts',
    TAKE_PROFIT: 'Take profit',
    TP_RANGE: 'Tp range',
    EMAx: 'EMAx',
    PP_BUY: 'Pingpong buy',
    PP_SELL: 'Pingpong sell',
    BUYLVL: 'Stepgain buy lvl',
    BUYLVL1: 'Stepgain buy lvl 1',
    BUYLVL2: 'Stepgain buy lvl 2',
    BUYLVL3: 'Stepgain buy lvl 3',
    SELLLVL: 'Stepgain sell lvl',
    SELLLVL1: 'Stepgain sell lvl 1',
    SELLLVL2: 'Stepgain sell lvl 2',
    SELLLVL3: 'Stepgain sell lvl 3',
    ATRX: 'ATRx',
    ATR_PERIOD: 'ATR period',
    RSI_METHOD: 'RSI method',
    STOCH_METHOD: 'Stoch method',
    KUMO_SENTIMENTS: 'Kumo sentiments',
    TG_ORDER_TIMEOUT: 'Telegram order timeout',
    CANDLES_LENGTH: 'Candles length',
    EMA_LENGTH: 'EMA length',
    MFI_LENGTH: 'MFI length',
    RT_SELL_UP: 'RT sell up',
    RT_TREND_ENABLED: 'RT trend enabled',
    RT_BUY_LEVEL: 'RT buy level',
    SELL_METHOD: 'Sell method',
    BUY_METHOD: 'Buy method',
    BEST_CURRENCIES: 'Best currencies',
    MINIMUM_VOLUME_GOOD_CURRENCY: 'Min volume good currency',
    MINIMUM_VARIATION_SUGGEST_TRADE: 'Min variation suggest trade',
    enabled: 'Enabled',
    authorized_froms: 'Authorized froms',
    wh_port: 'Webhooks port',
    wh_passphrase: 'Webhooks passphrase',
    user: 'User',
    password: 'Password',
    host: 'Host',
    tls: 'Tls',
    Port: 'Port',
    clientPort: 'Client Port',
    hostname: 'Hostname',
    TELEGRAM_ENABLED: 'Enabled',
    TELEGRAM_NICK: 'Bot nickname',
    TOKEN: 'Token',
    chat_id: 'Chat id',
    TV_GAIN: 'Gain',
    TV_TRADING_LIMIT_BUY: 'Trading limit buy',
    TV_TRADING_LIMIT_BUY_PYRAMID: 'Trading limit buy pyramid',
    TV_PYRAMID: 'Pyramid',
    TV_TRADING_LIMIT_SELL: 'Trading limit sell',
    TV_PROTECTION: 'Protection',
    TV_TRADING_LIMIT_CAP: 'Trading limit cap',
    TV_STOPLOSS_PERCENTAGE: 'Stoploss pct',
    TV_TRADING_LIMIT_ALLIN: 'Trading limit all-in',
    RETRY_TV_ORDER: 'Retry order',
    withdraw_address: 'Withdraw address',
    withdraw_threshold: 'Withdraw threshold',
    CANCEL_ORDERS_ENABLED: 'Cancel orders enabled',
    CANCEL_ORDERS_CYCLE_CAP: 'Cancel orders cycle cap',
    CANCEL_ONCAP: 'Cancel orders oncap',
    timeout_sell: 'Timeout sell',
    timeout_buy: 'Timeout buy',
    period_storage_ticker: 'Period storage ticker',
    interval_ticker_update: 'Interval ticker update',
    BOT_CCLEAN: 'Clean cache',
    BOT_DELAY: 'Bot delay',
    WATCH_MODE: 'Watch mode',
    MULTIPLE_BASE: 'Multiple base',
    debug: 'Debug',
    VERBOSE: 'Verbose',
    RESERVE_PILE_UP: 'Reserve pile up',
    IGNORE_TRADES_BEFORE: 'Ignore trades before',
    LIQUIDITY: 'Liquidity provider',
    LIQUIDITY_TAKER: 'Liquidity taker role',
    LIQUIDITY_GAIN: 'Liquidity gain',
    MAX_INVESTMENT: 'Max invested base',
    TSSL_TARGET_ONLY: 'Tssl target only',
    SELL_RANGE: 'Sell range',
    ADX_ENABLED: 'ADX enabled',
    ADX_LEVEL: 'ADX level',
    DI_PERIOD: 'DI period',
    EMASPREAD: 'EMA spread',
    BUY_RANGE: 'Buy range',
    RT_GAIN: 'RT gain',
    BUY_ENABLED: 'Buy enabled',
    BUY_LEVEL: 'Buy level',
    SELL_ENABLED: 'Sell enabled',
    GAIN: 'Gain',
    TRADING_FEES: 'Trading fees',
    TRADING_LIMIT: 'Trading limit',
    FUNDS_RESERVE: 'Funds reserve',
    PERIOD: 'Period',
    EMA1: 'Medium EMA',
    EMA2: 'Fast EMA',
    EMA3: 'Long EMA',
    HIGH_BB: 'High BB',
    LOW_BB: 'Low BB',
    STDV: 'Standard deviation',
    SMAPERIOD: 'SMA period',
    SLOW_SMA: 'Slow SMA',
    FAST_SMA: 'Fast SMA',
    MACD_SHORT: 'MACD short',
    MACD_LONG: 'MACD long',
    MACD_SIGNAL: 'MACD signal',
    STOCH_ENABLED: 'Stoch enabled',
    STOCH_BUY_LEVEL: 'Stoch buy level',
    STOCH_SELL_LEVEL: 'Stoch sell level',
    STOCH_K: 'Stoch K',
    SLOW_STOCH_K: 'Slow stoch K',
    STOCH_D: 'Stoch D',
    RSI_BUY_ENABLED: 'RSI buy enabled',
    RSI_SELL_ENABLED: 'RSI sell enabled',
    RSI_BUY_LEVEL: 'RSI buy level',
    RSI_SELL_LEVEL: 'RSI sell level',
    MFI_ENABLED: 'MFI enabled',
    MFI_SELL_LEVEL: 'MFI sell level',
    MFI_BUY_LEVEL: 'MFI buy level',
    BTC_PND_PROTECTION: 'BTC pnd protection',
    BTC_MONEY_FLOW: 'BTC money flow',
    DOUBLE_UP: 'Double up enabled',
    DOUBLE_UP_CAP: 'DU cap',
    DU_CAP_COUNT: 'DU cap count',
    DU_METHOD: 'DU method',
    DU_BUYDOWN: 'DU buydown',
    RSI_DU_BUY: 'DU RSI buy',
    RT_ENABLED: 'RT enabled',
    RT_MAXBAG_PROTECTION: 'RT max bag protection',
    TM_RT_SELL: 'TrailMe RT sell',
    TRAIL_ME_RT_SELL_RANGE: 'TrailMe RT sell range',
    TRAIL_ME_SELL: 'TrailMe sell',
    TRAIL_ME_BUY: 'TrailMe buy',
    TRAIL_ME_RT: 'TrailMe RT buy',
    TRAIL_ME_DU: 'TrailMe DU',
    TRAIL_ME_SELL_RANGE: 'TrailMe sell range',
    TRAIL_ME_BUY_RANGE: 'TrailMe buy range',
    PANIC_SELL: 'Panic sell',
    STOP_LIMIT: 'Stop limit',
    MIN_VOLUME_TO_BUY: 'Min volume to buy',
    MIN_VOLUME_TO_SELL: 'Min volume to sell',
    TRADES_TIMEOUT: 'Trades timeout',
    TG_AUTOCONFIG: 'Autoconfig alerts',
    OLD_TELEGRAM: 'bitRage alerts',
    BR_TOKEN: 'Token for bitRage & autoconfig alerts',
    BR_SMART_MODE: 'bitRage smart mode',
    BR_SLOW_MODE: 'bitRage slow mode',
    admin_id: 'Admin id',
    TV_CLOSE_ALL: 'Close complete position',
    TV_LENDING: 'Max lending rate',
    SINGLE_BUY: 'Single buy',
    STOP_BUY: 'Buy stop',
    STOP_SELL: 'Sell stop',
    INITIAL_CAPITAL: 'Initial capital',
    MAX_BUY: 'Max buy',
    MAX_SELL: 'Max sell',
    CANCEL_SPREAD: 'Cancel spread',
    FIRST_ORDER_SPREAD: 'First order spread',
    FLIP: 'Flip',
    MM_SPREAD: 'MM spread',
    SR_RATIO: 'SR ratio',
    SR_INVESTED_PERC: 'SR invested perc',
    SR_LIMIT_LONG: 'SR limit long',
    SR_LIMIT_SHORT: 'SR limit short',
    SR_SPREAD: 'SR spread',
    CAPITAL_RISK: 'Capital risk',
    INVESTED_PERC: 'Invested perc',
    MAX_OPEN_CONTRACTS: 'Max open contracts',
    REDUCING_RATIO: 'Reducing ratio',
    MAX_LOSS: 'Max loss',
    FIBONACCI: 'Fibonacci',
    FIB_DCA_PERC: 'Fib DCA perc',
    DCA_METHOD: 'DCA method',
    SupportResistance: 'Support / resistance',
    SupRes_ALLOW_DCA: 'Sup / res allow DCA',
    SupRes_SPREAD: 'Sup / res spread',
    SupRes_LVL_SPREAD: 'Sup / res DCA spread',
    SupRes_MAX: 'Sup / res max',
    SupRes_TIMER: 'Sup / res timer',
    SupResMinROE: 'Sup / res min ROE',
    GRID: 'Grid',
    GRID_SPREAD: 'Grid spread',
    GRID_SIDE: 'Grid side',
    GRID_MAX: 'Grid max',
    GRID_TIMER: 'Grid timer',
    x125: 'X125',
    DCA_PRICE_SPREAD: 'DCA price spread',
    DCA_SPREAD: 'DCA spread',
    MAX_DCA_ORDERS: 'Max DCA orders',
    DCA_SPREAD_CAP: 'DCA spread cap',
    bitRage: 'Use bitRage',
    WAIT_OPEN_ORDERS: 'Wait for open orders',
    bitRage_MODE: 'bitRage mode',
    AUTOSELL: 'Autosell',
    MAIN_BASE: 'Base to hedge to',
    BR_PANIC_SELL: 'bitRage panic sell',
    PSAR_PERIOD: 'Psar period ',
    PSAR_STEP: 'Psar step',
    PSAR_MAX: 'Psar max',
    CLOSE_SPREAD: 'Close spread',
    bitRage_strategy: 'bitRage stratey',
    DCA_strategy: 'DCA strategy',
    BR_HEDGING: 'bitRage hedging',
    MOTION_OF_THE_OCEAN: 'Motion of the ocean',
    oneSCALPER: '1 scalper',
    oneSCALPER_DCA: '1 scalper DCA',
    oneSCALPER_TIMER: '1 scalper timer',
    MOTO_DCA_PERC: 'Moto DCA spread',
    MOTO_SIDE: 'Moto side',
    MOTO_TIMER: 'Moto timer',
    MOTO_DCA_MULTIPLIER: 'Moto DCA multiplier',
    PND: 'Pnd',
    BOUGHT_PRICE: 'Bought price',
    PND_PROTECTION: 'Pnd protection',
    TV_MARKET_ORDERS: 'Use market orders',
    PULLBACK: 'Pullback',
    PULLBACK_DCA_PERC: 'Pullback DCA pct',
    PULLBACK_SIDE: 'Pullback side',
    PULLBACK_DCA_MULTIPLIER: 'Pullback DCA multiplier',
    PULLBACK_ENABLED: 'Pullback enabled',
    HEDGE_MODE: 'Hedge mode',
    MM_DCA: 'Allow DCA for MM spot',
    INTER_ARB: 'Interexchange arbitrage (for gunthy token)',
    LIQUIDITY_MINING: 'Liquidity mining (for gunthy token)',
    MAX_BUY_COUNT: 'Max buy count',
    GRID_MULTIPLIER: 'Grid multiplier',
    STOP_AFTER_SELL: 'Stop after next sell',
    AUTO_GAIN: 'Auto gain',
    EWO: 'Elliot waves oscillator',
    TRAILING_MULTIPLIER: 'Trailing range multiplier',
    START_CONT_TRADING: 'Start CT',
    TL_MULTIPLIER: 'Trading limit multiplier',
    CT_TL_MULTIPLIER: 'CT TL limit multiplier',
    CT_RESTART_MULTIPLIER: 'CT restart multiplier',
    SAFETY_TIMER: 'Safety timer',
    INSTANCE_NAME: 'Instance name',
    TREND_OPEN: 'Trend open',
    TREND_BLOCK_DCA: 'Dynamic DCA block',
    TREND_LOWER_DCA: 'Dynamic DCA distance',
    DIRECTION: 'Direction',
    TREND_CT_MULTIPLIER: 'Trend CT multiplier',
    TREND_GRID_MULTIPLIER: 'Trend grid multiplier',
    AUTO_STEP_SIZE: 'Auto step size',
    STEP_SIZE: 'Step size',
    ENFORCE_STEP: 'Enforce step size',
    STRAT_FILENAME: 'Strategy filename',
    FOREVER_BAGS: 'Forever bags',
    USE_TLR: 'Auto TL ratio',
    USE_PSR: 'Auto Partial sell ratio',
    PARTIAL_SELL_RATIO: 'Partial sell ratio',
    PCT_STEP_SIZE: 'Pct step size',
    PCT_TRAILING_RANGE: 'Pct buy trailing range',
    CUSTOM_TRAILING_RANGE: 'Custom buy trailing range',
    PCT_SELL_TRAILING_RANGE: 'Pct sell trailing range',
    CUSTOM_SELL_TRAILING_RANGE: 'Custom sell trailing range',
    TREND_SYNC: 'Trend sync',
    TREND_BASIC: 'Trend basic',
    TREND_PLUS: 'Trend plus',
    TREND_ORDERS: 'Trend orders',
    SELL_STEP_MULTIPLIER: 'Sell step multiplier',
    CUSTOM_TRADING_RANGE_MODE: 'Custom trading range',
    TRADING_RANGE_LOW: 'Trading range low',
    TRADING_RANGE_HIGH: 'Trading range high',
    TRADING_RANGE_STOP: 'Trading range stop',
    TRADING_RANGE_DCA_STOP: 'Trading range DCA stop',
    PULLBACK_MODE: 'Pullback mode',
    VOLATILITY_MODE: 'Volatility mode',
    TA_MODE: 'TA mode',
    DYNAMIC_SL: 'Dynamic stop loss',
    TRADING_LIMIT_SHORT: 'Trading limit short',
    MAX_UNHEDGED: 'Max unhedged',
    FIRST_TRADES_MULTIPLIER: 'First trades multiplier (long)',
    FIRST_TRADES_THRESHOLD: 'First trades threshold (long)',
    FIRST_TRADES_MULTIPLIER_SHORT: 'First trades multiplier (short)',
    FIRST_TRADES_THRESHOLD_SHORT: 'First trades threshold (short)',
    USE_TREND: 'Use trend',
    STOP_AFTER_CLOSE_LONG: 'Stop after close (long)',
    STOP_AFTER_CLOSE_SHORT: 'Stop after close (short)',
    CLOSE_LONG_ENABLED: 'Close long enabled',
    CLOSE_SHORT_ENABLED: 'Close short enabled',
    unit_cost: 'Unit cost',
    DYNAMIC_EXIT_LOGIC: 'Dynamic exit logic',
    FIRST_ORDER_EXTRA_DELAY: 'First order delay',
    TREND_TRAILING: 'Trend trailing',
    TREND_TRAILING_MULTIPLIER: 'Trend trailing multiplier (small)',
    TREND_TRAILING_BEARISH_MULTIPLIER: 'Trend trailing multiplier (big)',
    AUTO_TREND_ORDERS: 'Auto trend orders',
    PROTECT_PARTIAL_SELL: 'Protect partial sell',
    TREND_VARIANT: 'Trend variant',
    BUYDOWN_EXPONENT: 'Buydown exponent',
    MIN_BUYDOWN: 'Minimum buydown',
    KEEP_ONE_TL: 'Keep 1x TL',
    MIN_STEP_UP_PCT: 'Minimum step up pct',
    MIN_STEP_DOWN_PCT: 'Minimum step down pct',
    MIN_STEP_ASYMMETRIC: 'Minimum step asymmetric',
    INSTANT_OPEN_THRESHOLD_LONG: 'Instant open threshold (long)',
    INSTANT_OPEN_THRESHOLD_SHORT: 'Instant open threshold (short)',
    MIN_STEP_PCT: 'Minimum step pct',
    GAIN_PARTIAL: 'Partial sell gain',
    PARTIAL_SELL_CAP: 'Use partial sell cap',
    PARTIAL_SELL_CAP_RATIO: 'Partial sell cap ratio',
    SUPPORT_TL_RATIO: 'S1 TL ratio',
    TREND_PLUS: 'Trend plus',
    TREND_PLUS_BUY_MULTIPLIER_SMALL: 'Buy multiplier small',
    TREND_PLUS_BUY_MULTIPLIER_MEDIUM: 'Buy multiplier medium',
    TREND_PLUS_BUY_MULTIPLIER_LARGE: 'Buy multiplier large',
    TREND_PLUS_SELL_MULTIPLIER_SMALL: 'Sell multiplier small',
    TREND_PLUS_SELL_MULTIPLIER_MEDIUM: 'Sell multiplier medium',
    TREND_PLUS_SELL_MULTIPLIER_LARGE: 'Sell multiplier large',
    TREND_SCALPING: 'Micro scalping',
    SCALP_TL_RATIO: 'Micro scalp TL ratio',
    EXHAUSTION_SENSITIVITY: 'Exhaustion sensitivity',
    STRICT_ENTRY: 'Strict entry',
    STRICT_DCA: 'Strict DCA',
    PERIOD_MEDIUM: 'Period medium',
    PERIOD_LONG: 'Period long',
    TRADE_SUPPORTS: 'Trade supports',
    SUPPORT2_TL_RATIO: 'S2 TL ratio',
    MULTIPLE_TIMEFRAMES_MODE: 'Multi timeframes mode',
    LOWER_PERIOD_LOW: 'Lower set low period',
    LOWER_PERIOD_MEDIUM: 'Lower set medium period',
    LOWER_PERIOD_HIGH: 'Lower set high period',
    MIDDLE_PERIOD_LOW: 'Regular set low period',
    MIDDLE_PERIOD_MEDIUM: 'Regular set medium period',
    MIDDLE_PERIOD_HIGH: 'Regular set high period',
    ACCUMULATION_CYCLE: 'Accumulation cycle',
    MTF_TL_RATIO: 'Multi timeframes mode TL ratio',
    PRICE_ACTION_TL_RATIO: 'Price action TL ratio',
    PRICE_ACTION_THRESHOLD: 'Price action threshold',
    ALWAYS_USE_TL_MULTIPLIER: 'Always use TL multiplier',
    PANIC_CLOSE: 'Panic close',

    RSI_PERIOD: 'RSI period',
    SMA_PERIOD: 'SMA period',
    EMA_PERIOD: 'EMA period',
    MA_PERIOD: 'MA period',
    SHORT_PERIOD: 'Short period',
    LONG_PERIOD: 'Long period',
    PERIOD1: 'Period1',
    PERIOD2: 'Period2',
    STOP_LOSS: 'Stop loss'
  },

  autoconfigTooltips: {
    enabled: 'Use this job',
    schedule:
      'How often this job runs \n\nAlternatively, you can use cron-style notation like */6 * * * *, with an optional 6th place for seconds',
    type: 'The job type defines the possible output and filter options, can not be changed after creating a job',
    snapshots: 'Number of ticker snapshots kept \n\nSnapshots are collected according to job schedule',
    history:
      'Number of history ticker snapshots kept \n\nThe history interval defines how often a new history snapshot gets saved',
    historyInterval: 'Time between saving history ticker snapshots',
    debug: 'Get detailed logs for each filter in the console logs \n\nDisable for faster job execution',
    exchange: 'Sets the exchange the job works on',
    include:
      'Filters for including pairs, as a comma separated list \n\nFor example: "BTC-" includes all pairs beginning with BTC-, "BTC" includes all pairs that contain BTC',
    exclude:
      'Filters for excluding pairs, as a comma separated list \n\nFor example: "BTC-" excludes all pairs beginning with BTC-, "BTC" excludes all pairs that contain BTC',
    maxPairs: 'AutoConfig will not add more pairs for an exchange than set here',
    noBag: "Don't use this job for pairs with a quote balance big enough to sell",
    bag: 'Use this job only for pairs with a quote balance big enough to sell',
    removeDisabled: 'Remove all disabled pairs when at least one pair passes all filters in this job',
    notRemoveBefore:
      'Only remove pairs when they have been added at least x minutes ago, works for automatically added pairs only \n\nValue in minutes',
    noCrossOver: 'Prevent adding pairs with base/quote crossover, like BTC-ETH and ETH-XRM',
    removeBase: 'Remove all pairs with this base currency',
    baseFrom: 'Base currency to hedge away from',
    baseTo: 'Base currency to hedge to',
    hedgePair: 'Valid trading pair used for hedging, for example USDT-BTC',
    filteredQuote: 'List of quote coins to filter in bitRage, as a comma separated list',
    filteredPair: 'List of pairs to filter in bitRage, as a comma separated list',
    filteredBase: 'List of base coins to filter in bitRage, as a comma separated list',
    overrides: 'List of pair overrides to be set',
    strategy: 'Name of the strategy to assign to pairs passing all filters',
    setITB:
      'Set an Ignore Trades Before overrides when the job leads to config changes, with the current time as value',
    muteTG: 'Disable AutoConfig Telegram notifications for this job',
    clearOverrides: 'Remove all existing overrides before placing new ones',
    historyToggle:
      'Use a secondary ticker snapshot cache to store older ticker data, collected less frequently than the job schedule',
    delay: 'Change the exchange delay \n\nValue represents time in seconds',
  },
  autoconfigFilterTooltips: {
    minPrice: ['Minimum price, in base currency'],
    maxPrice: ['Maximum price, in base currency'],
    custom: ['Custom expression. Filter passes when expression returns true'],
    minPricePctChangeInterval: [
      'Minimum price change of last price vs average price in interval \n\nValue represents a percentage',
    ],
    maxPricePctChangeInterval: [
      'Maximum price change of last price vs average price in interval \n\nValue represents a percentage',
    ],
    minVolumePctChangeInterval: [
      'Minimum 24h volume change of last value vs average volume in interval \n\nValue represents a percentage',
    ],
    maxVolumePctChangeInterval: [
      'Maximum 24h volume change of last value vs average volume in interval \n\nValue represents a percentage',
    ],
    minVolume24h: ['Minimum 24h volume, in base currency'],
    maxVolume24h: ['Maximum 24h volume, in base currency'],
    minVolatilityPct24h: ['Minimum 24h change \n\nValue represents a percentage'],
    maxVolatilityPct24h: ['Maximum 24h change \n\nValue represents a percentage'],
    minSpreadPct: ['Minimum spread between best bid and ask \n\nValue represents a percentage'],
    maxSpreadPct: ['Maximum spread between best bid and ask \n\nValue represents a percentage'],
    minSlopePctInterval: [
      'Minimum linear regression slope \n\nExpressed as percentage from last price \n\nValue represents a percentage',
    ],
    maxSlopePctInterval: [
      'Maximum linear regression slope \n\nExpressed as percentage from last price \n\nValue represents a percentage',
    ],
    minStandardDevPctInterval: [
      'Minimum standard deviation \n\nExpressed as percentage from last price \n\nValue represents a percentage',
    ],
    maxStandardDevPctInterval: [
      'Maximum standard deviation \n\nExpressed as percentage from last price \n\nValue represents a percentage',
    ],
    belowMedianVolume: [],
    aboveMedianVolume: [],
    minVolumeRank: ['Minimum volume rank'],
    maxVolumeRank: ['Maximum volume rank'],
    bullishStandardDeviationChannel: [
      'Target from lower standard deviation band \n\nRange 0 = same price as lower band \n\nRange 100 = same price as upper band',
    ],
    bearishStandardDeviationChannel: [
      'Target from upper standard deviation band \n\nRange 0 = same price as upper band \n\nRange 100 = same price as lower band',
    ],
    linearRegressionChannel: [
      'Target from lower linear regression channel band \n\nRange 0 = same price as lower band \n\nRange 100 = same price as upper band',
    ],
    allowsHedging: ['Base of pair used for hedging', 'Quote of pair used for hedging'],
    buyTrailing: [
      'Minimum distance below EMA of all snapshots \n\nValue represents a percentage',
      'Percentage of price to use as trailing range \n\nValue represents a percentage',
    ],
    volumeTrailing: [
      'Minimum distance below EMA of all snapshots \n\nValue represents a percentage',
      'Percentage of 24h volume to use as trailing range \n\nValue represents a percentage',
    ],
    slopeTrailing: [
      'Minimum distance below EMA of all snapshots \n\nValue represents a percentage',
      'Percentage of slope to use as trailing range \n\nValue represents a percentage',
    ],
    minPriceHistory: ['Minimum price at the time of defined history source, in base currency'],
    maxPriceHistory: ['Maximum price at the time of defined history source, in base currency'],
    maxVolumeRankHistory: ['Maximum volume rank at the time of defined history source'],
    minVolumeRankHistory: ['Minimum volume rank at the time of defined history source'],
    minPricePctChangeIntervalHistory: [
      'Minimum price change of history source price vs average price in history \n\nValue represents a percentage',
    ],
    maxPricePctChangeIntervalHistory: [
      'Maximum price change of history source price vs average price in history \n\nValue represents a percentage',
    ],
    minVolumePctChangeIntervalHistory: [
      'Minimum 24h volume change of history source price vs average volume in history \n\nValue represents a percentage',
    ],
    maxVolumePctChangeIntervalHistory: [
      'Maximum 24h volume change of history source price vs average volume in history \n\nValue represents a percentage',
    ],
    minVolume24hHistory: ['Minimum 24h volume at the time of defined history source, in base currency'],
    maxVolume24hHistory: ['Maximum 24h volume at the time of defined history source, in base currency'],
    minVolatilityPct24hHistory: [
      'Minimum 24h change at the time of defined history source \n\nValue represents a percentage',
    ],
    maxVolatilityPct24hHistory: [
      'Maximum 24h change at the time of defined history source \n\nValue represents a percentage',
    ],
    minSpreadPctHistory: [
      'Minimum spread between best bid and ask at the time of defined history source \n\nValue represents a percentage',
    ],
    maxSpreadPctHistory: [
      'Maximum spread between best bid and ask at the time of defined history source \n\nValue represents a percentage',
    ],
    minSlopePctIntervalHistory: [
      'Minimum linear regression slope of history data \n\nExpressed as percentage from the price at defined history source \n\nValue represents a percentage',
    ],
    maxSlopePctIntervalHistory: [
      'Maximum linear regression slope of history data \n\nExpressed as percentage from the price at defined history source \n\nValue represents a percentage',
    ],
    minStandardDevPctIntervalHistory: [
      'Minimum standard deviation of history data \n\nExpressed as percentage from the price at defined history source \n\nValue represents a percentage',
    ],
    maxStandardDevPctIntervalHistory: [
      'Maximum standard deviation of history data \n\nExpressed as percentage from the price at defined history source \n\nValue represents a percentage',
    ],
    bearishStandardDeviationChannelHistory: [
      'Target from lower standard deviation band of history data \n\nRange 0 = same price as lower band \n\nRange 100 = same price as upper band',
    ],
    bullishStandardDeviationChannelHistory: [
      'Target from upper standard deviation band of history data \n\nRange 0 = same price as upper band \n\nRange 100 = same price as lower band',
    ],
    exact: [
      'Name of key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Exact value to match, depending on the chosen label this value can be a number, string or boolean',
    ],
    biggerThan: [
      'Name of key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Minimum target  \n\nValue must be a number',
    ],
    smallerThan: [
      'Name of key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Maximum target  \n\nValue must be a number',
    ],
    pairVariableBiggerThan: ['Exact name of variable', 'Minimum target  \n\nValue must be a number'],
    pairVariableSmallerThan: ['Exact name of variable', 'Maximum target  \n\nValue must be a number'],
    variableBiggerThan: ['Exact name of variable', 'Minimum target  \n\nValue must be a number'],
    variableSmallerThan: ['Exact name of variable', 'Maximum target  \n\nValue must be a number'],
    compareBigger: [
      'Value of this item must be bigger \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Value of this item must be smaller \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
    ],
    compareSmaller: [
      'Value of this item must be smaller \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Value of this item must be bigger \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
    ],
    differenceBigger: [
      'Value of this item must be bigger \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Value of this item must be smaller \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Minimum delta between label 1 and label 2 \n\nValue represents a percentage',
    ],
    differenceSmaller: [
      'Value of this item must be smaller \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Value of this item must be bigger \n\nEnter the name of the key from pair state to filter on \n\nThe dropdown shows often used items, custom input is possible',
      'Minimum delta between label 1 and label 2 \n\nValue represents a percentage',
    ],
    variableExact: ['Exact name of variable to match', 'Exact value of variable'],
    variableNotExist: ['Exact name of variable'],
    pairVariableExact: ['Exact name of pair specific variable to match', 'Exact value of pair specific variable'],
    strategyName: ['Exact strategy name'],
    minTimeInConfig: ['Minimum time since AutoConfig added a trading pair \n\nValue represents time in minutes'],
    maxTimeInConfig: ['Maximum time since AutoConfig added a trading pair \n\nValue represents time in minutes'],
    maxSameOrder: ['Maximum number of most recent orders to NOT have the defined type', 'Order type to search for'],
    minTimeSinceOrder: [
      'Minimum time since specified order type, for the same pair \n\nValue represents time in minutes',
      'Order type to search for',
    ],
    maxTimeSinceOrder: [
      'Maximum time since specified order type, for the same pair \n\nValue represents time in minutes',
      'Order type to search for',
    ],
    name: ['Unique name for this filter \n\nCannot be changed after saving'],
    lastSnapshots: ['Number of most recent ticker snapshots to use'],
    useLastSnapshots: ['Use a subset of collected ticker data for this filter'],
    historySource: [
      'History source target \n\nValue must be numerical and represents a specific value from history data \n\n0 is equal to the oldest data',
    ],
    newFilter: ['Select a filter type'],
    filterSet: [
      'Select a filter set for creating a new filter in, or to select existing filters from \n\nA job will lead to output when all filters in at least one filterset are passed',
    ],
    editFilter: ['Select an existing filter to edit'],
    select: ['This is a placeholder text for when no filter type is selected'],
    '': ['This is a placeholder text for when no filter type is selected'],
  },

  // Readable titles for autoconfig parameters
  autoconfigTitles: {
    enabled: 'Enabled',
    schedule: 'Schedule',
    type: 'Type',
    snapshots: 'Ticker snapshots',
    history: 'History snapshots',
    historyInterval: 'History interval',
    debug: 'Debug',
    exchange: 'Exchange',
    include: 'Include',
    exclude: 'Exclude',
    maxPairs: 'Max pairs',
    noBag: 'Not for bags',
    bag: 'Only for bags',
    removeDisabled: 'Remove disabled pairs',
    notRemoveBefore: "Don't remove before",
    noCrossOver: 'Prevent crossover',
    removeBase: 'Base to remove',
    hedgeTo: 'Hedge to',
    baseFrom: 'Base from',
    baseTo: 'Base to',
    hedgePair: 'Hedge pair',
    filteredQuote: 'Filtered quote',
    filteredPair: 'Filtered pairs',
    filteredBase: 'Filtered base',
    overrides: 'Overrides',
    strategy: 'Strategy',
    setITB: 'Set ITB',
    muteTG: 'Mute Telegram notifications',
    clearOverrides: 'Clear overrides',
    delay: 'Exchange delay',
    brStrat: 'bitRage strategy name',
  },

  // Tab description texts
  tabDescriptions: {
    Tournament:
      'Join exclusive trading tournaments for Gunbot owners only. \nClick the question mark icon to get details about the current or upcoming tournament',
    'Trading Pairs': 'Add trading pairs and assign the strategy you want to run',
    'Override Settings': 'Pair specific strategy settings',
    'Modify Pairs': 'Change settings for existing trading pairs',
    'Filter Rules': 'Add filters to select trading pairs',
    'Create new trading strategy':
      'Create a new trading strategy. Strategies can be assigned to one or more trading pairs',
    Buy: 'Set the primary buy trigger for your strategy',
    Sell: 'Set the primary sell trigger for your strategy',
    Balance:
      'Configure how much the strategy is allowed to spend per trade. \nMake sure to set the minimums correctly for the pairs you want to run the strategy on',
    Indicator: 'Edit the main indicators used in the buy or sell method, or enable additional confirming indicators',
    'Dollar Cost Avg': 'With Double Up you can average down losing trades, to reduce the average price per unit',
    'Reversal Trading':
      "With RT you can accumulate an asset below it's break even price without investing additional funds",
    TrailMe: 'Add additional price trailing for selected order types',
    Margin: 'Settings specific to margin trading on supported exchanges',
    Misc: 'Various setting to change the order type, frequency and more',
    Bot: 'Global bot settings. Affects every trading pair and trading strategy',
    'Cancel Orders': 'Global settings for cancel order behavior. \nAffects every trading pair',
    Advanced: 'Various settings for advanced users',
    Notifications: 'Set which types of notifications you want to receive in this browser interface',
    'Step 1: enter your Gunthy wallet address':
      'Use the registered ERC-20 wallet address in which your Gunthy tokens are stored',
    'Step 2: add API keys for registered exchanges':
      "Connect to the exchange(s) that you've already registered. \nYou can use the same registered API key for both the master and trading key",
    Exchanges:
      'Connect a new registered exchange API key. \nMake sure to only use master keys that are already registered for your license. \nIn case you use a separate trading key, make sure it exists on the same exchange account as the registered key',
    'Connected Exchanges': "Edit or remove API keys for exchanges you've already connected",
    Telegram: 'Settings to control the built in Telegram bot, used for trade notifications and more',
    'Alert execution':
      'Settings to control how incoming email alerts are handled. \nMake sure to enable the email listener to start receiving alerts',
    'Email listener':
      'Connect your IMAP compatible mail box to be able to receive alerts. \nListening starts immediately once you enable it',
    'User Variables':
      'Variables are set when one pair passes all filters in a job. You can use them as filter criteria. Pair variables are saved and used for individual pairs',
    Webhooks: 'Receive alerts from TradingView webhooks',
    Filters:
      'All filters in a filterset must be passed to generate an output. When multiple filter sets exist in one job, output is generated when at least one pair passes all filters in one set',
    Pairs: 'Settings to specify which pairs on which exchange are affected by this job.',
    General: 'Configure how frequent your job runs, and more general settings',
    'Manage API Slots': 'Self manage your registered API keys',
    'Custom strategy': 'Configure which strategy filename contains your code, and general settings that affect the GUI',
    Data: 'Configure the inputs for standard indicators. These settings do not trigger any orders, they only influence how indicators are calculated. You can use these indicators in any custom strategy',
    Rename:
      'Change your strategy name if needed. \nMake sure to also update the assigned strategy for pairs, the bot will error when a pair has a strategy assigned that no longer exists under the same name',
    'GUI Authentication': 'Change the security settings for the Gunbot interface',
    'Gunthy wallet': 'Enter the registered ERC-20 wallet address that holds your Gunthy tokens',
    'Automate settings changes with AutoConfig':
      'Create scheduled tasks to automate settings changes based on custom filter criteria',
    'Base settings':
      'Basic settings to control how Market Maker opens and adds to positions. The default behavior is that maker orders are placed directly around bid/ask. \n\nMarket Maker is only available to eligible users: Top 10 token rank or better',
    'Close Position': 'Close positions directly when reaching ROE, or trail for higher profits',
    'Risk Management':
      'Manage your risks with these options and reduce positions when they get too heavy. Beware that all hard reduction options cause an immediate loss. \n To prevent unwanted behavior: make sure to set everything in a way that fits your wallet size!',
    'Strategy variants':
      'These options fundamentally change how Market Maker enters posititions and when DCA orders are placed. \nPick any one strategy variant: you cannot combine Grid, Fibonacci and/or Support / Resistance',
    Spot: 'Options specific to spot trading with Market Maker',
    Other: 'Misc and indicator settings used by the selected market maker strategy variant',
    Bitrage:
      'Basic settings to control how bitRage works and which strategy is used to handle bags. \n\nMake sure to also set your balance and filter settings on the profile page, for each exchange you use bitRage on. \nIt is recommended to have only one bitRage strategy. The last one created automatically sets the bitRage strategy name used in the autopairs functionality \n\nbitRage is only available to eligible users: Top 20 token rank or better',
    Hedging: 'Use hedging to switch between accumulating USDT and BTC, depending on the direction of the market',
    Basic:
      'All essential settings for this strategy. This strategy is a bit different, common settings like confirming indicator have no role at all, there is no stop limit. \nIf market goes up, it rides the trend. If market goes down, it accumulates in ever increasing steps',
    'Advanced ':
      'This strategy was developed for 15m candles with an SMA period of 50 to calculate support and resistance levels. \nTrailing limits are dynamic, optionally you can use an automatic gain target',
    'Basic settings':
      'Configure how much the strategy is allowed to spend per trade. Max buy count it set very high intentionally, to never stop trading.\nMake sure to set min sell volume correctly for the pairs you want to run the strategy on',
    'Step settings':
      'This strategy buys each step down, and sells each step up. Above break even it closes a bag in several steps. Step size is automatic by default, but you can customize it.',
  },

  // Tab descriptions for Autoconfig output
  tabDescriptionsAutoconfigOutput: {
    addPairs:
      'Set the strategy name for pairs added by this job. Optionally you can also set overrides for newly added pairs.',
    removePairs: 'This job type removes pairs, there are no output settings needed.',
    removePairs2: 'This job type removes pairs, there are no output settings needed.',
    changeStrategy: 'Define the strategy to be assigned to pairs added by this job.',
    changeStrategy2: 'Define the strategy to be assigned to pairs added by this job.',
    manageOverrides: 'Set which overrides are used for pairs that pass all filters.',
    changeDelay: 'Change the exchange delay when at least one pair passes all filters.',
    manageBotSettings: 'Define which bot settings to change when at least one pair passes all filters.',
    hedgeGB: 'This job type starts hedging when one pair passes all filters, using the pair settings.',
    hedgeGB2: 'This job type starts hedging when one pair passes all filters, using the pair settings.',
    hedge:
      'This job type starts hedging when one pair passes all filters. Optionally you can update the bitRage filters when this happens.',
    filteredQuote:
      'This job type automatically fills the bitRage filteredQuote list with quote coins that passed all filters. There are no output settings needed.',
    collectData: '',
    backtesting: '',
  },

  // External links, placed tabs with specified title
  tabDescriptionLinks: {
    Tournament: 'https://wiki.gunthy.org/setup-and-general-settings/profile-settings/tournament',
    'Trading Pairs':
      'https://wiki.gunthy.org/how-to-work-with-gunbot/editing-strategies-and-pairs/trading-pairs#add-trading-pairs',
    'Override Settings':
      'https://wiki.gunthy.org/how-to-work-with-gunbot/editing-strategies-and-pairs/trading-pairs#override-settings',
    'Modify Pairs':
      'https://wiki.gunthy.org/how-to-work-with-gunbot/editing-strategies-and-pairs/trading-pairs#override-settings',
    'Create new trading strategy':
      'https://wiki.gunthy.org/how-to-work-with-gunbot/editing-strategies-and-pairs/strategy-editor',
    Buy: 'https://docs.gunthy.org/docs/built-in-strategies/about-gunbot-strategies/trading-methods#available-buy-and-sell-methods',
    Sell: 'https://docs.gunthy.org/docs/built-in-strategies/about-gunbot-strategies/trading-methods#available-buy-and-sell-methods',
    Balance: 'https://wiki.gunthy.org/how-to-work-with-gunbot/basic-workings/balance-settings',
    Indicator: 'https://docs.gunthy.org/docs/built-in-strategies/confirming-indicators',
    'Dollar Cost Avg': 'https://docs.gunthy.org/docs/built-in-strategies/dollar-cost-avg-dca',
    'Reversal Trading': 'https://docs.gunthy.org/docs/built-in-strategies/reversal-trading-rt',
    TrailMe: 'https://docs.gunthy.org/docs/built-in-strategies/trailme',
    Margin: 'https://docs.gunthy.org/docs/built-in-strategies/margin-trading-strategies',
    Misc: 'https://docs.gunthy.org/docs/built-in-strategies/misc-settings',
    Bot: 'https://wiki.gunthy.org/setup-and-general-settings/global-settings/bot-settings',
    'Cancel Orders': 'https://wiki.gunthy.org/setup-and-general-settings/global-settings/cancel-orders',
    Advanced: 'https://wiki.gunthy.org/setup-and-general-settings/global-settings/advanced',
    Notifications: 'https://wiki.gunthy.org/setup-and-general-settings/preferences/gui-notifications',
    'Step 1: enter your Gunthy wallet address':
      'https://wiki.gunthy.org/setup-and-general-settings/exchange-and-license-settings/gunthy-wallet/how-to-create-a-wallet',
    'Step 2: add API keys for registered exchanges':
      'https://wiki.gunthy.org/setup-and-general-settings/exchange-and-license-settings/connect-exchange',
    Exchanges: 'https://wiki.gunthy.org/setup-and-general-settings/profile-settings/connect-exchange',
    'Gunthy wallet': 'https://wiki.gunthy.org/setup-and-general-settings/profile-settings/gunthy-wallet',
    'Update Registered Gunthy Wallet': 'https://wiki.gunthy.org/',
    'GUI Authentication': 'https://wiki.gunthy.org/setup-and-general-settings/profile-settings/gui-authentication',
    'Manage API Slots': 'https://wiki.gunthy.org/setup-and-general-settings/global-settings/edit-license',
    Telegram: 'https://wiki.gunthy.org/setup-and-general-settings/profile-settings/telegram-notifications',
    'Email listener': 'https://wiki.gunthy.org/how-to-work-with-gunbot/extras/tradingview-add-on/imap-listener',
    Webhooks: 'https://wiki.gunthy.org/how-to-work-with-gunbot/extra-modules/tradingview-webhooks',
    'Alert execution': 'https://wiki.gunthy.org/how-to-work-with-gunbot/extras/tradingview-add-on',
    'Automate settings changes with AutoConfig': 'https://wiki.gunthy.org/how-to-work-with-gunbot/extras/autoconfig',
  },

  alertContents: {
    /*"Alert execution": ["item1", "item2"],
        "Manage API Slots": ["item1", "item2"],
        "Exchanges": ["item1", "item2"],
        "Sell": ["item1", "item2"]*/
    'this is a tab title': ['item1', 'item2'],
  },

  marginExchanges: [
    'bitmex',
    'bitmex_testnet',
    'krakenFutures',
    'binanceFutures',
    'futures_gunthy',
    'kumex',
    'bitget',
    'ftx',
    'bybit',
    'okex5',
    'okgunbot',
    'dydx',
  ],

  bothExchanges: [
    'bybit',
    'bitget',
    'okex5'
  ],

  // list of all available exchanges
  ccxtExchanges: [
    'ftxus',
    'mex_gunthy',
    '_1btcxe',
    'acx',
    'allcoin',
    'anxpro',
    'anybits',
    'bcex',
    'beaxy',
    'bequant',
    'bibox',
    'bigone',
    'binance',
    'binanceje',
    'bit2c',
    'bitbank',
    'bitbay',
    'bitfinex',
    'bitfinex2',
    'bitflyer',
    'bitforex',
    'bithumb',
    'bitibu',
    'bitkk',
    'bitlish',
    'bitmart',
    'bitmarket',
    'nashio',
    'bitmex',
    'bitsane',
    'bitso',
    'bitstamp',
    'bitstamp1',
    'bittrex',
    'bitz',
    'bl3p',
    'bleutrade',
    'braziliex',
    'btcalpha',
    'btcbox',
    'btcchina',
    'btcexchange',
    'btcmarkets',
    'btctradeim',
    'btctradeua',
    'btcturk',
    'buda',
    'bxinth',
    'ccex',
    'cex',
    'chbtc',
    'chilebit',
    'cobinhood',
    'coinbase',
    'coinbaseprime',
    'coincheck',
    'coinegg',
    'coinex',
    'coinexchange',
    'coinfalcon',
    'coinfloor',
    'coingi',
    'coinmarketcap',
    'coinmate',
    'coinnest',
    'coinone',
    'coinspot',
    'cointiger',
    'coolcoin',
    'coss',
    'crex24',
    'cryptocom',
    'crypton',
    'cryptopia',
    'deribit',
    'dsx',
    'dx',
    'ethfinex',
    'exmo',
    'exx',
    'fcoin',
    'fcoinjp',
    'flowbtc',
    'foxbit',
    'fybse',
    'fybsg',
    'gateio',
    'gdax',
    'gemini',
    'getbtc',
    'hadax',
    'hitbtc',
    'huobi',
    'huobiru',
    'ice3x',
    'independentreserve',
    'indodax',
    'itbit',
    'jubi',
    'kkex',
    'kraken',
    'kucoin',
    'kuna',
    'lakebtc',
    'lbank',
    'liqui',
    'liquid',
    'livecoin',
    'luno',
    'lykke',
    'mandala',
    'mercado',
    'mixcoins',
    'ftx',
    'negociecoins',
    'binanceus',
    'bitmex_testnet',
    'nova',
    'bitget',
    'latoken',
    'binanceFutures',
    'futures_gunthy',
    'krakenFutures',
    'bybit',
    'okcoincny',
    'kumex',
    'okcoinusd',
    'gunthy',
    'okex5',
    'okgunbot',
    'paymium',
    'poloniex',
    'quadrigacx',
    'rightbtc',
    'southxchange',
    'stronghold',
    'surbitcoin',
    'theocean',
    'therock',
    'tidebit',
    'tidex',
    'uex',
    'upbit',
    'urdubit',
    'vaultoro',
    'vbtc',
    'virwox',
    'xbtce',
    'yobit',
    'zaif',
    'zb',
    'txbit',
    'mexc',
  ],

  // List of often used state keys in Autoconfig
  stateKeys: [
    'ABP',
    'Ask',
    'AskVolume',
    'Bid',
    'BidVolume',
    'CHIKOU',
    'FAST_SMA',
    'KIJUN',
    'MACD',
    'MACD_HISTOGRAM',
    'MACD_SIGNAL',
    'ROE',
    'SLOW_SMA',
    'STOCHRSI',
    'STOCH_D',
    'STOCH_K',
    'TENKAN',
    'actualSENKOUSPANA',
    'actualSENKOUSPANB',
    'adx',
    'atr',
    'availableMargin',
    'avgEntryPrice',
    'baseBalance',
    'countsell',
    'currentQty',
    'diminus',
    'diplus',
    'ducount',
    'dudone',
    'ema1',
    'ema2',
    'ema3',
    'forecast',
    'highBB',
    'liquidationPrice',
    'lowBB',
    'mfi',
    'ourBaginBase',
    'pKIJUN',
    'pTENKAN',
    'pastSENKOUSPANA',
    'pastSENKOUSPANB',
    'prevAsk',
    'prevBid',
    'quoteBalance',
    'reversal',
    'rsi',
    'rtsoldprice',
  ],

  // static and non exchange specific data needed to render new strat editor
  easyStratEditor: {
    gridbot: {
      info: {
        description:
          'Limit grid strategy with options to rebalance and set the size and number of grids.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/gridbots/',
        market: 'spot',
        license: ['one', 'standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "gridbot",
        sellMethod: "gridbot",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
    The general format is: BASECOIN-QUOTECOIN
            
    All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
    With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
    All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
    With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Strategy settings': {
        description: 'Configure a gridbot',
        parameters: {
          TRADING_LIMIT: {
            label: 'Invest per buy order',
            defaultValue: '20', 
            tooltip: `The base amount to invest for each individual buy order. Setting 10 on USDT-BTC would spend 10 USDT on each buy order.`,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          },
          FUNDS_RESERVE: {
            defaultValue: '0', 
            inputType: 'string',
          },
          USE_REBALANCE: {
            label: 'Use rebalance',
            defaultValue: false,
            tooltip: `Activating this feature triggers the purchase of assets equal to the value of 'max orders' multiplied by 'invest per order' when the bot lacks sufficient assets to sell. When disabled, it works only with available balances.`,
            inputType: 'boolean',
          },
          gbInterval: {
            label: 'Steps %',
            defaultValue: 'auto', 
            tooltip: `This option sets the percentage interval for creating the grid, for example set 1.2 to get grids of 1.2%. If set to 'auto', the grid size is automatically adjusted based on ATR (Average True Range).`,
            inputType: 'string',
          },
          MAX_GRID_ORDERS: {
            label: 'Max grid orders',
            defaultValue: '5',
            tooltip: `Defines the maximum number of orders that can be open on each side of the trade. For instance, a setting of 5 allows for up to 5 buy and 5 sell orders simultaneously.`,
            inputType: 'range',
            rangeLowHigh: [0, 50],
            rangeInput: [0, 999],
          },
          STOP_LIMIT: {
            label: 'Stop loss',
            tooltip: 'Defines when to take losses. Setting 10 would cause all assets to be sold when their current value exceeds 10% loss compared to the break even price.',
            defaultValue: '99999',
            inputType: 'string',
          },
          PERIOD: {
            defaultValue: '15', 
            tooltip: `Sets which candles are used to display the chart and calculate ATR.`,
            inputType: 'select',
          },
        },
      },
    },
    gridbotadvanced: {
      info: {
        description:
          'Limit grid strategy with options to rebalance and set the size and number of grids.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/gridbots/',
        market: 'spot',
        license: ['one', 'standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "gridbotadvanced",
        sellMethod: "gridbotadvanced",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
    The general format is: BASECOIN-QUOTECOIN
            
    All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
    With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
    All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
    With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Strategy settings': {
        description: 'Configure a gridbot',
        parameters: {
          TRADING_LIMIT: {
            label: 'Invest per buy order',
            defaultValue: '20', 
            tooltip: `The base amount to invest for each individual buy order. Setting 10 on USDT-BTC would spend 10 USDT on each buy order.`,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          },
          FUNDS_RESERVE: {
            defaultValue: '0', 
            inputType: 'string',
          },
          USE_REBALANCE: {
            label: 'Use rebalance',
            defaultValue: false,
            tooltip: `Activating this feature triggers the purchase of assets equal to the value of 'max orders' multiplied by 'invest per order' when the bot lacks sufficient assets to sell. When disabled, it works only with available balances.`,
            inputType: 'boolean',
          },
          gbInterval: {
            label: 'Steps %',
            defaultValue: 'auto', 
            tooltip: `This option sets the percentage interval for creating the grid, for example set 1.2 to get grids of 1.2%. If set to 'auto', the grid size is automatically adjusted based on ATR (Average True Range).`,
            inputType: 'string',
          },
          MAX_GRID_ORDERS: {
            label: 'Max grid orders',
            defaultValue: '5',
            tooltip: `Defines the maximum number of orders that can be open on each side of the trade. For instance, a setting of 5 allows for up to 5 buy and 5 sell orders simultaneously.`,
            inputType: 'range',
            rangeLowHigh: [0, 50],
            rangeInput: [0, 999],
          },
          STOP_LIMIT: {
            label: 'Stop loss',
            tooltip: 'Defines when to take losses. Setting 10 would cause all assets to be sold when their current value exceeds 10% loss compared to the break even price.',
            defaultValue: '99999',
            inputType: 'string',
          },
          PERIOD: {
            defaultValue: '15', 
            tooltip: `Sets which candles are used to display the chart and calculate ATR.`,
            inputType: 'select',
          },
        },
      },
    },
    stepgrid: {
      info: {
        description:
          'Grid strategy with integrated trailing. Can do partial sell orders below break even. Targets up and down are set around last order rate.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/stepgrid/',
        market: 'spot',
        license: ['one', 'standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "stepgrid",
        sellMethod: "stepgrid",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MAX_BUY_COUNT: {
            defaultValue: '40',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in sell mode only. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 999],
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          }
        },
      },
      'Customize grid': {
        description: 'Options to change grid step size',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          AUTO_STEP_SIZE: {
            defaultValue: true,
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility`,
            inputType: 'boolean',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.`,
            inputType: 'string',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
          unit_cost: {
            defaultValue: true,
            tooltip: `Determines how the break even price is calculated. \n\nWhen enabled: break even represents the average price per unit of remaining units in balance \n\nWhen disabled: break even line represents the point at which the remaining units can be sold for the total trade sequence to break even.`,
            inputType: 'boolean',
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          FOREVER_BAGS: {
            defaultValue: false,
            tooltip: `Used to 'ride trends up'. \n\nThe default strategy behavior is to exit a trade in one or a small number of sell orders above the break even price. \n\nUsing the 'forever bags' option the break even price is completely ignored and each buy and sell order is always placed for 1x 'trading limit'. Buy count is not available when forever bags is enabled.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          PROTECT_PARTIAL_SELL: {
            defaultValue: true,
            tooltip: `Prevents partial sell orders from being fired at rates where there is no buy volume below it, and would cause a loss.
            
This relies on Gunbot having the full order history for the current position, for example it cannot work when you deposit funds and use stepgrid to sell them.`,
            inputType: 'boolean',
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          ATR_PERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate Average True Range, which is used to calculate auto step size. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    spotgrid: {
      info: {
        description:
          'DCA strategy with automatic buydown levels and price trailing. Invest per trade is static. Buy target increases with each buy order, does not sell below break even. ',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/spotgrid/',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "spotgrid",
        sellMethod: "spotgrid",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MAX_BUY_COUNT: {
            defaultValue: '40',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in sell mode only. \n\nA value of 15 means that the total position size may not exceed 15x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 999],
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          }
        },
      },
      'Customize targets': {
        description: 'Options to change trading targets',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          AUTO_GAIN: {
            defaultValue: true,
            tooltip: `Use a dynamic profit target. The dynamic target is based on the distance between support and resistance, capped at a minimum profit of 2x trading fees. \n\nThe target decreases when position size increases. Overrules a manually set target.`,
            inputType: 'boolean',
          },
          GAIN: {
            defaultValue: '0.5',
            tooltip: `Set a manual profit target. \n\nEach time price reaches this percentage above break even, a sell order gets placed.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          GRID_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the distance between buy orders. Default value 1 means that the hardcoded levels are used. \n\n1.5 would increase the levels with a factor 1.5x, a value of 0.5 would halve the default levels`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          unit_cost: {
            defaultValue: true,
            inputType: 'boolean',
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate buydown targets and trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    spotgridadvanced: {
      info: {
        description:
          'DCA strategy with automatic buydown levels and price trailing. Can increase DCA invest per trade. Buy target increases with each buy order, can do partial sell orders below break even. ',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/spotgridadvanced',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "spotgridadvanced",
        sellMethod: "spotgridadvanced",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order. \n\nWhen using trading limit multiplier, this is the value for the first buy order.`,
            inputType: 'string',
          },
          TL_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the invested amount with each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 500],
          },
          MAX_BUY_COUNT: {
            defaultValue: '15',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in sell mode only. \n\nA value of 15 means that the total position size may not exceed 15x the amount set as 'trading limit' \n\nWhen trading limit multiplier is used, the increased investment per buy order is factored into 'buy count'.\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          },
          FUNDS_RESERVE: {
            defaultValue: '0', 
            inputType: 'string',
          },
        },
      },
      'Customize targets': {
        description: 'Options to change trading targets',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          AUTO_GAIN: {
            defaultValue: true,
            tooltip: `Use a dynamic profit target. The dynamic target is based on the distance between support and resistance, capped at a minimum profit of 2x trading fees. \n\nThe target decreases when position size increases. Overrules a manually set target.`,
            inputType: 'boolean',
          },
          GAIN: {
            defaultValue: 0.5,
            tooltip: `Set a manual profit target. \n\nEach time price reaches this percentage above break even, a sell order gets placed.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 10],
            rangeInput: [-100, 999],
          },
          GRID_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the distance between buy orders. Default value 1 means that the hardcoded levels are used. \n\n1.5 would increase the levels with a factor 1.5x, a value of 0.5 would halve the default levels`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
          TRAILING_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the trailing range for all order types. Default value 1 means that the hardcoded range is used. \n\n1.5 would increase the range with a factor 1.5x, a value of 0.5 would halve the default trailing range`,
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
          unit_cost: {
            defaultValue: true,
            tooltip: `Determines how the break even price is calculated. \n\nWhen enabled: break even represents the average price per unit of remaining units in balance \n\nWhen disabled: break even line represents the point at which the remaining units can be sold for the total trade sequence to break even.`,
            inputType: 'boolean',
          },
        },
      },
      'Trend options': {
        description: 'Higher timeframe trend watcher',
        parameters: {
          TREND_OPEN: {
            defaultValue: false,
            tooltip: `When enabled, new positions are only opened when both 4h and 15m charts indicate a bullish market. \n\nThis check is independent from the PERIOD in your strategy. Requires the exchange to offer both 15m and 4h candle data`,
            inputType: 'boolean',
          },
          TREND_BLOCK_DCA: {
            defaultValue: false,
            tooltip: `When enabled, DCA trades are only allowed when both 4h and 15m charts indicate a bullish market \n\nThis check is independent from the PERIOD in your strategy. Requires the exchange to offer both 15m and 4h candle data`,
            inputType: 'boolean',
          },
          TREND_LOWER_DCA: {
            defaultValue: false,
            tooltip: `When enabled, the DCA distance gets doubled when the 4h and/or 15m chart do not indicate a bullish market \n\nThis check is independent from the PERIOD in your strategy. Requires the exchange to offer both 15m and 4h candle data`,
            inputType: 'boolean',
          },
          TREND_CT_MULTIPLIER: {
            defaultValue: '2',
            tooltip: `Multiplier to adjust CT buy target while trend is not bullish and Dynamic DCA distance is used\n\nA value of 2 would double the distance, a value of 1 results in the same behavior as when Dynamic DCA distance is disabled`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
          TREND_GRID_MULTIPLIER: {
            defaultValue: '2',
            tooltip: `Multiplier to adjust the next DCA target while trend is not bullish and Dynamic DCA distance is used\n\nA value of 2 would double the distance, a value of 1 results in the same behavior as when Dynamic DCA distance is disabled`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          CT_TL_MULTIPLIER: {
            defaultValue: '0.5',
            tooltip: `Sets the ratio between last buy order invest in base, and the amount used for trades during continuous trading. Value between 0 and 1. \n\nWhen set to 0.5, halve of the base amount used for the previous DCA trade will be used for continuous trading. Setting 1 would use the same amount as the last DCA buy order`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
          START_CONT_TRADING: {
            defaultValue: '3',
            tooltip: `Continuous trading allows you to continue trading while the current price is below break even, with the aim of lowering break even by accumulating quote and to gain base balance. You need to have a trading limit that is at least 2x the exchange defined minimum order size for this feature to work. \n\n This setting defines from which buy count continuous trading is allowed. \n\n A value of 3 means that when the position is 3x trading limit in size, the next sell target is placed above the last buy rate instead of above break even. Set a very high value to effectively disable this feature`,
            inputType: 'range',
            rangeLowHigh: [1, 40],
            rangeInput: [1, 200],
          },
          CT_RESTART_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Sets the distance between a CT sell and a buy above that. Default is 1, should usually not be changed. \n\nExamples:\n\n1: buy target above last sell rate is placed once price is 1x the distance between sup/res above last sell rate \n\n0.5: buy target above last sell rate is placed once price is 0.5x the distance between sup/res above last sell rate`,
            inputType: 'range',
            rangeLowHigh: [1, 40],
            rangeInput: [1, 200],
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate buydown targets and trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    stepgridhybrid: {
      info: {
        description: 'Crossover between pure grid trading, TA trading and price action trading.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/stepgridhybrid/',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: ['bittrex'],
        buyMethod: "stepgridhybrid",
        sellMethod: "stepgridhybrid",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '80', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The normal amount to invest for each individual buy order. \n\nThis strategy uses several multipliers, the actual invest per order can be between 0.25 and 2 times the specific trading limit.`,
            inputType: 'string',
          },
          TL_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the invested amount for each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 500],
          },
          USE_TLR: {
            defaultValue: false,
            tooltip: `When enabled, the strategy will use a dynamic buy amount depending on if price is relatively high (lower amount) or relatively low (higher amount). \n\nAmount ranges between 0.25 and 1 times trading limit\n\nIf you use this, make sure to set a value for trading limit that is at least 4x the minimum order size`,
            inputType: 'boolean',
          },
          USE_PSR: {
            defaultValue: false,
            tooltip: `When enabled, the strategy sets partial sell ratio automatically. It will use a higher value when price is relatively low, and a lower value when price is relatively high.\n\nWhat this means is that when prices are relatively high, partial sell orders will use less of the available balance at these rates, and aim to bring more sell volume to higher prices. When prices are relatively low, each partial sell order will sell almost the full amount of the previous buy order.`,
            inputType: 'boolean',
          },
          PARTIAL_SELL_RATIO: {
            defaultValue: '0.95',
            tooltip: `The strategy is aware of how much volume was bought below the current price, and how much remains to be sold profitably. If a sell step is reached where nothing can be sold profitably, the step is skipped. \n\nWhen placing a sell order below the overall break even rate, the sell volume is defined by partial sell ratio.\n\nIf for example 200 units were bought at rates lower than the current price, a partial sell of 0.4 will lead to a partial sell order of 80 units.\n\nValues above 1 will cause immediate losses because it forces the bot to sell more than it bought in a previous buy step…`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          PARTIAL_SELL_CAP: {
            defaultValue: false,
            tooltip: `Set an upper limit to partial sell order quantity`,
            inputType: 'boolean',
          },
          PARTIAL_SELL_CAP_RATIO: {
            defaultValue: '1',
            tooltip: `Setting 1 as cap value means that a partial sell order may not exceed 1x 'trading limit' in value.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          MAX_BUY_COUNT: {
            defaultValue: '40',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in sell mode only. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          }
        },
      },
      'Customize grid': {
        description: 'Options to change grid step size',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
            inputType: 'select',
          },
          AUTO_STEP_SIZE: {
            defaultValue: true,
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility`,
            inputType: 'boolean',
          },
          MIN_STEP_PCT: {
            defaultValue: '0',
            tooltip: `Minimum percentage size for steps, to overrule automatic step size. \n\nSetting 1 means that a step is set to 1% of price, unless auto step size is already higher than 1%`,
            inputType: 'string',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          PCT_STEP_SIZE: {
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
          unit_cost: {
            defaultValue: true,
            tooltip: `Determines how the break even price is calculated. \n\nWhen enabled: break even represents the average price per unit of remaining units in balance \n\nWhen disabled: break even line represents the point at which the remaining units can be sold for the total trade sequence to break even.`,
            inputType: 'boolean',
          },
          DYNAMIC_EXIT_LOGIC: {
            defaultValue: false,
            inputType: 'boolean',
          },
          GAIN_PARTIAL: {
            defaultValue: '0.5',
            tooltip: `Minimum gain target for partial sell orders. \n\nWhen set to 0.5, only buy orders at least 0.5% (+ trading fees) below current price are considered when calculating partial sell volume`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          GAIN: {
            defaultValue: '1',
            tooltip: `Minimum gain percentage target above active unit cost / break even \n\nWhen set to 1, full sell is only allowed when hitting a sell step at least 1% above current unit cost / break even target.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
        },
      },
      'Customize trailing': {
        description: 'Customize trailing ranges',
        parameters: {
          PCT_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default buy trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct buy trailing range is enabled, setting 0.5 will lead to having buy trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
          PCT_SELL_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default sell trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_SELL_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct sell trailing range is enabled, setting 0.5 will lead to having sell trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      'Trend options': {
        description: 'Trend based order triggers and restrictions',
        parameters: {
          TREND_SYNC: {
            defaultValue: false,
            tooltip: `When enabled, new trades may only be opened when the trends on 4h and 1h are aligned. Does not apply to dca trades.\n\nThis can be very restrictive.`,
            inputType: 'boolean',
          },
          TREND_BASIC: {
            defaultValue: true,
            tooltip: `This option enables dynamic strategy behavior like:

- using an increased sell step and trailing range when the market seems good for it
- placing immediate buy orders (regardless of hitting a step) when price action is very positive
- using a higher partial sell ratio after an immediate buy
- skipping certain buy steps when price action is very negative

This option will usually lead to more trading volume and makes it a more complex to plan capital allocation.`,
            inputType: 'boolean',
          },
          TREND_PLUS: {
            defaultValue: true,
            tooltip: `This option enables dynamic strategy behavior like:

- placing immediate buy orders (regardless of hitting a step) when there seems to be a short term opportunity
- using higher partial sell ratio after an immediate buy

This option will usually lead to more trading volume and makes it a more complex to plan capital allocation.`,
            inputType: 'boolean',
          },
          TREND_ORDERS: {
            defaultValue: true,
            tooltip: `This option can be used to disallow orders triggered by trend basic and trend plus.`,
            inputType: 'boolean',
          },
          AUTO_TREND_ORDERS: {
            defaultValue: true,
            inputType: 'boolean',
          },
          TRAILING_MULTIPLIER: {
            defaultValue: '2',
            tooltip: `This multiplier is used when trend basic and/or trend plus are enabled, and the trend module detects a situation where increased targets make sense.

Setting trailing multiplier to 2, multiplies the trailing range by 2 in specific situations. 

This multiplier also applies to custom pct trailing ranges.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 10],
            rangeInput: [0.01, 100],
          },
          SELL_STEP_MULTIPLIER: {
            defaultValue: '1.2',

            tooltip: `This multiplier is used when trend basic and/or trend plus are enabled, and the trend module detects a situation where increased targets make sense.

Setting sell step multiplier to 1.2 makes the a sell step 1.2x times as big in specific situations.

This multiplier also applies to a custom step size.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 100],
          },
          TREND_TRAILING: {
            defaultValue: true,
            inputType: 'boolean',
          },
          TREND_TRAILING_MULTIPLIER: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 4],
            rangeInput: [0.1, 100],
          },
          TREND_TRAILING_BEARISH_MULTIPLIER: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 4],
            rangeInput: [0.1, 100],
          },
        },
      },
      'Trading modes': {
        description: 'Customize trading modes',
        parameters: {
          CUSTOM_TRADING_RANGE_MODE: {
            defaultValue: false,
            tooltip: `With this mode you can define the price zone in which you want the strategy to operate, and optionally set a stop target. 

With the settings like in the defaults, new trades may only be opened when price is between 8000 and 10000. DCA trades can be placed at any price. In case price drops below 7000 all funds get sold. Set stop target to 0 to effectively disable it.`,
            inputType: 'boolean',
          },
          TRADING_RANGE_LOW: {
            defaultValue: '8000',
            tooltip: `Lower limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_HIGH: {
            defaultValue: '10000',
            tooltip: `Upper limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_STOP: {
            defaultValue: '7000',
            tooltip: `Stop loss target of manual trading range. Triggers when latest candle open rate is below stop target.`,
            inputType: 'string',
          },
          TRADING_RANGE_DCA_STOP: {
            defaultValue: '7500',
            tooltip: `Lower limit for DCA orders. No additional DCA orders are placed below this price.`,
            inputType: 'string',
          },
          PULLBACK_MODE: {
            defaultValue: false,
            tooltip: `When enabled, a pair may only open new trades when a pullback happens. Mostly monitored on 4h charts, independent of the period you set in this strategy. \n\nThis can be very restrictive.`,
            inputType: 'boolean',
          },
          VOLATILITY_MODE: {
            defaultValue: false,
            tooltip: `When enabled, only bullish pairs are traded in a controlled volatility range: it will attempt to start trading relatively low and stop trading when price is about to peak. \n\nThis mode comes with a built in stop mechanism near the top to often allow for a clean exit with minimal loss on the last sell order. This stop mechanism is separated from the dynamic stop loss option, and cannot be disabled in this mode.\n\nThis can be very restrictive.`,
            inputType: 'boolean',
          },
          TA_MODE: {
            defaultValue: false,
            tooltip: `When enabled, only bullish pairs are traded after they hit one or more TA fractals.\n\nThis can be very restrictive.`,
            inputType: 'boolean',
          },
          DYNAMIC_SL: {
            defaultValue: false,
            tooltip: `This option is meant to be used together with volatility mode or TA mode and tries to provide a meaningful stop target. Can also be used with other, or no active modes. You cannot see the stop target before it hits.\n\nThis can lead to losses.`,
            inputType: 'boolean',
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          FOREVER_BAGS: {
            defaultValue: false,
            tooltip: `Used to 'ride trends up'. \n\nThe default strategy behavior is to exit a trade in one or a small number of sell orders above the break even price. \n\nUsing the 'forever bags' option the break even price is completely ignored. Buy count is not available when forever bags is enabled.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          ATR_PERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate Average True Range, which is used to calculate auto step size. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    stepgridscalp: {
      info: {
        description:
          'Highly configurable grid strategy using the IRIS-Trend algorithm to analyze, identify and process price action in real time, identify trend changes dynamically and take advantage of market cycles using a multi timeframe approach.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/spot-strategies/stepgridscalp/',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "stepgridscalp",
        sellMethod: "stepgridscalp",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
          
  The general format is: BASECOIN-QUOTECOIN
          
  All pairs with BTC as base currency are written like: BTC-ETH, BTC-DOGE, BTC-XRP
  With a BTC-XXX pair, the aim is realize growth in BTC balance.
          
  All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
  With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure investment limits',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '40',
            tooltip: `The base amount used for each regular buy order. This value is also used to calculate 'buy count'`,
            inputType: 'string',
          },
          TL_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the invested amount for each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 500],
          },
          ALWAYS_USE_TL_MULTIPLIER: {
            defaultValue: false,
            tooltip: `Use this to control if 'TL ratio' settings use an absolute amount as input, or if they use the multiplier trading limit amount.
            
When enabled: all TL ratio settings use TL multiplier for the base amount to apply the ratio on.

When disabled: each TL ratio setting works as 'absolute amount set as trading limit' * ratio.`,
            inputType: 'boolean',
          },
          MAX_BUY_COUNT: {
            defaultValue: '40',
            tooltip: `Limits maximum position size for all situations except during BTFD mode. \n\nWhen trading limit multiplier is used, the increased investment per buy order is factored into 'buy count' so each dca round is only counted once. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          }
        },
      },
      'Profit settings': {
        description: 'Configure how the strategy takes profit',
        parameters: {
          GAIN: {
            defaultValue: '1',
            tooltip: `Minimum gain percentage target above active unit cost / break even \n\nWhen set to 1, full sell is only allowed when hitting a sell step at least 1% above current unit cost / break even target.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          GAIN_PARTIAL: {
            defaultValue: '0.5',
            tooltip: `Minimum gain target for partial sell orders. \n\nWhen set to 0.5, only buy orders at least 0.5% (+ trading fees) below current price are considered when calculating partial sell volume`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          PARTIAL_SELL_RATIO: {
            defaultValue: '0.95',
            tooltip: `The strategy is aware of how much volume was bought below the current price, and how much remains to be sold profitably. If a sell step is reached where nothing can be sold profitably, the step is skipped. \n\nWhen placing a sell order below the overall break even rate, the sell volume is defined by partial sell ratio.\n\nIf for example 200 units were bought at rates lower than the current price, a partial sell of 0.4 will lead to a partial sell order of 80 units.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          PARTIAL_SELL_CAP: {
            defaultValue: false,
            tooltip: `Set an upper limit to partial sell order quantity per sell step. `,
            inputType: 'boolean',
          },
          PARTIAL_SELL_CAP_RATIO: {
            defaultValue: '1',
            tooltip: `Setting 1 as cap value means that a partial sell order may not exceed 1x 'trading limit' in value per step. \n\nIn case of sell trailing effectively skipping several steps upwards, sell volume is determined by number of steps reached at once * partial sell ratio, capped by partial sell cap ratio`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          unit_cost: {
            defaultValue: true,
            tooltip: `Determines how the break even price is calculated. \n\nWhen enabled: break even represents the average price per unit of remaining units in balance \n\nWhen disabled: break even line represents the point at which the remaining units can be sold for the total trade sequence to break even.`,
            inputType: 'boolean',
          },
          DYNAMIC_EXIT_LOGIC: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      'Period settings': {
        description: 'Configure trading periods',
        parameters: {
          PERIOD: {
            defaultValue: '5', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for short term market data. This is the main trading timeframe.`,
            inputType: 'select',
          },
          PERIOD_MEDIUM: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for medium term market data.`,
            inputType: 'select',
          },
          PERIOD_LONG: {
            defaultValue: '60', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for long term market data.`,
            inputType: 'select',
          },
        },
      },
      'Customize grid': {
        description: 'Control the size of grid steps',
        parameters: {
          AUTO_STEP_SIZE: {
            defaultValue: 'ATR',
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility. When set to none, the configured absolute or percentage step size is used instead.`,
            options: ['none', 'ATR', 'candleSize'],
            inputType: 'select',
          },
          MIN_STEP_PCT: {
            defaultValue: '0.3',
            tooltip: `Minimum percentage size for steps, to overrule automatic step size. \n\nSetting 0.3 means that the absolute minimum for auto step size is 0.3% of current price.`,
            inputType: 'string',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          PCT_STEP_SIZE: {
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
        },
      },
      'Customize trailing': {
        description: 'Adjust buy and sell trailing ranges',
        parameters: {
          PCT_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default buy trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct buy trailing range is enabled, setting 0.5 will lead to having buy trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
          PCT_SELL_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default sell trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_SELL_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct sell trailing range is enabled, setting 0.5 will lead to having sell trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      'IRIS-Trend  |  Price action': {
        description: 'Constrain grid trading to favorable market cycle phases',
        parameters: {
          TREND_SYNC: {
            label: 'Price action analysis',
            defaultValue: false,
            tooltip: `When enabled, trend data of the configured timeframes for short, medium and long term is used to follow price action to:
      - (dis)allow opening new trades 
      - switch between normal grid trailing and grid trailing with increased trailing ranges`,
            inputType: 'boolean',
          },
          PRICE_ACTION_TL_RATIO: {
            defaultValue: '1',
            tooltip: `Can be used to modify buy amounts when price action is very positive. \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          PRICE_ACTION_THRESHOLD: {
            defaultValue: '0',
            tooltip: `ATR percentage rank threshold used in the conditions to allow 'price action tl multiplier' and 'Price action analysis (during phase bullish reversal)'. Setting 0.5 means that the current medium period ATR percentage rank value must be at least 50% and the current candle is green.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [-1, 1],
            rangeInput: [-1, 1],
          },
          STRICT_ENTRY: {
            defaultValue: false,
            tooltip: `When enabled, more conservative criteria are used to (dis)allow opening new trades, mainly by not buying into overbought market conditions. This can lead to times of inactivity after a final sell, by design.`,
            inputType: 'boolean',
          },
          STRICT_DCA: {
            defaultValue: false,
            tooltip: `When enabled, grid dca trades are not allowed when the upper timeframe has bearish momentum`,
            inputType: 'boolean',
          },
          EXHAUSTION_SENSITIVITY: {
            defaultValue: 'MEDIUM',
            inputType: 'select',
            options: ['NONE', 'SHORT', 'MEDIUM', 'LONG'],
            tooltip: `This setting controls up to which timeframe exhaustion must pass before a next DCA buy is allowed.

        - NONE: disables this protection
        - SHORT: do not allow buy orders when short term is exhausted
        - MEDIUM: do not allow buy orders when short OR medium term is exhausted
        - LONG: do not allow buy orders when short OR medium OR long term is exhausted
        `,
          },
        },
      },
      'IRIS-Trend  |  Trade supports': {
        description: 'Target supports during phases unfavorable to grid trading',
        parameters: {
          TRADE_SUPPORTS: {
            defaultValue: true,
            tooltip: `When enabled, trend data of the configured timeframes for short, medium and long term is used to follow price action to:
  - a mode in which limit orders targets are placed at medium or long term support levels`,
            inputType: 'boolean',
          },
          SUPPORT_TL_RATIO: {
            defaultValue: '2',
            tooltip: `When trend sync is enabled and multiple timeframes move downwards, limit buy targets are placed at medium or high timeframe supports. \n\nThis setting determines the buy order value for S1 targets, it multiplies 'trailing limit' \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          SUPPORT2_TL_RATIO: {
            defaultValue: '2',
            tooltip: `When trend sync is enabled and multiple timeframes move downwards, limit buy targets are placed at medium or high timeframe supports. \n\nThis setting determines the buy order value for S2 targets, it multiplies 'trailing limit' \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
        },
      },
      'IRIS-Trend  |  Micro scalping': {
        description: 'More careful grid trading in relatively high zones',
        parameters: {
          TREND_SCALPING: {
            label: 'Micro scalping',
            defaultValue: true,
            tooltip: `When enabled, trend data using the configured timeframes for short, medium and long term is used to:
      - (dis)allow opening new trades 
      - switch between normal grid trailing micro scalping
      
      During micro scalping partial stop loss orders are possible.`,
            inputType: 'boolean',
          },
          SCALP_TL_RATIO: {
            defaultValue: '0.625',
            tooltip: `When trend scalping is enabled and a scalping zone is reached, buy and sell amounts are based on this ratio\n\nThis setting determines the buy order value for support targets, it multiplies 'trailing limit' \n\nSetting 0.5 places buy orders for 'trading limit' * 0.5`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          SCALP_INIT_BUY_MULTIPLIER: {
            label: 'Initial buy step mulitplier',
            defaultValue: '0.6',
            tooltip: `Can be used to modify the first buy step size, when not in a position and the strategy is in status 'scalping'. Values below 0 lead to opening a new trade quicker. \n\nThis multiplier is used like 'multiplier * step size', where step size can be an automatically generated value, or a defined manual step size.`,
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 2],
            rangeInput: [0.1, 99],
          },
          DYNAMIC_SL: {
            defaultValue: false,
            tooltip: `This is a stop loss option for small positions (up to 2x 'trading limit', rounded), accumulated in higher regions. 
      
      The goal is to accept a relatively small loss sometimes, before a trade gets hard to DCA effectively.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Multiple timeframes': {
        description: 'Increase trading frequency by using a second set of timeframes',
        parameters: {
          MULTIPLE_TIMEFRAMES_MODE: {
            defaultValue: false,
            tooltip: `When enabled, the strategy switches to a lower set of timeframes when it detects favorable conditions during a 'bullish reversal' phase, with the aim of reaching a higher trading frequency. When the lower set of timeframes all have exhausted market conditions, it switches back to the regular set of timeframes. `,
            inputType: 'boolean',
          },
          MTF_TL_RATIO: {
            defaultValue: '1',
            tooltip: `Trading limit ratio to use for buy trading using the lower set of timeframes\n\nThis setting determines the buy order value for support targets, it multiplies 'trailing limit' \n\nSetting 0.5 places buy orders for 'trading limit' * 0.5`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          separator_2: {},
          LOWER_PERIOD_LOW: {
            defaultValue: '5',
            tooltip: `Low period of lower set of timeframes.`,
            inputType: 'select',
          },
          LOWER_PERIOD_MEDIUM: {
            defaultValue: '15',
            tooltip: `Medium period of lower set of timeframes`,
            inputType: 'select',
          },
          LOWER_PERIOD_HIGH: {
            defaultValue: '30',
            tooltip: `High period of lower set of timeframes`,
            inputType: 'select',
          },
          separator_3: {},
          MIDDLE_PERIOD_LOW: {
            defaultValue: '15',
            tooltip: `Low period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
          MIDDLE_PERIOD_MEDIUM: {
            defaultValue: '60',
            tooltip: `Medium period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
          MIDDLE_PERIOD_HIGH: {
            defaultValue: '240',
            tooltip: `High period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
        },
      },
      'IRIS-Trend  |  Accumulation cycle': {
        description: 'Mode to accumulate without partial sell orders',
        parameters: {
          ACCUMULATION_CYCLE: {
            defaultValue: false,
            tooltip: `When enabled, no partial sells below break even are allowed, full sell only allowed when gain target is reached and the market is exhausted on the upper timeframe. New trades are only opened during the 'bullish reversal' phase. Micro scalping is disabled.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Advanced trailing': {
        description: 'Adjust buy and sell trailing multipliers',
        parameters: {
          TREND_PLUS: {
            label: 'Advanced trailing',
            defaultValue: true,
            tooltip: `When enabled, trend data using the configured timeframes for short, medium and long term is used to in- and decrease trailing ranges based on the currently detected market phase. This overrules a trailing modifier in 'strict price action'`,
            inputType: 'boolean',
          },
          separator: {},
          TREND_PLUS_BUY_MULTIPLIER_SMALL: {
            defaultValue: '1',
            tooltip: `Smallest buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_BUY_MULTIPLIER_MEDIUM: {
            defaultValue: '2',
            tooltip: `Medium buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_BUY_MULTIPLIER_LARGE: {
            defaultValue: '5',
            tooltip: `Largest buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          separator_2: {},
          TREND_PLUS_SELL_MULTIPLIER_SMALL: {
            defaultValue: '0.5',
            tooltip: `Smallest sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_SELL_MULTIPLIER_MEDIUM: {
            defaultValue: '2',
            tooltip: `Medium sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_SELL_MULTIPLIER_LARGE: {
            defaultValue: '5',
            tooltip: `Largest sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
        },
      },
      'IRIS-Trend  |  BTFD mode': {
        description: 'Buy the dip',
        parameters: {
          BTFD_MODE: {
            label: 'BTFD mode',
            defaultValue: false,
            tooltip: `When enabled, a configurable series of limit buy orders gets placed when price drops below a defined or automatic price target and there are no open orders at that time.`,
            inputType: 'boolean',
          },
          BTFD_TREND_FILTER: {
            label: 'Use trend filter',
            defaultValue: false,
            tooltip: `When enabled, the trend module only allows to BTFD when the market is confirmed very bearish, sell volume is leading on the upper timeframe and volatily is high. Can be used together with manual or automatic price targets.`,
            inputType: 'boolean',
          },
          BTFD_DIP_TARGET: {
            label: 'BTFD dip target',
            defaultValue: '0',
            tooltip: `Manual price target to start BTFD mode. As soon as price drops below this target and there are no open orders, a series of limit buy orders get placed. Normal grid buy operations stop below this price.`,
            inputType: 'string',
          },
          BTFD_MAX_DIP_TARGET: {
            label: 'BTFD max dip target',
            defaultValue: '0',
            tooltip: `Manual price target to stop BTFD mode. No limit buys get placed below this target. Example: dip target set to 1000, max dip target set to 900, means that BTFD may only buy between 1000 and 900.`,
            inputType: 'string',
          },
          BTFD_AUTO_TARGET: {
            label: 'BTFD use automatic targets',
            defaultValue: 'none',
            tooltip: `Enable to use automatic dip targets. Conditions to BTDF with auto targets: price drops below selected target and there are no open orders. When used together with the 'trade supports' option, BTFD would not activate if there are support orders open already. \n\nWhen combined with 'trade supports', it is strongly recommended to select long term S2 or a manually set target - to avoid triggering both support orders and BTFD orders at a similar time.`,
            options: ['none', 'mediumTermS1', 'mediumTermS2', 'longTermS1', 'longTermS2'],
            inputType: 'select',
          },
          BTFD_TL_RATIO: {
            label: 'BTFD TL ratio',
            defaultValue: '1',
            tooltip: `Can be used to modify buy amounts during BTFD. \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          BTFD_MAX_BUY_COUNT: {
            label: 'BTFD max buy count',
            defaultValue: '25',
            tooltip: `Limits maximum position size during BTFD mode. \n\nWhen trading limit multiplier is used, the increased investment per buy order is factored into 'buy count' so each dca round is only counted once. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          BTFD_MAX_ORDERS: {
            label: 'BTFD max dip orders',
            defaultValue: '5',
            tooltip: `Max number of buy orders to fire, each placed at one step distance from another, to place when BTFD mode starts. Intended to limit the number of orders per time that BTFD activates, not to cap position size itself. Takes max buy count and max dip target into account, and stops placing lower orders when either of those would get exceeded.`,
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 20],
            rangeInput: [0, 99],
          },
          BTFD_GAIN: {
            label: 'BTFD gain',
            defaultValue: '1',
            tooltip: `Defines at which percentage above last order rate the sell target gets placed during BTFD.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          BTFD_AUTO_STEP_SIZE: {
            label: 'BTFD auto step size',
            defaultValue: 'ATR',
            tooltip: `Lets the bot automatically determine a grid step size for BTFD that fits the pairs current volatility. When set to none, the configured absolute or percentage step size is used instead.`,
            options: ['none', 'ATR', 'candleSize'],
            inputType: 'select',
          },
          BTFD_STEP_SIZE: {
            label: 'BTFD step size',
            defaultValue: '500',
            tooltip: `Manually defined grid step size for BTFD buy orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          BTFD_PCT_STEP_SIZE: {
            label: 'BTFD pct step size',
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
        },
      },
      'Custom trading range': {
        description: 'Set boundaries to the traded price range',
        parameters: {
          CUSTOM_TRADING_RANGE_MODE: {
            defaultValue: false,
            tooltip: `With this mode you can define the price zone in which you want the strategy to operate, and optionally set a stop target. 
  
  With the settings like in the defaults, new trades may only be opened when price is between 8000 and 10000. DCA trades can be placed at any price. In case price drops below 7000 all funds get sold. Set stop target to 0 to effectively disable it.`,
            inputType: 'boolean',
          },
          TRADING_RANGE_LOW: {
            defaultValue: '8000',
            tooltip: `Lower limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_HIGH: {
            defaultValue: '10000',
            tooltip: `Upper limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_STOP: {
            defaultValue: '7000',
            tooltip: `Stop loss target of manual trading range.`,
            inputType: 'string',
          },
          TRADING_RANGE_DCA_STOP: {
            defaultValue: '7500',
            tooltip: `Lower limit for DCA orders. No additional DCA orders are placed below this price.`,
            inputType: 'string',
          },
          TRADING_RANGE_STOP_RATIO: {
            label: 'Stop loss ratio',
            defaultValue: '1',
            tooltip: `Can be used for partial stop losses that are triggered by DCA stop target. Regardless of input, minimum SL size is capped to 1x trading limit \n\nSetting 0.5 places stop for 'quote balance' * 0.5 \n\nNote that setting a value below 1 can lead to repeated stops. When the last order trigger is this kind of stop order, it won't trigger again until another strategy trigger happens after, like a grid buy order.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 1],
            rangeInput: [0.01, 1],
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          ATR_PERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate Average True Range, which is used to calculate auto step size and trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          FOREVER_BAGS: {
            defaultValue: false,
            tooltip: `Used to 'ride trends up'. \n\nThe default strategy behavior is to exit a trade in one or a small number of sell orders above the break even price. \n\nUsing the 'forever bags' option the break even price is completely ignored and the strategy places partial sell orders only. Buy count is not available when forever bags is enabled.`,
            inputType: 'boolean',
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    sgsnextgen: {
      info: {
        description:
          'VTA work in progress version',
        docsUrl: 'https://wiki.gunthy.org/unreleased/',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "sgsnextgen",
        sellMethod: "sgsnextgen",
        hardcode: {
          'DYNAMIC_EXIT_LOGIC': false,
          'unit_cost': true,
          'MAX_INVESTMENT': 99999999999999999,
          'STOP_LIMIT': 9999999999999999999
        },
        walletWhitelist: ['0xeda5498ae0db187ba2fe89f22c4a145278745303', '0x5d3f6bd63f43db5927d32fb5f98c3b59b79c88a7', '0x5d3F6BD63f43DB5927d32Fb5f98c3B59B79C88A7', '0x48FEf6B8C2D49551781aB0a4c9423D61A48326F0', '0x355e1602D223eAD302861CeF5AeAafa07e6dc3cA', '0x214d4aabdc27dcbcf51ce68b7adc3f1f4a926dab'],
        category: 'Grid Bots',
      },
      'General configuration': {
        isHeadline: true,
        parameters: {}
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
          
  The general format is: BASECOIN-QUOTECOIN
          
  All pairs with BTC as base currency are written like: BTC-ETH, BTC-DOGE, BTC-XRP
  With a BTC-XXX pair, the aim is realize growth in BTC balance.
          
  All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
  With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Profit settings': {
        description: 'Adjust how to take earnings',
        parameters: {
          GAIN: {
            defaultValue: '1',
            tooltip: `Minimum gain percentage target above active unit cost / break even \n\nWhen set to 1, full sell is only allowed when hitting a sell step at least 1% above current unit cost / break even target.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          GAIN_PARTIAL: {
            defaultValue: '0.2',
            tooltip: `Minimum gain target for partial sell orders. \n\nWhen set to 0.5, only buy orders at least 0.5% (+ trading fees) below current price are considered when calculating partial sell volume`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
        },
      },
      'Trailing settings': {
        description: 'Adjust buy and sell trailing ranges',
        parameters: {
          PCT_BUY_TRAILING_RANGE: {
            defaultValue: false,
            label: 'Pct buy trailing range',
            tooltip: `By default buy trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_BUY_TRAILING_RANGE: {
            defaultValue: '0.3',
            label: 'Custom buy trailing range',
            tooltip: `When pct buy trailing range is enabled, setting 0.5 will lead to having buy trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
          PCT_SELL_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default sell trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_SELL_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct sell trailing range is enabled, setting 0.5 will lead to having sell trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      'Price action algorithms': {
        isHeadline: true,
        parameters: {}
      },
      'IRIS-Trend  |  Dynamic trading capital': {
        description: 'Modify investment capital allocation, reserves, and compounding',
        parameters: {
          TRADING_CAPITAL: {
            label: 'Trading capital',
            defaultValue: '1000',
            tooltip: `Maximum allowed investment on this trading pair. Funds will get allocated automatically, based on your settings and trading zone.`,
            inputType: 'string',
          },

        },
      },
      'IRIS-Trend  |  Trading zone': {
        description: 'Set boundaries to the traded price range',
        parameters: {
          // add option to fetch automatic zone targets
          CONFIGURE_TRADING_ZONE: {
            label: 'Configure trading zone',
          },
          TRADING_ZONE_HIGH: {
            label: 'Trading zone high',
            defaultValue: '1000000',
            tooltip: `Upper limit of trading zone. No buy orders above this price. When approaching this target, 'stop after next sell' gets automatically enabled, this way no new position will be entered.`,
            inputType: 'string',
          },
          TRADING_ZONE_LOW: {
            label: 'Trading zone low',
            defaultValue: '0.00000001',
            tooltip: `Lower limit of trading zone. No buy orders below this price.`,
            inputType: 'string',
          },
          TRADING_ZONE_STOP: {
            label: 'Trading zone stop',
            defaultValue: '0',
            tooltip: `Stop loss target of manual trading range.`,
            inputType: 'string',
          },
        },
      },
      'IRIS-Trend  |  Multiple trend synergy': {
        description: 'Monitor trends across timeframes to detect opportunities',
        parameters: {
          PERIOD: {
            defaultValue: '5', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for short term market data. This is the main trading timeframe.`,
            inputType: 'select',
          },
          PERIOD_MEDIUM: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for medium term market data.`,
            inputType: 'select',
          },
          PERIOD_LONG: {
            defaultValue: '60', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for long term market data.`,
            inputType: 'select',
          }
        },
      },
      'IRIS-Trend  |  Price action analysis': {
        description: 'Analyzes price action states to spot opportunities',
        parameters: {
          PRICE_ACTION: {
            label: 'Use price action analysis',
            defaultValue: true,
            tooltip: `When activated, this algorithm performs advanced analysis of price action to identify favorable trend conditions for trading using a technical approach.`,
            inputType: 'boolean',
          },
          STRICT_PRICE_ACTION: {
            label: 'Strict price action',
            defaultValue: false,
            tooltip: `When enabled, more conservative criteria are used to (dis)allow opening new trades, mainly by not buying into overbought market conditions. This can lead to times of inactivity after a final sell, by design.`,
            inputType: 'boolean',
          },
          STRICT_MOMENTUM: {
            label: 'Strict momentum',
            defaultValue: false,
            tooltip: `When enabled, grid dca trades are not allowed when the upper timeframe has bearish momentum.`,
            inputType: 'boolean',
          },
          EXHAUSTION_SENSITIVITY: {
            defaultValue: 'SHORT',
            inputType: 'select',
            options: ['NONE', 'SHORT', 'MEDIUM', 'LONG'],
            tooltip: `This setting controls up to which timeframe exhaustion must pass before a next DCA buy is allowed.

        - NONE: disables this protection
        - SHORT: do not allow buy orders when short term is exhausted
        - MEDIUM: do not allow buy orders when short OR medium term is exhausted
        - LONG: do not allow buy orders when short OR medium OR long term is exhausted
        `,
          },
        },
      },
      'IRIS-Trend  |  Advanced trailing': {
        description: 'Price action tracking mechanism based on directional movement',
        parameters: {
          ADVANCED_TRAILING: {
            label: 'Use advanced trailing',
            defaultValue: true,
            tooltip: `When enabled, this algorithm uses the direction of the price action to apply an optimized multiplier to the basic trailing ranges used to buy/sell in each time frame.`,
            inputType: 'boolean',
          },
        },
      },
      'Trading algorithms': {
        isHeadline: true,
        parameters: {}
      },
      'IRIS-Trend  |  Trade grid': {
        description: 'Adjust the behavior of the trading grid',
        parameters: {
          GRID: {
            label: 'Use grid trading',
            defaultValue: true,
            tooltip: `When enabled, grid trading is the default behavior. When disabled, the strategy will never using a trading grid.`,
            inputType: 'boolean',
          },
          AUTO_STEP_SIZE: {
            defaultValue: 'optimized',
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility. When set to none, the configured absolute or percentage step size is used instead.`,
            options: ['none', 'ATR', 'candleSize', 'optimized'],
            inputType: 'select',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          PCT_STEP_SIZE: {
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Trade scalping': {
        description: 'Navigate bull markets and volatile trends with confidence',
        parameters: {
          TREND_SCALPING: {
            label: 'Use micro scalping',
            defaultValue: true,
            tooltip: `When enabled, this algorithm performs microscalping operations using various trading library patterns and a risk management strategy.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Trade supports': {
        description: 'Reliable mechanism to trade bear markets',
        parameters: {
          TRADE_SUPPORTS: {
            label: 'Use support trading',
            defaultValue: true,
            tooltip: `When enabled, this algorithm makes it possible to trade support and resistance using various trading patterns and an effective price averaging strategy.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Trade B.T.F.D': {
        description: 'Squeeze more out of volatile markets',
        parameters: {
          BTFD_MODE: {
            label: 'Use BTFD',
            defaultValue: true,
            tooltip: `When enabled, this algorithm allows trading in markets with high bearish volatility. Various techniques are used to ensure buying the dip during most price correcting scenarios.
            `,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Trade accumulation': {
        description: 'Use supply and demand zones to identify a trading plan',
        parameters: {
          ACCUMULATION_CYCLE: {
            defaultValue: false,
            label: 'Use accumulation mode',
            tooltip: `When enabled, this algorithm allows trading in bear markets using a accumulation approach, placing initial buy order in specific areas. When the market returns to bullish trend, the accumulated capital is sold. Several settings are automatically overruled in this mode: partial sell, scalping, B.T.F.D, strict momentum, gain target.`,
            inputType: 'boolean',
          },
        },
      },
      'Extras': {
        isHeadline: true,
        parameters: {}
      },
      'Advanced settings': {
        description: 'Adjust various advanced options',
        parameters: {
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10',
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    builder: {
      info: {
        description: 'Create your own strategy using readily available strategy options.',
        docsUrl: 'https://www.gunbot.com/support/docs/built-in-strategies/spot-strategies/builder/about-builder/',
        market: 'spot',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        category: 'Legacy',
        hardcode: {BUY_LEVEL: 0.1}
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Buy settings': {
        description: 'Primary buy trigger',
        parameters: {
          supportresistance: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SupRes_SPREAD: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 100],
            },
            SupRes_MAX: {
              defaultValue: '0',
              inputType: 'string',
            },
          },
          bb: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emotionless: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          gain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          ichimoku: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            KIJUN_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_BUY: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          pp: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_BUY: {
              defaultValue: '1000',
              inputType: 'string',
            },
          },
          tsa: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          tssl: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUY_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
          },
          stepgain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUYLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            BUYLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          adx: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          atrts: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emaspread: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macd: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          macdh: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          smacross: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
        },
      },
      'Sell settings': {
        description: 'Primary sell trigger',
        parameters: {
          bb: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emotionless: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          gain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          ichimoku: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_SELL: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KIJUN_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          pp: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_SELL: {
              defaultValue: '1000',
              inputType: 'string',
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tsa: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tssl: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELL_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          stepgain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SELLLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            SELLLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          adx: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          atrts: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            STOP_LIMIT: {
              defaultValue: '99',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          bbta: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emaspread: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macd: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macdh: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          smacross: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          supportresistance: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          TL_PERC: {
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 100],
            rangeInput: [0, 100],
          },
          TL_ALLIN: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999', 
            tooltip: `Limits total investment. Setting 1000 on USDT-BTC limits the maximum investment to 1000 USDT.`,
            inputType: 'string',
          },
          FUNDS_RESERVE: {
            defaultValue: '0',
            inputType: 'string',
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to prevent buy orders that are too small to buy. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
        },
      },
      Indicators: {
        description: 'Indicators used in selected buy and sell methods',
        parameters: {
          adx: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ADX_LEVEL: {
              defaultValue: '25',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 70],
              rangeInput: [1, 99],
            },
            DI_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          atrts: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ATRX: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            ATR_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          bb: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          bbta: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
          },
          supportresistance: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate support and resistance targets`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
          },
          emaspread: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMAx: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          emotionless: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          ichimoku: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            TENKAN_PERIOD: {
              defaultValue: '9',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            KIJUN_PERIOD: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            SENKOUSPAN_PERIOD: {
              defaultValue: '52',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            DISPLACEMENT: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          gain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macd: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macdh: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          pp: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets the chart candle timeframe. has no effect on primary buy or sell trigger with pp, only used for confirming indicators.`,
              inputType: 'select',
            },
          },
          smacross: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SLOW_SMA: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            FAST_SMA: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          tsa: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
          },
          tssl: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          stepgain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
        },
      },
      TrailMe: {
        description: 'Use additional price trailing',
        parameters: {
          TRAIL_ME_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_BUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator: true,
          TRAIL_ME_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator1: true,
          TRAIL_ME_RT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_RT_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          TM_RT_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_SELL_UP: {
            defaultValue: '0.3',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
        },
      },
      DCA: {
        description: 'Dollar cost averaging',
        parameters: {
          DOUBLE_UP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          DOUBLE_UP_CAP: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 100],
          },
          DU_CAP_COUNT: {
            defaultValue: '0',
            inputType: 'range',
            rangeLowHigh: [0, 10],
            rangeInput: [0, 999],
          },
          DU_BUYDOWN: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          DU_METHOD: {
            defaultValue: 'HIGHBB',
            options: ['HIGHBB', 'RSI'],
            inputType: 'select',
            freeSolo: true,
          },
          RSI_DU_BUY: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
        },
      },
      'Confirming indicators': {
        description: 'Additional indicator conditions',
        parameters: {
          ADX_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ADX_LEVEL: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          DI_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator: true,
          BTC_PND_PROTECTION: {
            defaultValue: false,
            inputType: 'boolean',
          },
          BTC_PND_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          BTC_MONEY_FLOW: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          separator1: true,
          EMASPREAD: {
            defaultValue: false,
            inputType: 'boolean',
          },
          EMAx: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 20],
          },
          separator2: true,
          MFI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MFI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator3: true,
          RSI_BUY_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_SELL_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          RSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator4: true,
          STOCH_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCH_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCH_K: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SLOW_STOCH_K: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          STOCH_D: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator5: true,
          STOCHRSI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCHRSI_BUY_LEVEL: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_SELL_LEVEL: {
            defaultValue: '0.8',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCHRSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator6: true,
          STDV: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.5, 3],
            rangeInput: [0.1, 30],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '100',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
        },
      },
      RT: {
        description: 'Reversal trading',
        parameters: {
          RT_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_GAIN: {
            defaultValue: '1.5',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_BUY_UP_LEVEL: {
            defaultValue: '0',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_ONCE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_ONCE_AND_CONTINUE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_MAXBAG_PROTECTION: {
            defaultValue: '10',
            inputType: 'range',
            rangeLowHigh: [0, 25],
            rangeInput: [0, 100],
          },
          RT_TREND_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      'Stop loss': {
        description: 'Handle losing trades',
        parameters: {
          STOP_LIMIT: {
            defaultValue: '99',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 5],
            rangeInput: [-100, 99999],
          },
          SL_DISABLE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      Misc: {
        description: 'Various other settings',
        parameters: {
          MARKET_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_STOP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTBUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTSELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_BUYBACK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_FOK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator: true,
          PANIC_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator1: true,
          TRADES_TIMEOUT: {
            defaultValue: '0',
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator2: true,
          NBA: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator3: true,
          TAKE_PROFIT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_PROFIT_ONLY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator4: true,
          TAKE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TBUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator5: true,
          LIQUIDITY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_TAKER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_GAIN: {
            defaultValue: true,
            inputType: 'boolean',
          },
          MAX_INVESTMENT: {
            defaultValue: '999999999999999',
            inputType: 'string',
          },
        },
      },
    },
    custom: {
      info: {
        description: 'Run custom JS strategy code. All order triggers are defined by your code.',
        docsUrl: 'https://docs.gunthy.org/docs/custom-strategies/what-are-custom-strategies',
        market: 'all',
        license: ['ultimate', 'promoultimate', 'br', 'mm'],
        buyMethod: "custom",
        sellMethod: "custom",
        category: 'Custom',
        hardcode: {
          'DYNAMIC_EXIT_LOGIC': false,
          'unit_cost': true,
          'MAX_INVESTMENT': 99999999999999999,
          'STOP_LIMIT': 9999999999999999999
        }
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Core settings': {
        description: 'Assign strategy and essential settings',
        parameters: {
          STRAT_FILENAME: {
            defaultValue: '', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select the strategy file to run. All .js files in the /customStrategies folder are listed.`,
            options: [],
            inputType: 'select',
          },
          IS_MARGIN_STRAT: {
            defaultValue: false,
            tooltip:
              'Only has an effect on the GUI. Disable to use the GUI in spot trading mode, enable to use in futures mode.',
            inputType: 'boolean',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip:
              'Only has an effect on the GUI in spot trading mode. This is the threshold for stats like ROE to calculate when there is an open position.',
            inputType: 'string',
          },
          unit_cost: {
            defaultValue: true,
            tooltip: `Determines how the spot break even is calculated. \n\nWhen enabled: break even represents the average price per unit of remaining units in balance \n\nWhen disabled: break even line represents the point at which the remaining units can be sold for the total trade sequence to break even.`,
            inputType: 'boolean',
          },
        },
      },
      Indicators: {
        description: 'Configure how indicators are calculated',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            inputType: 'select',
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 500],
          },
          separator0: true,
          ADX_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ADX_LEVEL: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          DI_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator: true,
          BTC_PND_PROTECTION: {
            defaultValue: false,
            inputType: 'boolean',
          },
          BTC_PND_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          BTC_MONEY_FLOW: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          separator1: true,
          EMASPREAD: {
            defaultValue: false,
            inputType: 'boolean',
          },
          EMAx: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 20],
          },
          EMA1: {
            defaultValue: '16',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA2: {
            defaultValue: '8',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA3: {
            defaultValue: '150',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '150',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 500],
          },
          separator2: true,
          MFI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MFI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator3: true,
          RSI_BUY_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_SELL_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          RSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator4: true,
          STOCH_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCH_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCH_K: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SLOW_STOCH_K: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          STOCH_D: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator5: true,
          STOCHRSI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCHRSI_BUY_LEVEL: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_SELL_LEVEL: {
            defaultValue: '0.8',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCHRSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator6: true,
          STDV: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.5, 3],
            rangeInput: [0.1, 30],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
        },
      },
    },
    futuresgrid: {
      info: {
        description:
          'DCA strategy with automatic buydown levels and price trailing. Invest per trade is static. Buy target increases with each buy order, does not sell below break even. ',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/futures-strategies/futuresgrid/',
        market: 'futures',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: ['bybit', 'okex5', 'okgunbot'],
        buyMethod: "futuresgrid",
        sellMethod: "futuresgrid",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MAX_BUY_COUNT: {
            defaultValue: '10',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in sell mode only. \n\nA value of 15 means that the total position size may not exceed 15x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 999],
          },
          MAX_OPEN_CONTRACTS: {
            label: 'Max open contracts (long)',
            tooltip: 'Sets the maximum position size of a futures long position. Expressed in quote units.\n\nIf executing a next order would exceed max open contracts, the order will not be fired.',
            defaultValue: '0',
            inputType: 'string',
          },
          MAX_OPEN_CONTRACTS_SHORT: {
            label: 'Max open contracts (short)',
            tooltip: 'Sets the maximum position size of a futures short position. Expressed in quote units.\n\nIf executing a next order would exceed max open contracts, the order will not be fired.',
            defaultValue: '0',
            inputType: 'string',
          },
          LEVERAGE: {
            defaultValue: '0',
            inputType: 'range',
            exchangeSpecific: ['bitmex', 'bitmex_testnet', 'futures_gunthy', 'binanceFutures'],
            rangeLowHigh: [0, 125],
            rangeInput: [0, 700],
          },
        },
      },
      'Customize targets': {
        description: 'Options to change trading targets',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          DIRECTION: {
            defaultValue: 'LONG', // only used as fallback, normal behavior is to use exchange specific values
            inputType: 'select',
            options: ['LONG', 'SHORT', 'AUTO'],
          },
          TREND_VARIANT: {
            defaultValue: 'legacy',
            options: ['legacy', '4h', '1h_15m', 'custom'],
            tooltip: `Trend source to use when direction is set to 'AUTO'. 
            
- Legacy mode uses the strategy timeframe, plus 1h and 4h data.
- 4h uses the same trend module as in stepgridhedge, using 4h data only.
- 1h_15m uses the same trend module as in stepgridhedge, using data from 1h and 15m charts.
`,
            inputType: 'select',
          },
          AUTO_GAIN: {
            defaultValue: true,
            tooltip: `Use a dynamic profit target. The dynamic target is based on the distance between support and resistance, capped at a minimum profit of 2x trading fees. \n\nThe target decreases when position size increases. Overrules a manually set target.`,
            inputType: 'boolean',
          },
          GAIN: {
            defaultValue: '0.5',
            tooltip: `Set a manual profit target. \n\nEach time price reaches this percentage above break even, a sell order gets placed.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          GRID_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the distance between buy orders. Default value 1 means that the hardcoded levels are used. \n\n1.5 would increase the levels with a factor 1.5x, a value of 0.5 would halve the default levels`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          TL_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the invested amount with each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 500],
          },
          MIN_BUYDOWN: {
            defaultValue: '0',
            tooltip: `Set a minimum distance between two orders that add to a position. Value represents a percentage.

This acts as an override to the built-in buydown levels that increase with each additional dca order.

When set to 0.6, this means that the minimum distance is 0.6%.
`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [0, 200],
          },
          KEEP_ONE_TL: {
            defaultValue: false,
            tooltip: `Instead of reopening a position shortly after closing one, with this setting enabled the bot will keep 1x trading limit open when (partially) closing a position.`,
            inputType: 'boolean',
          },
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing long orders. Closing longs is still possible. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing short orders. Closing shorts is still possible. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate buydown targets and trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          FIRST_ORDER_EXTRA_DELAY: {
            defaultValue: '30',
            tooltip: `Additional delay after opening a new trade. Can be used to prevent multiple orders in case the exchange does not update balances fast enough.`,
            inputType: 'range',
            rangeLowHigh: [5, 50],
            rangeInput: [1, 999999],
          },
        },
      },
    },
    tenkan: {
      info: {
        description: 'Opens a long when tenkan goes below kumo, opens a short position when tenkan crosses over kumo.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/futures-strategies/tenkan/',
        market: 'futures',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: ['bybit', 'okex5', 'okgunbot'],
        buyMethod: "tenkan",
        sellMethod: "tenkan",
        category: 'Legacy',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
    The general format is: BASECOIN-QUOTECOIN
            
    All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
    With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
    All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
    With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Basic settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MAX_OPEN_CONTRACTS: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
      'Customize targets': {
        description: 'Set targets to take profit',
        parameters: {
          ROE: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0, 50],
            rangeInput: [-100, 999],
          },
          ROE_LIMIT: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          DCA_SPREAD: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 20],
            rangeInput: [0.1, 999],
          },
        },
      },
      Indicators: {
        description: 'Configure relevant indicators',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          separator: true,
          TENKAN_PERIOD: {
            defaultValue: '9',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          KIJUN_PERIOD: {
            defaultValue: '26',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SENKOUSPAN_PERIOD: {
            defaultValue: '52',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          DISPLACEMENT: {
            defaultValue: '26',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator1: true,
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
        },
      },
      Advanced: {
        description: 'Various advanced settings',
        parameters: {
          MARKET_BUY: {
            defaultValue: true,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: true,
            inputType: 'boolean',
          },
          MARKET_CLOSE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MAKER_FEES: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ROE_SPREAD: {
            defaultValue: '0',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
        },
      },
    },
    'builder (futures)': {
      info: {
        description: 'Create your own strategy using readily available strategy options.',
        docsUrl: 'https://www.gunbot.com/support/docs/built-in-strategies/futures-strategies/builder/about-builder/',
        market: 'futures',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: ['bybit', 'okex5', 'okgunbot'],
        category: 'Legacy',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Long settings': {
        description: 'Primary long trigger',
        parameters: {
          bb: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LONG_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          gain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LONG_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          ichimoku: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          pp: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_BUY: {
              defaultValue: '1000',
              inputType: 'string',
            },
          },
          tsa: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          tssl: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LONG_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUY_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
          },
          adx: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          atrts: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emaspread: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macd: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macdh: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          smacross: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
        },
      },
      'Short settings': {
        description: 'Primary short trigger',
        parameters: {
          bb: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SHORT_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          gain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SHORT_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          ichimoku: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          pp: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_SELL: {
              defaultValue: '1000',
              inputType: 'string',
            },
          },
          tsa: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          tssl: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SHORT_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELL_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
          },
          adx: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          atrts: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emaspread: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macd: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macdh: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          smacross: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
        },
      },
      'Close position': {
        description: 'Setting options to take profit',
        parameters: {
          adx: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          atrts: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          bb: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          bbta: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          emaspread: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          ichimoku: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_CLOSE: {
              defaultValue: false,
              inputType: 'boolean',
            },
            KUMO_CLOSE: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_CLOSE: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KIJUN_CLOSE: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          gain: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          macd: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          macdh: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          pp: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          smacross: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          tsa: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
          tssl: {
            ROE: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 50],
              rangeInput: [-100, 999],
            },
            ROE_TRAILING: {
              defaultValue: false,
              inputType: 'boolean',
            },
            ROE_LIMIT: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
            ROE_SCALPER: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND: {
              defaultValue: false,
              inputType: 'boolean',
            },
            PND_PROTECTION: {
              defaultValue: '1.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0, 20],
              rangeInput: [-100, 999],
            },
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          TL_ALLIN: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '0.00000001', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum order value. Needed in case TL_ALLIN does not work. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair.`,
            inputType: 'string',
          },
          MAX_OPEN_CONTRACTS: {
            defaultValue: '99999999999999999',
            inputType: 'string',
          },
          LEVERAGE: {
            defaultValue: '0',
            inputType: 'range',
            exchangeSpecific: ['bitmex', 'bitmex_testnet', 'futures_gunthy', 'binanceFutures'],
            rangeLowHigh: [0, 125],
            rangeInput: [0, 700],
          },
        },
      },
      Indicators: {
        description: 'Indicators used in selected buy and sell methods',
        parameters: {
          adx: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ADX_LEVEL: {
              defaultValue: '25',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 70],
              rangeInput: [1, 99],
            },
            DI_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          atrts: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ATRX: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            ATR_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          bb: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MEAN_REVERSION: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
            MEAN_REVERSION: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          emaspread: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMAx: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          ichimoku: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            TENKAN_PERIOD: {
              defaultValue: '9',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            KIJUN_PERIOD: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            SENKOUSPAN_PERIOD: {
              defaultValue: '52',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            DISPLACEMENT: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            ICHIMOKU_PROTECTION: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          gain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MEAN_REVERSION: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          macd: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macdh: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          pp: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              inputType: 'select',
            },
            MEAN_REVERSION: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          smacross: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              inputType: 'select',
            },
            SLOW_SMA: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            FAST_SMA: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          tsa: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
          },
          tssl: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MEAN_REVERSION: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
        },
      },
      DCA: {
        description: 'Dollar cost averaging',
        parameters: {
          DCA_METHOD: {
            defaultValue: 'NONE',
            options: ['NONE', 'TENKAN'],
            inputType: 'select',
          },
          DCA_SPREAD: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 20],
            rangeInput: [0.1, 999],
          },
          MAX_OPEN_CONTRACTS: {
            defaultValue: '99999999999999999',
            inputType: 'string',
          },
        },
      },
      'Confirming indicators': {
        description: 'Additional indicator conditions',
        parameters: {
          ADX_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ADX_LEVEL: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          DI_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator: true,
          BTC_PND_PROTECTION: {
            defaultValue: false,
            inputType: 'boolean',
          },
          BTC_PND_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          BTC_MONEY_FLOW: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          separator1: true,
          EMASPREAD: {
            defaultValue: false,
            inputType: 'boolean',
          },
          EMAx: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 20],
          },
          separator2: true,
          MFI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MFI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator3: true,
          RSI_BUY_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_SELL_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          RSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator4: true,
          STOCH_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCH_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCH_K: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SLOW_STOCH_K: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          STOCH_D: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator5: true,
          STOCHRSI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCHRSI_BUY_LEVEL: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_SELL_LEVEL: {
            defaultValue: '0.8',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCHRSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator6: true,
          STDV: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.5, 3],
            rangeInput: [0.1, 30],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '100',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
        },
      },
      'Stop loss': {
        description: 'Handle losing trades',
        parameters: {
          adx: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          atrts: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          bb: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          bbta: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          emaspread: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          ichimoku: {
            KUMO_STOP: {
              defaultValue: true,
              inputType: 'boolean',
            },
            TENKAN_STOP: {
              defaultValue: false,
              inputType: 'boolean',
            },
            KIJUN_STOP: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          gain: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          macd: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          macdh: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          pp: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          smacross: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          tsa: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
          tssl: {
            STOP_LIMIT: {
              defaultValue: '999999',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 999999],
            },
            SL_DISABLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            SL_DISABLE_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            STOP_BUY: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
            STOP_SELL: {
              defaultValue: '0',
              inputType: 'range',
              step: 0.01,
              exchangeSpecific: ['bitmex', 'bitmex_testnet'],
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 99999],
            },
          },
        },
      },
      Misc: {
        description: 'Various other settings',
        parameters: {
          MARKET_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_CLOSE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_STOP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator: true,
          PANIC_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator1: true,
          TRADES_TIMEOUT: {
            defaultValue: '0',
            inputType: 'string',
          },
          SAFETY_TIMER: {
            defaultValue: '1800',
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator2: true,
          NBA: {
            defaultValue: '0',
            inputType: 'string',
          },
          ROE_SPREAD: {
            defaultValue: '0',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          separator3: true,
          PRE_ORDER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          PRE_ORDER_GAP: {
            defaultValue: '0',
            inputType: 'string',
          },
          MAKER_FEES: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
    },
    market_maker: {
      info: {
        description: 'Liquidity providing strategy for spot markets',
        market: 'spot',
        license: ['mm'],
        exchangeBlacklist: [],
        buyMethod: "market_maker",
        sellMethod: "market_maker",
        category: 'Legacy',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Regular trades': {
        description: 'Place orders up and down',
        parameters: {
          MAX_OPEN_CONTRACTS: {
            defaultValue: '5',
            inputType: 'string',
            tooltip: 'The maximum open orders on each side. Setting 5 leads to maximum 5 open buy orders and maximum 5 open sell orders, 10 in total.'
          },
          GAIN: {
            defaultValue: '0.1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 1],
            rangeInput: [0, 100],
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
          unit_cost: {
            defaultValue: true,
            inputType: 'boolean',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '10',
            inputType: 'string',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10',
            inputType: 'string',
          },
          INITIAL_CAPITAL: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
      DCA: {
        description: 'Dollar cost averaging',
        parameters: {
          MM_DCA: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MAX_DCA_ORDERS: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [0, 20],
            rangeInput: [0, 999],
          },
          DCA_SPREAD: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 2],
            rangeInput: [0, 100],
          },
          DCA_SPREAD_CAP: {
            defaultValue: '2',
            inputType: 'range',
            rangeLowHigh: [0, 20],
            rangeInput: [0, 999],
          },
        },
      },
      'Stop loss': {
        description: 'Handle losing trades',
        parameters: {
          STOP_LIMIT: {
            defaultValue: '999999',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 5],
            rangeInput: [-100, 999999],
          },
          SL_DISABLE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
    },
    'market_maker (futures)': {
      info: {
        description: 'Liquidity providing strategy for futures markets',
        market: 'futures',
        license: ['mm'],
        exchangeBlacklist: ['bybit', 'okex5', 'okgunbot'],
        exchangeWhitelist: [
          'bitmex',
          'bitmex_testnet',
          'binanceFutures',
          'dydx',
          'krakenFutures',
          'futures_gunthy',
          'ftx',
        ],
        buyMethod: "market_maker",
        sellMethod: "market_maker",
        category: 'Legacy',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Core settings': {
        description: 'Invest per order and order placement',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          MAX_BUY: {
            defaultValue: '5',
            inputType: 'range',
            rangeLowHigh: [0, 20],
            rangeInput: [0, 999],
          },
          MAX_SELL: {
            defaultValue: '5',
            inputType: 'range',
            rangeLowHigh: [0, 20],
            rangeInput: [0, 999],
          },
          separator1: true,
          CANCEL_SPREAD: {
            defaultValue: '5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 6],
            rangeInput: [-100, 100],
          },
          FIRST_ORDER_SPREAD: {
            defaultValue: '0.02',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [-100, 100],
          },
          MM_SPREAD: {
            defaultValue: '0.02',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [-100, 100],
          },
        },
      },
      'Close position': {
        description: 'Control how to take profit',
        parameters: {
          ROE: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0, 50],
            rangeInput: [-100, 999],
          },
          ROE_TRAILING: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ROE_LIMIT: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          ROE_SCALPER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ROE_SPREAD: {
            defaultValue: '0',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
          separator: true,
          PND: {
            defaultValue: false,
            inputType: 'boolean',
          },
          PND_PROTECTION: {
            defaultValue: '1.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0, 20],
            rangeInput: [-100, 999],
          },
        },
      },
      'Risk management': {
        description: 'Set limits to control risk',
        parameters: {
          MAX_OPEN_CONTRACTS: {
            defaultValue: '999999',
            inputType: 'string',
          },
          MAX_LOSS: {
            defaultValue: '999999',
            inputType: 'string',
          },
          REDUCING_RATIO: {
            defaultValue: '4',
            inputType: 'string',
          },
          INITIAL_CAPITAL: {
            defaultValue: '0.01',
            inputType: 'string',
          },
          CAPITAL_RISK: {
            defaultValue: '0.01',
            inputType: 'string',
          },
          INVESTED_PERC: {
            defaultValue: '0.01',
            inputType: 'string',
          },
          separator: true,
          SR_RATIO: {
            defaultValue: '1',
            inputType: 'string',
          },
          SR_INVESTED_PERC: {
            defaultValue: '0.01',
            inputType: 'string',
          },
          SR_SPREAD: {
            defaultValue: '0.05',
            inputType: 'string',
          },
          SR_LIMIT_LONG: {
            defaultValue: '1',
            inputType: 'string',
          },
          SR_LIMIT_SHORT: {
            defaultValue: '1',
            inputType: 'string',
          },
          separator2: true,
          STOP_LIMIT: {
            defaultValue: '999999',
            inputType: 'string',
          },
        },
      },
      DCA: {
        description: 'Additional DCA options',
        parameters: {
          DCA_METHOD: {
            defaultValue: 'NATIVE',
            options: ['NATIVE', 'TENKAN'],
            inputType: 'select',
          },
          DCA_SPREAD: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          separator: true,
          PULLBACK_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          PULLBACK_SIDE: {
            defaultValue: 'BOTH',
            options: ['LONG', 'SHORT', 'BOTH'],
            inputType: 'select',
          },
          PULLBACK_DCA_PERC: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          PULLBACK_DCA_MULTIPLIER: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 2],
            rangeInput: [0, 999],
          },
        },
      },
      'Strategy variants': {
        description: 'Use different entry or dca logic',
        parameters: {
          FIBONACCI: {
            defaultValue: false,
            inputType: 'boolean',
          },
          FIB_DCA_PERC: {
            defaultValue: '0.3',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          separator: true,
          SupportResistance: {
            defaultValue: false,
            inputType: 'boolean',
          },
          SupRes_ALLOW_DCA: {
            defaultValue: false,
            inputType: 'boolean',
          },
          SupRes_SPREAD: {
            defaultValue: '0.1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 100],
          },
          SupRes_LVL_SPREAD: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 100],
          },
          SupRes_MAX: {
            defaultValue: '0',
            inputType: 'string',
          },
          SupRes_TIMER: {
            defaultValue: '300',
            inputType: 'string',
          },
          SupResMinROE: {
            defaultValue: '20',
            inputType: 'string',
          },
          BUY_ENABLED: {
            defaultValue: true,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            inputType: 'boolean',
          },
          separator1: true,
          GRID: {
            defaultValue: false,
            inputType: 'boolean',
          },
          GRID_SPREAD: {
            defaultValue: '0.3',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          GRID_SIDE: {
            defaultValue: 'BOTH',
            options: ['LONG', 'SHORT', 'BOTH'],
            inputType: 'select',
          },
          GRID_MAX: {
            defaultValue: '0',
            inputType: 'string',
          },
          GRID_TIMER: {
            defaultValue: '300',
            inputType: 'string',
          },
          x125: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator2: true,
          MOTION_OF_THE_OCEAN: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MOTO_DCA_PERC: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          MOTO_SIDE: {
            defaultValue: 'BOTH',
            options: ['LONG', 'SHORT', 'BOTH'],
            inputType: 'select',
          },
          MOTO_DCA_MULTIPLIER: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 2],
            rangeInput: [0, 999],
          },
          MOTO_TIMER: {
            defaultValue: '900',
            inputType: 'string',
          },
          separator3: true,
          PULLBACK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          PULLBACK_DCA_PERC: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 5],
            rangeInput: [-100, 999],
          },
          PULLBACK_SIDE: {
            defaultValue: 'BOTH',
            options: ['LONG', 'SHORT', 'BOTH'],
            inputType: 'select',
          },
          PULLBACK_DCA_MULTIPLIER: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0, 2],
            rangeInput: [0, 999],
          },
          separator4: true,
          oneSCALPER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          oneSCALPER_DCA: {
            defaultValue: false,
            inputType: 'boolean',
          },
          oneSCALPER_TIMER: {
            defaultValue: '900',
            inputType: 'string',
          },
          separator5: true,
          EWO: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator6: true,
          futuresGrid: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      Indicators: {
        description: 'Relevant indicator settings',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
            inputType: 'select',
          },
          EMA1: {
            defaultValue: '16',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA2: {
            defaultValue: '8',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA3: {
            defaultValue: '150',
            inputType: 'range',
            rangeLowHigh: [2, 150],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '150',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 500],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 500],
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 500],
          },
        },
      },
      Misc: {
        description: 'Various additional settings',
        parameters: {
          MARKET_CLOSE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator: true,
          SAFETY_TIMER: {
            defaultValue: '900',
            inputType: 'string',
          },
          HEDGE_MODE: {
            defaultValue: 'BOTH',
            options: ['LONG', 'SHORT', 'BOTH'],
            inputType: 'select',
          },
          FLIP: {
            defaultValue: true,
            inputType: 'boolean',
          },
          LEVERAGE: {
            defaultValue: '0',
            inputType: 'range',
            exchangeSpecific: ['bitmex', 'bitmex_testnet', 'binanceFutures', 'futures_gunthy'],
            rangeLowHigh: [0, 125],
            rangeInput: [0, 700],
          },
          separator1: true,
          PRE_ORDER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          PRE_ORDER_GAP: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    stepgridhedge: {
      info: {
        description:
          'Grid trading in hedge mode. Similar to stepGrid, but on two sides and without partial closing trades below break even.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/futures-strategies/stepgridhedge/',
        market: 'futures',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeWhitelist: ['bybit', 'okex5', 'okgunbot', 'bitget'],
        buyMethod: "stepgridhedge",
        sellMethod: "stepgridhedge",
        category: 'Grid Bots',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Enter the long pair name. For example USDT-ETH-LONG.

This is the pair the strategy runs on and where settings can be controlled. Strategy fires orders on both long and short side.`,
            inputType: 'string',
          },
          PAIR_NAME_SHORT: {
            label: 'Pair name (short)',
            defaultValue: 'USDT-BTC',
            tooltip: `Enter the short pair name for the same market as the long side. For example USDT-ETH-SHORT.
            
This pair will get added with read only strategy settings. Used to collect data and display orders on the trading chart.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The minimum amount to invest for each order.\n\nBybit: represents notional value \n\nOKX / okGunbot: represents contracts`,
            inputType: 'string',
          },
          TRADING_LIMIT_SHORT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The minimum amount to invest for each short order.\n\nBybit: represents notional value \n\nOKX / okGunbot: represents contracts`,
            inputType: 'string',
          },
          MAX_BUY_COUNT: {
            defaultValue: '75',
            tooltip: `Limits maximum position size. \n\nWhen max is reached, the strategy goes in close only mode. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'`,
            inputType: 'range',
            rangeLowHigh: [0, 99],
            rangeInput: [0, 999],
          },
          MAX_UNHEDGED: {
            defaultValue: '75',
            tooltip: `Limits maximum position size difference from the other side, applies to orders that add to a position. \n\nWhen max is reached, the strategy goes in close only mode. \n\nExample: max unhedged set to 40 and is reached, long is at 50x tl position, short at 10x tl. Long can now not grow further until the short grows too.`,
            inputType: 'range',
            rangeLowHigh: [0, 99],
            rangeInput: [0, 999],
          },
          FIRST_TRADES_MULTIPLIER: {
            defaultValue: 'auto',
            tooltip: `Multiplies the order size for the first x trades on the long side. 

Set 'auto' to use default behavior. Auto mode generates multiplier values between 1.5 and 5, mostly depending on the imbalance between both sides.
            
With a manually set value, you use 2 as multiplier value, it means that eligible orders get fired for tl * 2. `,
            inputType: 'string',
          },
          FIRST_TRADES_THRESHOLD: {
            defaultValue: 'auto',
            tooltip: `Sets a position size threshold under which first trades multiplier applies.

Set 'auto' to use default behavior. In auto mode it will usually allow 2 to 3 trades with first trades multiplier, and continues with 1x tl trades after that. 
            
When you set 10 as value, first trades multiplier applies until the current position is tl * 10. `,
            inputType: 'string',
          },
          FIRST_TRADES_MULTIPLIER_SHORT: {
            defaultValue: 'auto',
            tooltip: `Multiplies the order size for the first x trades on the short side. 

Set 'auto' to use default behavior. Auto mode generates multiplier values between 1.5 and 5, mostly depending on the imbalance between both sides.
            
With a manually set value, you use 2 as multiplier value, it means that eligible orders get fired for tl * 2. `,
            inputType: 'string',
          },
          FIRST_TRADES_THRESHOLD_SHORT: {
            defaultValue: 'auto',
            tooltip: `Sets a position size threshold under which first trades multiplier applies.

Set 'auto' to use default behavior. In auto mode it will usually allow 2 to 3 trades with first trades multiplier, and continues with 1x tl trades after that. 
            
When you set 10 as value, first trades multiplier applies until the current position is tl * 10. `,
            inputType: 'string',
          },
        },
      },
      'Customize grid': {
        description: 'Options to change grid step size',
        parameters: {
          PERIOD: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.`,
            inputType: 'select',
          },
          USE_TREND: {
            defaultValue: false,
            tooltip: `Gives the strategy the option to pick a side and manipulate trading limit, based on trend data gathered on multi timeframe charts.
            
When it detects a phase where it trades long only:
- tl for longs are multiplied by 2
- shorts may only close, not dca

When it detects a phase where it trades short only:
- tl for shorts are multiplied by 2
- longs may only close, not dca

When it detects an undecided zone: 
- both long and short use regular tl
- both sides may dca and close

During stronger trends, expect this mechanism to pause dca on the losing side for longer times.

Strategy behavior is fundamentally different with or without this option.`,
            inputType: 'boolean',
          },
          TREND_VARIANT: {
            defaultValue: '4h',
            options: ['4h', '1h_15m', 'custom'],
            tooltip: `Sets the data source to use for determining market direction.

Epected behavior in 4h mode:
When it detects a phase where it trades long only:
- tl for longs are multiplied by 2
- shorts may only close, not dca

When it detects a phase where it trades short only:
- tl for shorts are multiplied by 2
- longs may only close, not dca

When it detects an undecided zone: 
- both long and short use regular tl
- both sides may dca and close  

Epected behavior in 1h_15m mode:
When it detects a phase where it trades long only:
- tl for longs are multiplied by 2
- shorts may only close, not dca

When it detects a phase where it trades short only:
- tl for shorts are multiplied by 2
- longs may only close, not dca

When it detects an undecided zone: 
- both sides may only close, not dca


Expected behavior in custom mode:
- you should set the direction as an override called 'CUSTOM_DIRECTION', it then trades in the same way as the '4h' mode would do
- value of 1 puts the bot in long mode
- value 0 puts the bot in both directions mode
- value of -1 puts the bot in short mode
`,
            inputType: 'select',
          },
          AUTO_STEP_SIZE: {
            defaultValue: true,
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility.`,
            inputType: 'boolean',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.`,
            inputType: 'string',
          },
          PCT_STEP_SIZE: {
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
          separator_121312: {},
          MIN_STEP_UP_PCT: {
            defaultValue: '0',
            tooltip: `Minimum percentage size for steps up, to overrule automatic step size. \n\nSetting 1 means that a step up is set to 1% of price, unless auto step size is already higher than 1%`,
            inputType: 'string',
          },
          MIN_STEP_DOWN_PCT: {
            defaultValue: '0',
            tooltip: `Minimum percentage size for steps down, to overrule automatic step size. \n\nSetting 1 means that a step down is set to 1% of price, unless auto step size is already higher than 1%`,
            inputType: 'string',
          },
          MIN_STEP_ASYMMETRIC: {
            defaultValue: true,
            tooltip: `When enabled, min step size (either up or down) is only applied in the dca direction of the currently smallest position. \n\nExample: if short position is smaller than long position, then 'min step up pct' is applied to steps up, while 'min step down pct' is not applied to steps down`,
            inputType: 'boolean',
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          STOP_AFTER_CLOSE_LONG: {
            defaultValue: false,
            tooltip: `Used to stop trading longs after the current long has been completely closed.`,
            inputType: 'boolean',
          },
          STOP_AFTER_CLOSE_SHORT: {
            defaultValue: false,
            tooltip: `Used to stop trading shorts after the current short has been completely closed.`,
            inputType: 'boolean',
          },
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing long orders.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing short orders.`,
            inputType: 'boolean',
          },
          CLOSE_LONG_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow (partially) closing long positions.`,
            inputType: 'boolean',
          },
          CLOSE_SHORT_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow (partially) closing short positions.`,
            inputType: 'boolean',
          },
          PANIC_CLOSE: {
            defaultValue: false,
            tooltip: `When enabled, every open long or close position will get immediate closed at market price.`,
            inputType: 'boolean',
          },
          SMAPERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate support and resistance levels, which are used to calculate trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          ATR_PERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate Average True Range, which is used to calculate auto step size. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          BUYDOWN_EXPONENT: {
            defaultValue: '0.5',
            tooltip: `Exponent to use in the built-in exponential buydown function that forces a distance between dca trades and avg entry price. 
            
Use https://jsfiddle.net/4b8s3hkf/ to experiment what different values result in. When in doubt, use default!`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.4, 1.5],
            rangeInput: [0, 100],
          },
          INSTANT_OPEN_THRESHOLD_LONG: {
            defaultValue: '99999',
            tooltip: `Minimum long position size, to use always in position trading on short side
            
Behavior of 'always in position' trading is to immediately start trailing for a new position after completely closing one.

When set to 50, the short side starts always in position trading when long position is more than 50x TL in size

Cannot be used together with enforce step size`,
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 100],
            rangeInput: [0, 100000000000],
          },
          INSTANT_OPEN_THRESHOLD_SHORT: {
            defaultValue: '99999',
            tooltip: `Minimum short position size, to use always in position trading on long side
            
Behavior of 'always in position' trading is to immediately start trailing for a new position after completely closing one.

When set to 50, the long side starts always in position trading when short position is more than 50x TL in size

Cannot be used together with enforce step size`,
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 100],
            rangeInput: [0, 100000000000],
          },
          STATIC_CLOSE_QTY: {
            label: 'Static close qty',
            defaultValue: false,
            tooltip: `When enabled, every close order uses the same order quantity: 1x trading limit`,
            inputType: 'boolean',
          },
        },
      },
    },
    sgsfutures: {
      info: {
        description:
          'Highly configurable grid strategy using the IRIS-Trend algorithm to analyze, identify and process price action in real time, identify trend changes dynamically and take advantage of market cycles using a multi timeframe approach.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/futures-strategies/sgsfutures/',
        market: 'futures',
        license: ['standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: ['bybit', 'okex5', 'okgunbot'],
        buyMethod: "sgsfutures",
        sellMethod: "sgsfutures",
        //walletWhitelist: ['0xeda5498ae0db187ba2fe89f22c4a145278745303', '0x5d3f6bd63f43db5927d32fb5f98c3b59b79c88a7', '0x5d3F6BD63f43DB5927d32Fb5f98c3B59B79C88A7', '0x48FEf6B8C2D49551781aB0a4c9423D61A48326F0'],
        category: 'Legacy',
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
          
  The general format is: BASECOIN-QUOTECOIN
          
  All pairs with BTC as base currency are written like: BTC-ETH, BTC-DOGE, BTC-XRP
  With a BTC-XXX pair, the aim is realize growth in BTC balance.
          
  All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
  With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Balance settings': {
        description: 'Configure investment limits',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '40',
            tooltip: `The base amount used for each regular buy order. This value is also used to calculate 'buy count'. \n\nSet value in contracts on coin-m markets.`,
            inputType: 'string',
          },
          TL_MULTIPLIER: {
            defaultValue: '1',
            tooltip: `Can be used to increase or decrease the invested amount for each consecutive buy order. \n\nExamples with trading limit 100 usdt and max buy count 3:\n\n1: each buy order is for 100 usdt \n\n1.5: first buy 100, second buy 150, third buy 225\n\n2: first buy 100, second buy 200, third buy 400`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [1, 5],
            rangeInput: [0.01, 500],
          },
          ALWAYS_USE_TL_MULTIPLIER: {
            defaultValue: false,
            tooltip: `Use this to control if 'TL ratio' settings use an absolute amount as input, or if they use the multiplier trading limit amount.
            
  When enabled: all TL ratio settings use TL multiplier for the base amount to apply the ratio on.
  
  When disabled: each TL ratio setting works as 'absolute amount set as trading limit' * ratio.`,
            inputType: 'boolean',
          },
          MAX_BUY_COUNT: {
            defaultValue: '20',
            tooltip: `Limits maximum position size, applies to all order triggers except BTFD mode. \n\nWhen trading limit multiplier is used, the increased investment per buy order is factored into 'buy count' so each dca round is only counted once. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          MAX_OPEN_CONTRACTS: {
            defaultValue: '999',
            inputType: 'string',
            tooltip:
              'Sets the maximum position size of a futures position. Expressed in quote units. If executing a next order would exceed max open contracts, the order will not be fired.',
          },
          MAX_LOSS: {
            defaultValue: '999999999999999',
            tooltip: `Max acceptable loss. When unrealized loss exceeds max loss, then the strategy will close the full position at loss. \n\nExample: max loss set to 100, stop loss happens when unrealized pnl exceeds -100.`,
            inputType: 'string',
          },
        },
      },
      'Profit settings': {
        description: 'Configure how the strategy takes profit',
        parameters: {
          GAIN: {
            defaultValue: '2',
            tooltip: `Minimum gain percentage for full close \n\nWhen set to 1, full sell is only allowed when hitting a sell step at least 1% 'above' average entry price`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          GAIN_PARTIAL: {
            defaultValue: '0.3',
            tooltip: `Minimum gain target for partial close orders. \n\nWhen set to 0.5, partial sell orders happen when hitting a target line between at least 0.5% above average entry price and gain target for full close`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 9999],
          },
          PARTIAL_SELL_RATIO: {
            defaultValue: '0.3',
            tooltip: `When set to 0.3, each partial close order uses 0.3 * position size`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          PARTIAL_SELL_CAP: {
            defaultValue: true,
            tooltip: `Set an upper limit to partial close order quantity. `,
            inputType: 'boolean',
          },
          PARTIAL_SELL_CAP_RATIO: {
            defaultValue: '2',
            tooltip: `Setting 2 as cap value means that a partial close order may not exceed 2x 'trading limit'`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
        },
      },
      'Period settings': {
        description: 'Configure trading periods',
        parameters: {
          PERIOD: {
            defaultValue: '5', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for short term market data. This is the main trading timeframe.`,
            inputType: 'select',
          },
          PERIOD_MEDIUM: {
            defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for medium term market data.`,
            inputType: 'select',
          },
          PERIOD_LONG: {
            defaultValue: '60', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Sets which timeframe to use for long term market data.`,
            inputType: 'select',
          },
        },
      },
      'Customize grid': {
        description: 'Control the size of grid steps',
        parameters: {
          AUTO_STEP_SIZE: {
            defaultValue: 'candleSize',
            tooltip: `Lets the bot automatically determine a grid step size that fits the pairs current volatility. When set to none, the configured absolute or percentage step size is used instead.`,
            options: ['none', 'ATR', 'candleSize'],
            inputType: 'select',
          },
          MIN_STEP_PCT: {
            defaultValue: '0.3',
            tooltip: `Minimum percentage size for steps, to overrule automatic step size. \n\nSetting 0.3 means that the absolute minimum for auto step size is 0.3% of current price.`,
            inputType: 'string',
          },
          STEP_SIZE: {
            defaultValue: '500',
            tooltip: `Manually defined grid step size for buy and sell orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          PCT_STEP_SIZE: {
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
          ENFORCE_STEP: {
            defaultValue: false,
            tooltip: `Used to ensure step size is respected. \n\nPrice trailing can sometimes finish at a rate worse than the current grid step up or down. \n\nWith this option enabled, orders may only get fired when trailing finishes at a better rate than the current step target. This can be useful for very precise balance management.`,
            inputType: 'boolean',
          },
          DIRECTION: {
            defaultValue: 'LONG',
            inputType: 'select',
            options: ['LONG'],
            tooltip: `This setting controls the direction the strategy trades
  
        - LONG: Trade only longs
        `,
          },
          MARKET_ORDERS: {
            label: 'Use market orders',
            defaultValue: true,
            tooltip: `Disable for dYdX, usually enable for other exchanges. \n\n When enabled, grid orders are placed as market orders. When disabled, grid orders are placed as limit order when price trailing finishes (note that these are not post only orders, they are meant to fill immediately).`,
            inputType: 'boolean',
          },
        },
      },
      'Customize trailing': {
        description: 'Adjust buy and sell trailing ranges',
        parameters: {
          PCT_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default buy trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct buy trailing range is enabled, setting 0.5 will lead to having buy trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
          PCT_SELL_TRAILING_RANGE: {
            defaultValue: false,
            tooltip: `By default sell trailing ranges are set automatically based on the current distance between support and resistance.\n\nWith this option you can set a percentage as custom trailing range to be used for buy and sell trailing. `,
            inputType: 'boolean',
          },
          CUSTOM_SELL_TRAILING_RANGE: {
            defaultValue: '0.3',
            tooltip: `When pct sell trailing range is enabled, setting 0.5 will lead to having sell trailing ranges of 0.5% of current price.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 50],
          },
        },
      },
      'IRIS-Trend  |  Price action': {
        description: 'Constrain grid trading to favorable market cycle phases',
        parameters: {
          TREND_SYNC: {
            label: 'Price action analysis',
            defaultValue: false,
            tooltip: `When enabled, trend data of the configured timeframes for short, medium and long term is used to follow price action to:
      - (dis)allow opening new trades 
      - switch between normal grid trailing and grid trailing with increased trailing ranges`,
            inputType: 'boolean',
          },
          PRICE_ACTION_TL_RATIO: {
            defaultValue: '1',
            tooltip: `Can be used to modify buy amounts when price action is very positive. \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          PRICE_ACTION_THRESHOLD: {
            defaultValue: '0',
            tooltip: `ATR percentage rank threshold used in the conditions to allow 'price action tl multiplier' and 'Price action analysis (during phase bullish reversal)'. Setting 0.5 means that the current medium period ATR percentage rank value must be at least 50% and the current candle is green.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [-1, 1],
            rangeInput: [-1, 1],
          },
          STRICT_ENTRY: {
            defaultValue: false,
            tooltip: `When enabled, more conservative criteria are used to (dis)allow opening new trades, mainly by not buying into overbought market conditions. This can lead to times of inactivity after a final sell, by design.`,
            inputType: 'boolean',
          },
          STRICT_DCA: {
            defaultValue: false,
            tooltip: `When enabled, grid dca trades are not allowed when the upper timeframe has bearish momentum`,
            inputType: 'boolean',
          },
          EXHAUSTION_SENSITIVITY: {
            defaultValue: 'MEDIUM',
            inputType: 'select',
            options: ['NONE', 'SHORT', 'MEDIUM', 'LONG'],
            tooltip: `This setting controls up to which timeframe exhaustion must pass before a next DCA buy is allowed.
  
        - NONE: disables this protection
        - SHORT: do not allow buy orders when short term is exhausted
        - MEDIUM: do not allow buy orders when short OR medium term is exhausted
        - LONG: do not allow buy orders when short OR medium OR long term is exhausted
        `,
          },
        },
      },
      'IRIS-Trend  |  Trade supports': {
        description: 'Target supports during phases unfavorable to grid trading',
        parameters: {
          TRADE_SUPPORTS: {
            defaultValue: true,
            tooltip: `When enabled, trend data of the configured timeframes for short, medium and long term is used to follow price action to:
  - a mode in which limit orders targets are placed at medium or long term support levels`,
            inputType: 'boolean',
          },
          SUPPORT_TL_RATIO: {
            defaultValue: '2',
            tooltip: `When trend sync is enabled and multiple timeframes move downwards, limit buy targets are placed at medium or high timeframe supports. \n\nThis setting determines the buy order value for S1 targets, it multiplies 'trailing limit' \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          SUPPORT2_TL_RATIO: {
            defaultValue: '2',
            tooltip: `When trend sync is enabled and multiple timeframes move downwards, limit buy targets are placed at medium or high timeframe supports. \n\nThis setting determines the buy order value for S2 targets, it multiplies 'trailing limit' \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
        },
      },
      'IRIS-Trend  |  Micro scalping': {
        description: 'More careful grid trading in relatively high zones',
        parameters: {
          TREND_SCALPING: {
            label: 'Micro scalping',
            defaultValue: true,
            tooltip: `When enabled, trend data using the configured timeframes for short, medium and long term is used to:
      - (dis)allow opening new trades 
      - switch between normal grid trading and micro scalping
      
      During micro scalping partial stop loss orders are possible.`,
            inputType: 'boolean',
          },
          SCALP_TL_RATIO: {
            defaultValue: '0.625',
            tooltip: `When trend scalping is enabled and a scalping zone is reached, buy and sell amounts are based on this ratio\n\nThis setting determines the buy order value for support targets, it multiplies 'trailing limit' \n\nSetting 0.5 places buy orders for 'trading limit' * 0.5`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          SCALP_INIT_BUY_MULTIPLIER: {
            label: 'Initial buy step multiplier',
            defaultValue: '0.6',
            tooltip: `Can be used to modify the first buy step size, when not in a position and the strategy is in status 'scalping'. Loweer values lead to opening a new trade quicker. \n\nThis multiplier is used like 'multiplier * step size', where step size can be an automatically generated value, or a defined manual step size.`,
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 2],
            rangeInput: [0.1, 99],
          },
          DYNAMIC_SL: {
            defaultValue: false,
            tooltip: `This is a stop loss option for small positions (up to 2x 'trading limit', rounded), accumulated in higher regions. 
      
      The goal is to accept a relatively small loss sometimes, before a trade gets hard to DCA effectively.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Multiple timeframes': {
        description: 'Increase trading frequency by using a second set of timeframes',
        parameters: {
          MULTIPLE_TIMEFRAMES_MODE: {
            defaultValue: false,
            tooltip: `When enabled, the strategy switches to a lower set of timeframes when it detects favorable conditions during a 'bullish reversal' phase, with the aim of reaching a higher trading frequency. When the lower set of timeframes all have exhausted market conditions, it switches back to the regular set of timeframes. `,
            inputType: 'boolean',
          },
          MTF_TL_RATIO: {
            defaultValue: '1',
            tooltip: `Trading limit ratio to use for buy trading using the lower set of timeframes\n\nThis setting determines the buy order value for support targets, it multiplies 'trailing limit' \n\nSetting 0.5 places buy orders for 'trading limit' * 0.5`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          separator_2: {},
          LOWER_PERIOD_LOW: {
            defaultValue: '5',
            tooltip: `Low period of lower set of timeframes.`,
            inputType: 'select',
          },
          LOWER_PERIOD_MEDIUM: {
            defaultValue: '15',
            tooltip: `Medium period of lower set of timeframes`,
            inputType: 'select',
          },
          LOWER_PERIOD_HIGH: {
            defaultValue: '30',
            tooltip: `High period of lower set of timeframes`,
            inputType: 'select',
          },
          separator_3: {},
          MIDDLE_PERIOD_LOW: {
            defaultValue: '15',
            tooltip: `Low period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
          MIDDLE_PERIOD_MEDIUM: {
            defaultValue: '60',
            tooltip: `Medium period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
          MIDDLE_PERIOD_HIGH: {
            defaultValue: '240',
            tooltip: `High period of regular set of timeframes. Should normally be set identically to the period settings in the indicator section.`,
            inputType: 'select',
          },
        },
      },
      'IRIS-Trend  |  Accumulation cycle': {
        description: 'Mode to accumulate without partial sell orders',
        parameters: {
          ACCUMULATION_CYCLE: {
            defaultValue: false,
            tooltip: `When enabled, no partial sells 'below' avg entry are allowed, full sell only allowed when gain target is reached and the market is exhausted on the upper timeframe. New trades are only opened during the 'bullish reversal' phase. Micro scalping is disabled.`,
            inputType: 'boolean',
          },
        },
      },
      'IRIS-Trend  |  Advanced trailing': {
        description: 'Adjust buy and sell trailing multipliers',
        parameters: {
          TREND_PLUS: {
            label: 'Advanced trailing',
            defaultValue: true,
            tooltip: `When enabled, trend data using the configured timeframes for short, medium and long term is used to in- and decrease trailing ranges based on the currently detected market phase. This overrules a trailing modifier in 'strict price action'`,
            inputType: 'boolean',
          },
          separator: {},
          TREND_PLUS_BUY_MULTIPLIER_SMALL: {
            defaultValue: '1',
            tooltip: `Smallest buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_BUY_MULTIPLIER_MEDIUM: {
            defaultValue: '2',
            tooltip: `Medium buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_BUY_MULTIPLIER_LARGE: {
            defaultValue: '5',
            tooltip: `Largest buy trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          separator_2: {},
          TREND_PLUS_SELL_MULTIPLIER_SMALL: {
            defaultValue: '0.5',
            tooltip: `Smallest sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_SELL_MULTIPLIER_MEDIUM: {
            defaultValue: '2',
            tooltip: `Medium sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
          TREND_PLUS_SELL_MULTIPLIER_LARGE: {
            defaultValue: '5',
            tooltip: `Largest sell trailing range multiplier. \n\nMultiplies the currently active trailing range: auto or custom pct. 1x auto trailing range equals 0.2x ATR.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 5],
            rangeInput: [0.01, 999],
          },
        },
      },
      'IRIS-Trend  |  BTFD mode': {
        description: 'Buy the dip',
        parameters: {
          BTFD_MODE: {
            label: 'BTFD mode',
            defaultValue: false,
            tooltip: `When enabled, a configurable series of limit buy orders gets placed when price drops below a defined or automatic price target and there are no open orders at that time.`,
            inputType: 'boolean',
          },
          BTFD_TREND_FILTER: {
            label: 'BTFD use trend filter',
            defaultValue: false,
            tooltip: `When enabled, the trend module only allows to BTFD when the market is confirmed very bearish, sell volume is leading on the upper timeframe and volatily is high. Can be used together with manual or automatic price targets.`,
            inputType: 'boolean',
          },
          BTFD_DIP_TARGET: {
            label: 'BTFD dip target',
            defaultValue: '0',
            tooltip: `Manual price target to start BTFD mode. As soon as price drops below this target and there are no open orders, a series of limit buy orders get placed. Normal grid buy operations stop below this price.`,
            inputType: 'string',
          },
          BTFD_MAX_DIP_TARGET: {
            label: 'BTFD max dip target',
            defaultValue: '0',
            tooltip: `Manual price target to stop BTFD mode. No limit buys get placed below this target. Example: dip target set to 1000, max dip target set to 900, means that BTFD may only buy between 1000 and 900.`,
            inputType: 'string',
          },
          BTFD_AUTO_TARGET: {
            label: 'BTFD use automatic targets',
            defaultValue: 'none',
            tooltip: `Enable to use automatic dip targets. Conditions to BTDF with auto targets: price drops below selected target and there are no open orders. When used together with the 'trade supports' option, BTFD would not activate if there are support orders open already. \n\nWhen combined with 'trade supports', it is strongly recommended to select long term S2 or a manually set target.`,
            options: ['none', 'mediumTermS1', 'mediumTermS2', 'longTermS1', 'longTermS2'],
            inputType: 'select',
          },
          BTFD_TL_RATIO: {
            label: 'BTFD TL ratio',
            defaultValue: '1',
            tooltip: `Can be used to modify buy amounts during BTFD. \n\nSetting 2 places buy orders for 'trading limit' * 2`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          BTFD_MAX_BUY_COUNT: {
            label: 'BTFD max buy count',
            defaultValue: '25',
            tooltip: `Limits maximum position size during BTFD mode. \n\nWhen trading limit multiplier is used, the increased investment per buy order is factored into 'buy count' so each dca round is only counted once. \n\nA value of 40 means that the total position size may not exceed 40x the amount set as 'trading limit'\n\nWhen max is reached, the strategy goes in sell mode only`,
            inputType: 'range',
            rangeLowHigh: [0, 80],
            rangeInput: [0, 500],
          },
          BTFD_MAX_ORDERS: {
            label: 'BTFD max dip orders',
            defaultValue: '5',
            tooltip: `Max number of buy orders to fire, each placed at one step distance from another, to place when BTFD mode starts. Intended to limit the number of orders per time that BTFD activates, not to cap position size itself. Takes max buy count and max dip target into account, and stops placing lower orders when either of those would get exceeded.`,
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 20],
            rangeInput: [0, 99],
          },
          BTFD_GAIN: {
            label: 'BTFD gain',
            defaultValue: '1',
            tooltip: `Percentage above avg entry price, to place the sell target after a BTFD order.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 2],
            rangeInput: [0.01, 999],
          },
          BTFD_AUTO_STEP_SIZE: {
            label: 'BTFD auto step size',
            defaultValue: 'ATR',
            tooltip: `Lets the bot automatically determine a grid step size for BTFD that fits the pairs current volatility. When set to none, the configured absolute or percentage step size is used instead.`,
            options: ['none', 'ATR', 'candleSize'],
            inputType: 'select',
          },
          BTFD_STEP_SIZE: {
            label: 'BTFD step size',
            defaultValue: '500',
            tooltip: `Manually defined grid step size for BTFD buy orders, when auto step size is not enabled. \n\nSet as a price: setting 500 on USDT-BTC makes the bot trade each time price moves 500 USDT.\n\nWhen using Pct step size, the value represents a percentage:  setting 2 will lead to having steps of 2% of current price.`,
            inputType: 'string',
          },
          BTFD_PCT_STEP_SIZE: {
            label: 'BTFD pct step size',
            defaultValue: false,
            tooltip: `When enabled, the value for step size represents a percentage.`,
            inputType: 'boolean',
          },
        },
      },
      'Custom trading range': {
        description: 'Set boundaries to the traded price range',
        parameters: {
          CUSTOM_TRADING_RANGE_MODE: {
            defaultValue: false,
            tooltip: `With this mode you can define the price zone in which you want the strategy to operate, and optionally set a stop target. 
  
  With the settings like in the defaults, new trades may only be opened when price is between 8000 and 10000. DCA trades can be placed at any price. In case price drops below 7000 all funds get sold. Set stop target to 0 to effectively disable it.`,
            inputType: 'boolean',
          },
          TRADING_RANGE_LOW: {
            defaultValue: '8000',
            tooltip: `Lower limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_HIGH: {
            defaultValue: '10000',
            tooltip: `Upper limit of manual trading range. Applies to opening new trades.`,
            inputType: 'string',
          },
          TRADING_RANGE_STOP: {
            defaultValue: '7000',
            tooltip: `Stop loss target of manual trading range.`,
            inputType: 'string',
          },
          TRADING_RANGE_DCA_STOP: {
            defaultValue: '7500',
            tooltip: `Lower limit for DCA orders. No additional DCA orders are placed below this price.`,
            inputType: 'string',
          },
          TRADING_RANGE_STOP_RATIO: {
            label: 'Stop loss ratio',
            defaultValue: '1',
            tooltip: `Can be used for partial stop losses that are triggered by DCA stop target. Regardless of input, minimum SL size is capped to 1x trading limit \n\nSetting 0.5 places stop for 'quote balance' * 0.5 \n\nNote that setting a value below 1 can lead to repeated stops. When the last order trigger is this kind of stop order, it won't trigger again until another strategy trigger happens after, like a grid buy order.`,
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 1],
            rangeInput: [0.01, 1],
          },
        },
      },
      Advanced: {
        description: 'Various advanced options',
        parameters: {
          BUY_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing buy orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          SELL_ENABLED: {
            defaultValue: true,
            tooltip: `Allow or disallow placing sell orders. \n\nStrategy performs better when trades are always allowed.`,
            inputType: 'boolean',
          },
          STOP_AFTER_SELL: {
            defaultValue: false,
            tooltip: `Used to stop trading after the current holding has been completely sold. \n\nWhen enabled, no new buy trades are placed at times where there is no balance to sell anymore.`,
            inputType: 'boolean',
          },
          ATR_PERIOD: {
            defaultValue: '50',
            tooltip: `Number of candles used to calculate Average True Range, which is used to calculate auto step size and trailing ranges. \n\nDefault value of 50 should usually not need tuning.`,
            inputType: 'range',
            rangeLowHigh: [5, 80],
            rangeInput: [1, 500],
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
    },
    webhooks_trading_terminal: {
      info: {
        description:
          'Create an alert based strategy and get the required alert messages to use on TradingView or another alerting platform.',
        docsUrl: 'https://docs.gunthy.org/docs/trading-alerts/webhook-alerts',
        market: 'spot',
        license: ['standardtv', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "gain",
        sellMethod: "gain",
        isCustom: true,
        showMethods: false,
        category: 'Alerts',
        hardcode: {
          "ADX_ENABLED": false,
          "ADX_LEVEL": 25,
          "ATRX": 0.5,
          "ATR_PERIOD": 14,
          "BTC_MONEY_FLOW": 35,
          "BTC_PND_PERIOD": 14,
          "BTC_PND_PROTECTION": false,
          "BUYLVL": 1,
          "BUYLVL1": 0.6,
          "BUYLVL2": 2,
          "BUYLVL3": 70,
          "BUY_ENABLED": false,
          "SINGLE_BUY": false,
          "BUY_LEVEL": 99,
          "BUY_METHOD": "gain",
          "BUY_RANGE": 0.5,
          "CANDLES_LENGTH": 500,
          "COUNT_SELL": 999999,
          "DISPLACEMENT": 26,
          "DI_PERIOD": 14,
          "DOUBLE_CHECK_GAIN": false,
          "DOUBLE_UP": false,
          "DOUBLE_UP_CAP": 1,
          "DU_BUYDOWN": 2,
          "DU_CAP_COUNT": 0,
          "DU_METHOD": "HIGHBB",
          "EMA1": 16,
          "EMA2": 8,
          "EMA3": 150,
          "EMASPREAD": false,
          "EMA_LENGTH": 50,
          "EMAx": 0.5,
          "FAST_SMA": 1,
          "FUNDS_RESERVE": 0,
          "GAIN": -999,
          "HIGH_BB": 0,
          "ICHIMOKU_PROTECTION": true,
          "IGNORE_TRADES_BEFORE": 0,
          "LIQUIDITY": false,
          "LIQUIDITY_TAKER": false,
          "LIQUIDITY_GAIN": false,
          "MAX_INVESTMENT": 999999999999,
          "IS_MARGIN_STRAT": false,
          "KEEP_QUOTE": 0,
          "KIJUN_BUY": false,
          "KIJUN_CLOSE": false,
          "KIJUN_PERIOD": 26,
          "KIJUN_SELL": false,
          "KIJUN_STOP": false,
          "KUMO_BUY": false,
          "KUMO_CLOSE": false,
          "KUMO_SELL": false,
          "KUMO_SENTIMENTS": true,
          "KUMO_STOP": true,
          "LEVERAGE": 0,
          "LONG_LEVEL": 100,
          "LOW_BB": 0,
          "MACD_LONG": 20,
          "MACD_SHORT": 5,
          "MACD_SIGNAL": 10,
          "MAKER_FEES": false,
          "TAKER_FEES": false,
          "MARKET_BUY": false,
          "MARKET_BUYBACK": false,
          "MARKET_CLOSE": false,
          "MARKET_DU": false,
          "MARKET_FOK": false,
          "MARKET_RTBUY": false,
          "MARKET_RTSELL": false,
          "MARKET_SELL": false,
          "MARKET_STOP": false,
          "MEAN_REVERSION": false,
          "MFI_BUY_LEVEL": 30,
          "MFI_ENABLED": false,
          "MFI_LENGTH": 14,
          "MFI_SELL_LEVEL": 70,
          "MIN_VOLUME_TO_BUY": 0.001,
          "MIN_VOLUME_TO_SELL": 0.001,
          "NBA": 0,
          "PANIC_SELL": false,
          "PERIOD": 15,
          "PP_BUY": 0,
          "PP_SELL": 99999,
          "PRE_ORDER": false,
          "PRE_ORDER_GAP": 0,
          "RENKO_ATR": false,
          "RENKO_BRICK_SIZE": 0.0001,
          "RENKO_PERIOD": 15,
          "ROE": 1,
          "ROE_CLOSE": false,
          "ROE_LIMIT": 1,
          "ROE_TRAILING": false,
          "ROE_SCALPER": false,
          "ROE_SPREAD": 0,
          "RSI_BUY_ENABLED": false,
          "RSI_BUY_LEVEL": 30,
          "RSI_DU_BUY": 30,
          "RSI_LENGTH": 14,
          "RSI_METHOD": "oscillator",
          "RSI_SELL_ENABLED": false,
          "RSI_SELL_LEVEL": 70,
          "RT_BUY_LEVEL": 2,
          "RT_BUY_UP_LEVEL": 0,
          "RT_ENABLED": false,
          "RT_GAIN": 1.5,
          "RT_MAXBAG_PROTECTION": 10,
          "RT_ONCE": false,
          "RT_ONCE_AND_CONTINUE": false,
          "RT_SELL_UP": 0.3,
          "RT_TREND_ENABLED": false,
          "SELLLVL": 1,
          "SELLLVL1": 0.6,
          "SELLLVL2": 2,
          "SELLLVL3": 70,
          "SELL_ENABLED": false,
          "SELL_METHOD": "gain",
          "SELL_RANGE": 0.5,
          "SENKOUSPAN_PERIOD": 52,
          "SHORT_LEVEL": 100,
          "SLOW_SMA": 2,
          "SLOW_STOCH_K": 3,
          "SL_DISABLE_BUY": false,
          "SL_DISABLE_SELL": false,
          "SMAPERIOD": 50,
          "STDV": 2,
          "STOCHRSI_BUY_LEVEL": 0.2,
          "STOCHRSI_ENABLED": false,
          "STOCHRSI_LENGTH": 14,
          "STOCHRSI_METHOD": "oscillator",
          "STOCHRSI_SELL_LEVEL": 0.8,
          "STOCH_BUY_LEVEL": 20,
          "STOCH_D": 3,
          "STOCH_ENABLED": false,
          "STOCH_K": 14,
          "STOCH_METHOD": "oscillator",
          "STOCH_SELL_LEVEL": 80,
          "STOP_LIMIT": 99999,
          "TAKE_BUY": false,
          "TAKE_PROFIT": false,
          "TBUY_RANGE": 0.5,
          "TENKAN_BUY": true,
          "TENKAN_CLOSE": true,
          "TENKAN_PERIOD": 9,
          "TENKAN_SELL": true,
          "TENKAN_STOP": false,
          "TL_ALLIN": false,
          "TL_PERC": 0,
          "TM_RT_SELL": false,
          "TP_PROFIT_ONLY": true,
          "TP_RANGE": 0.5,
          "TRADES_TIMEOUT": 0,
          "TRADING_LIMIT": 0.002,
          "TRAIL_ME_BUY": false,
          "TRAIL_ME_BUY_RANGE": 0.5,
          "TRAIL_ME_DU": false,
          "TRAIL_ME_RT": false,
          "TRAIL_ME_RT_SELL_RANGE": 0.5,
          "TRAIL_ME_SELL": false,
          "TRAIL_ME_SELL_RANGE": 0.5,
          "TSSL_TARGET_ONLY": true,
          "USE_RENKO": false,
          "XTREND_ENABLED": true,
          "STOP_BUY": 9999,
          "STOP_SELL": 9999,
          "PND": false,
          "PND_PROTECTION": 1.5,
          "SupportResistance": false,
          "SupRes_ALLOW_DCA": true,
          "SupRes_SPREAD": 0.1,
          "SupRes_LVL_SPREAD": 1,
          "SupRes_MAX": 0,
          "SupRes_TIMER": 300,
          "SupResMinROE": 20,
          "MAX_BUY_COUNT": 20,
          "GRID_MULTIPLIER": 1,
          "STOP_AFTER_SELL": false,
          "AUTO_GAIN": true,
          "TRAILING_MULTIPLIER": 1,
          "START_CONT_TRADING": 3,
          "CT_TL_MULTIPLIER": 0.5,
          "CT_RESTART_MULTIPLIER": 1,
          "TL_MULTIPLIER": 1,
          "MAX_OPEN_CONTRACTS": 1,
          "DCA_METHOD": "NATIVE",
          "DCA_SPREAD": 2,
          "SAFETY_TIMER": 1800,
          "TREND_OPEN": false,
          "TREND_BLOCK_DCA": false,
          "TREND_LOWER_DCA": false,
          "DIRECTION": "LONG",
          "TREND_CT_MULTIPLIER": 2,
          "TREND_GRID_MULTIPLIER": 2,
          "AUTO_STEP_SIZE": true,
          "STEP_SIZE": 500,
          "ENFORCE_STEP": false,
          "STRAT_FILENAME": "filename.js",
          "unit_cost": true,
          "DYNAMIC_EXIT_LOGIC": false,
          "bitRage": false,
          "FIRST_ORDER_EXTRA_DELAY": 30,
          "TREND_SCALPING": true,
          "SCALP_TL_RATIO": 0.625,
          "EXHAUSTION_SENSITIVITY": "SHORT",
          "STRICT_ENTRY": true,
          "PERIOD_MEDIUM": 60,
          "PERIOD_LONG": 240,
          "TRADE_SUPPORTS": true,
          "SUPPORT2_TL_RATIO": 2,
          "TREND_SYNC": true,
          "MULTIPLE_TIMEFRAMES_MODE": false,
          "LOWER_PERIOD_LOW": 5,
          "LOWER_PERIOD_MEDIUM": 15,
          "LOWER_PERIOD_HIGH": 30,
          "MIDDLE_PERIOD_LOW": 15,
          "MIDDLE_PERIOD_MEDIUM": 60,
          "MIDDLE_PERIOD_HIGH": 240,
          "ACCUMULATION_CYCLE": false,
          "MTF_TL_RATIO": 1,
          "PRICE_ACTION_TL_RATIO": 1,
          "PRICE_ACTION_THRESHOLD": 0,
          "ALWAYS_USE_TL_MULTIPLIER": false,
          "PANIC_CLOSE": false
        }
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Buy alerts': {
        description: 'Configure alerts to place buy orders',
        parameters: {
          WH_INDICATORS_LONG_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a buy order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_LONG: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_LONG_AMOUNT: {
            tooltip: 'Amount per order',
            label: 'Amount per order',
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
      'Sell alerts': {
        description: 'Configure alerts to place sell orders',
        parameters: {
          WH_INDICATORS_SHORT_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a sell order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_SHORT: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_SHORT_AMOUNT: {
            tooltip: 'Amount per order',
            label: 'Amount per order',
            defaultValue: 'full',
            inputType: 'string',
          },
        },
      },
    },
    'webhooks_trading_terminal (futures)': {
      info: {
        description:
          'Create an alert based strategy and get the required alert messages to use on TradingView or another alerting platform.',
        docsUrl: 'https://docs.gunthy.org/docs/trading-alerts/webhook-alerts',
        market: 'futures',
        license: ['standardtv', 'protv', 'ultimate', 'promoultimate', 'br', 'mm','monthlyStandard','yearlyStandard','monthlyPro','yearlyPro','monthlyUltimate','yearlyUltimate'],
        exchangeBlacklist: [],
        buyMethod: "gain",
        sellMethod: "gain",
        isCustom: true,
        showMethods: false,
        category: 'Alerts',
        hardcode: {
          "ADX_ENABLED": false,
          "ADX_LEVEL": 25,
          "ATRX": 0.5,
          "ATR_PERIOD": 14,
          "BTC_MONEY_FLOW": 35,
          "BTC_PND_PERIOD": 14,
          "BTC_PND_PROTECTION": false,
          "BUYLVL": 1,
          "BUYLVL1": 0.6,
          "BUYLVL2": 2,
          "BUYLVL3": 70,
          "BUY_ENABLED": false,
          "SINGLE_BUY": false,
          "BUY_LEVEL": 99,
          "BUY_METHOD": "gain",
          "BUY_RANGE": 0.5,
          "CANDLES_LENGTH": 500,
          "COUNT_SELL": 999999,
          "DISPLACEMENT": 26,
          "DI_PERIOD": 14,
          "DOUBLE_CHECK_GAIN": false,
          "DOUBLE_UP": false,
          "DOUBLE_UP_CAP": 1,
          "DU_BUYDOWN": 2,
          "DU_CAP_COUNT": 0,
          "DU_METHOD": "HIGHBB",
          "EMA1": 16,
          "EMA2": 8,
          "EMA3": 150,
          "EMASPREAD": false,
          "EMA_LENGTH": 50,
          "EMAx": 0.5,
          "FAST_SMA": 1,
          "FUNDS_RESERVE": 0,
          "GAIN": -999,
          "HIGH_BB": 0,
          "ICHIMOKU_PROTECTION": true,
          "IGNORE_TRADES_BEFORE": 0,
          "LIQUIDITY": false,
          "LIQUIDITY_TAKER": false,
          "LIQUIDITY_GAIN": false,
          "MAX_INVESTMENT": 999999999999,
          "IS_MARGIN_STRAT": true,
          "KEEP_QUOTE": 0,
          "KIJUN_BUY": false,
          "KIJUN_CLOSE": false,
          "KIJUN_PERIOD": 26,
          "KIJUN_SELL": false,
          "KIJUN_STOP": false,
          "KUMO_BUY": false,
          "KUMO_CLOSE": false,
          "KUMO_SELL": false,
          "KUMO_SENTIMENTS": true,
          "KUMO_STOP": true,
          "LEVERAGE": 0,
          "LONG_LEVEL": 100,
          "LOW_BB": 0,
          "MACD_LONG": 20,
          "MACD_SHORT": 5,
          "MACD_SIGNAL": 10,
          "MAKER_FEES": false,
          "TAKER_FEES": false,
          "MARKET_BUY": false,
          "MARKET_BUYBACK": false,
          "MARKET_CLOSE": false,
          "MARKET_DU": false,
          "MARKET_FOK": false,
          "MARKET_RTBUY": false,
          "MARKET_RTSELL": false,
          "MARKET_SELL": false,
          "MARKET_STOP": false,
          "MEAN_REVERSION": false,
          "MFI_BUY_LEVEL": 30,
          "MFI_ENABLED": false,
          "MFI_LENGTH": 14,
          "MFI_SELL_LEVEL": 70,
          "MIN_VOLUME_TO_BUY": 0.001,
          "MIN_VOLUME_TO_SELL": 0.001,
          "NBA": 0,
          "PANIC_SELL": false,
          "PERIOD": 15,
          "PP_BUY": 0,
          "PP_SELL": 99999,
          "PRE_ORDER": false,
          "PRE_ORDER_GAP": 0,
          "RENKO_ATR": false,
          "RENKO_BRICK_SIZE": 0.0001,
          "RENKO_PERIOD": 15,
          "ROE": 9999,
          "ROE_CLOSE": false,
          "ROE_LIMIT": 1,
          "ROE_TRAILING": false,
          "ROE_SCALPER": false,
          "ROE_SPREAD": 0,
          "RSI_BUY_ENABLED": false,
          "RSI_BUY_LEVEL": 30,
          "RSI_DU_BUY": 30,
          "RSI_LENGTH": 14,
          "RSI_METHOD": "oscillator",
          "RSI_SELL_ENABLED": false,
          "RSI_SELL_LEVEL": 70,
          "RT_BUY_LEVEL": 2,
          "RT_BUY_UP_LEVEL": 0,
          "RT_ENABLED": false,
          "RT_GAIN": 1.5,
          "RT_MAXBAG_PROTECTION": 10,
          "RT_ONCE": false,
          "RT_ONCE_AND_CONTINUE": false,
          "RT_SELL_UP": 0.3,
          "RT_TREND_ENABLED": false,
          "SELLLVL": 1,
          "SELLLVL1": 0.6,
          "SELLLVL2": 2,
          "SELLLVL3": 70,
          "SELL_ENABLED": false,
          "SELL_METHOD": "gain",
          "SELL_RANGE": 0.5,
          "SENKOUSPAN_PERIOD": 52,
          "SHORT_LEVEL": 100,
          "SLOW_SMA": 2,
          "SLOW_STOCH_K": 3,
          "SL_DISABLE_BUY": false,
          "SL_DISABLE_SELL": false,
          "SMAPERIOD": 50,
          "STDV": 2,
          "STOCHRSI_BUY_LEVEL": 0.2,
          "STOCHRSI_ENABLED": false,
          "STOCHRSI_LENGTH": 14,
          "STOCHRSI_METHOD": "oscillator",
          "STOCHRSI_SELL_LEVEL": 0.8,
          "STOCH_BUY_LEVEL": 20,
          "STOCH_D": 3,
          "STOCH_ENABLED": false,
          "STOCH_K": 14,
          "STOCH_METHOD": "oscillator",
          "STOCH_SELL_LEVEL": 80,
          "STOP_LIMIT": 99999,
          "TAKE_BUY": false,
          "TAKE_PROFIT": false,
          "TBUY_RANGE": 0.5,
          "TENKAN_BUY": true,
          "TENKAN_CLOSE": true,
          "TENKAN_PERIOD": 9,
          "TENKAN_SELL": true,
          "TENKAN_STOP": false,
          "TL_ALLIN": false,
          "TL_PERC": 0,
          "TM_RT_SELL": false,
          "TP_PROFIT_ONLY": true,
          "TP_RANGE": 0.5,
          "TRADES_TIMEOUT": 0,
          "TRADING_LIMIT": 0.002,
          "TRAIL_ME_BUY": false,
          "TRAIL_ME_BUY_RANGE": 0.5,
          "TRAIL_ME_DU": false,
          "TRAIL_ME_RT": false,
          "TRAIL_ME_RT_SELL_RANGE": 0.5,
          "TRAIL_ME_SELL": false,
          "TRAIL_ME_SELL_RANGE": 0.5,
          "TSSL_TARGET_ONLY": true,
          "USE_RENKO": false,
          "XTREND_ENABLED": true,
          "STOP_BUY": 9999,
          "STOP_SELL": 9999,
          "PND": false,
          "PND_PROTECTION": 1.5,
          "SupportResistance": false,
          "SupRes_ALLOW_DCA": true,
          "SupRes_SPREAD": 0.1,
          "SupRes_LVL_SPREAD": 1,
          "SupRes_MAX": 0,
          "SupRes_TIMER": 300,
          "SupResMinROE": 20,
          "MAX_BUY_COUNT": 20,
          "GRID_MULTIPLIER": 1,
          "STOP_AFTER_SELL": false,
          "AUTO_GAIN": true,
          "TRAILING_MULTIPLIER": 1,
          "START_CONT_TRADING": 3,
          "CT_TL_MULTIPLIER": 0.5,
          "CT_RESTART_MULTIPLIER": 1,
          "TL_MULTIPLIER": 1,
          "MAX_OPEN_CONTRACTS": 1,
          "DCA_METHOD": "NATIVE",
          "DCA_SPREAD": 2,
          "SAFETY_TIMER": 1800,
          "TREND_OPEN": false,
          "TREND_BLOCK_DCA": false,
          "TREND_LOWER_DCA": false,
          "DIRECTION": "LONG",
          "TREND_CT_MULTIPLIER": 2,
          "TREND_GRID_MULTIPLIER": 2,
          "AUTO_STEP_SIZE": true,
          "STEP_SIZE": 500,
          "ENFORCE_STEP": false,
          "STRAT_FILENAME": "filename.js",
          "unit_cost": true,
          "DYNAMIC_EXIT_LOGIC": false,
          "bitRage": false,
          "FIRST_ORDER_EXTRA_DELAY": 30,
          "TREND_SCALPING": true,
          "SCALP_TL_RATIO": 0.625,
          "EXHAUSTION_SENSITIVITY": "SHORT",
          "STRICT_ENTRY": true,
          "PERIOD_MEDIUM": 60,
          "PERIOD_LONG": 240,
          "TRADE_SUPPORTS": true,
          "SUPPORT2_TL_RATIO": 2,
          "TREND_SYNC": true,
          "MULTIPLE_TIMEFRAMES_MODE": false,
          "LOWER_PERIOD_LOW": 5,
          "LOWER_PERIOD_MEDIUM": 15,
          "LOWER_PERIOD_HIGH": 30,
          "MIDDLE_PERIOD_LOW": 15,
          "MIDDLE_PERIOD_MEDIUM": 60,
          "MIDDLE_PERIOD_HIGH": 240,
          "ACCUMULATION_CYCLE": false,
          "MTF_TL_RATIO": 1,
          "PRICE_ACTION_TL_RATIO": 1,
          "PRICE_ACTION_THRESHOLD": 0,
          "ALWAYS_USE_TL_MULTIPLIER": false,
          "PANIC_CLOSE": false
        }
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Long alerts': {
        description: 'Configure alerts to place buy orders',
        parameters: {
          WH_INDICATORS_LONG_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a long order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_LONG: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_LONG_AMOUNT: {
            tooltip: 'Amount per order',
            label: 'Amount per order',
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
      'Short alerts': {
        description: 'Configure alerts to place sell orders',
        parameters: {
          WH_INDICATORS_SHORT_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a short order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_SHORT: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_SHORT_AMOUNT: {
            tooltip: 'Amount per order',
            label: 'Amount per order',
            defaultValue: '0',
            inputType: 'string',
          },
        },
      },
      'Close long alerts': {
        description: 'Configure alerts to close a long position',
        parameters: {
          WH_INDICATORS_CLOSELONG_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a close long order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_CLOSELONG: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_CLOSELONG_AMOUNT: {
            tooltip: 'Amount per order. Set "full" to close the whole current position.',
            label: 'Amount per order',
            defaultValue: 'full',
            inputType: 'string',
          },
        },
      },
      'Close short alerts': {
        description: 'Configure alerts to close a short position',
        parameters: {
          WH_INDICATORS_CLOSESHORT_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a close short order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_CLOSESHORT: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_CLOSESHORT_AMOUNT: {
            tooltip: 'Amount per order. Set "full" to close the whole current position.',
            label: 'Amount per order',
            defaultValue: 'full',
            inputType: 'string',
          },
        },
      },
      'Flip long alerts': {
        description: 'Configure alerts to flip a long position to a short position',
        parameters: {
          WH_INDICATORS_FLIPLONG_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a long flip order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_FLIPLONG: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_FLIPLONG_AMOUNT: {
            tooltip: 'Target position size after flip. Set "full" to flip to a same sized position.',
            label: 'Amount per order',
            defaultValue: 'full',
            inputType: 'string',
          },
        },
      },
      'Flip short alerts': {
        description: 'Configure alerts to flip a short position to a long position',
        parameters: {
          WH_INDICATORS_FLIPSHORT_COUNT: {
            tooltip: 'Required number of incoming alerts to confirm a short flip order',
            label: 'Alert count',
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 15],
            rangeInput: [0, 100],
          },
          WH_ORDER_TYPE_FLIPSHORT: {
            tooltip: 'Order placement type',
            label: 'Order type',
            defaultValue: 'market',
            options: ['market', 'limit'],
            inputType: 'select',
          },
          WH_FLIPSHORT_AMOUNT: {
            tooltip: 'Target position size after flip. Set "full" to flip to a same sized position.',
            label: 'Amount per order',
            defaultValue: 'full',
            inputType: 'string',
          },
        },
      },
    },
    /*
    'Double CCI+RSIOMA': {
      info: {
        description: 'Run Double CCI+RSIOMA JS strategy code. All order triggers are defined by your code.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/custom-strategies/',
        market: 'spot',
        license: ['one', 'standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm'],
        buyMethod: "gain",
        sellMethod: "gain",
        category: 'Scalping'
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Core Settings': {
        description: 'Configure core settings',
        parameters: {
          RSI_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          SMA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          EMA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          MA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          SHORT_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          LONG_PERIOD: {
            defaultValue: '16',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          PERIOD1: {
            defaultValue: '6',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          PERIOD2: {
            defaultValue: '12',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 12],
            rangeInput: [0, 48],
          },
          STOP_LOSS: {
            defaultValue: 'ha',
            tooltip: `select stop loss method`,
            options: ['ha', 'fibo', 'pips'],
            inputType: 'select',
          },
        }
      },
      'Buy settings': {
        description: 'Primary buy trigger',
        parameters: {
          supportresistance: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SupRes_SPREAD: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 100],
            },
            SupRes_MAX: {
              defaultValue: '0',
              inputType: 'string',
            },
          },
          bb: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emotionless: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          gain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          ichimoku: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            KIJUN_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_BUY: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          pp: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_BUY: {
              defaultValue: '1000',
              inputType: 'string',
            },
          },
          tsa: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          tssl: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUY_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
          },
          stepgain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUYLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            BUYLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          adx: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          atrts: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emaspread: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macd: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          macdh: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          smacross: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
        },
      },
      'Sell settings': {
        description: 'Primary sell trigger',
        parameters: {
          bb: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emotionless: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          gain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          ichimoku: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_SELL: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KIJUN_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          pp: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_SELL: {
              defaultValue: '1000',
              inputType: 'string',
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tsa: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tssl: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELL_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          stepgain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SELLLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            SELLLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          adx: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          atrts: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            STOP_LIMIT: {
              defaultValue: '99',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          bbta: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emaspread: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macd: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macdh: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          smacross: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          supportresistance: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          TL_PERC: {
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 100],
            rangeInput: [0, 100],
          },
          TL_ALLIN: {
            defaultValue: false,
            inputType: 'boolean',
          },
          FUNDS_RESERVE: {
            defaultValue: '0',
            inputType: 'string',
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to prevent buy orders that are too small to buy. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
        },
      },
      Indicators: {
        description: 'Indicators used in selected buy and sell methods',
        parameters: {
          adx: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ADX_LEVEL: {
              defaultValue: '25',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 70],
              rangeInput: [1, 99],
            },
            DI_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          atrts: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ATRX: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            ATR_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          bb: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          bbta: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
          },
          supportresistance: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate support and resistance targets`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
          },
          emaspread: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMAx: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          emotionless: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          ichimoku: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            TENKAN_PERIOD: {
              defaultValue: '9',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            KIJUN_PERIOD: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            SENKOUSPAN_PERIOD: {
              defaultValue: '52',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            DISPLACEMENT: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          gain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macd: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macdh: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          pp: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets the chart candle timeframe. has no effect on primary buy or sell trigger with pp, only used for confirming indicators.`,
              inputType: 'select',
            },
          },
          smacross: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SLOW_SMA: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            FAST_SMA: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          tsa: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
          },
          tssl: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          stepgain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
        },
      },
      TrailMe: {
        description: 'Use additional price trailing',
        parameters: {
          TRAIL_ME_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_BUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator: true,
          TRAIL_ME_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator1: true,
          TRAIL_ME_RT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_RT_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          TM_RT_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_SELL_UP: {
            defaultValue: '0.3',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
        },
      },
      DCA: {
        description: 'Dollar cost averaging',
        parameters: {
          DOUBLE_UP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          DOUBLE_UP_CAP: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 100],
          },
          DU_CAP_COUNT: {
            defaultValue: '0',
            inputType: 'range',
            rangeLowHigh: [0, 10],
            rangeInput: [0, 999],
          },
          DU_BUYDOWN: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          DU_METHOD: {
            defaultValue: 'HIGHBB',
            options: ['HIGHBB', 'RSI'],
            inputType: 'select',
            freeSolo: true,
          },
          RSI_DU_BUY: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
        },
      },
      'Confirming indicators': {
        description: 'Additional indicator conditions',
        parameters: {
          ADX_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ADX_LEVEL: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          DI_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator: true,
          BTC_PND_PROTECTION: {
            defaultValue: false,
            inputType: 'boolean',
          },
          BTC_PND_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          BTC_MONEY_FLOW: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          separator1: true,
          EMASPREAD: {
            defaultValue: false,
            inputType: 'boolean',
          },
          EMAx: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 20],
          },
          separator2: true,
          MFI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MFI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator3: true,
          RSI_BUY_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_SELL_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          RSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator4: true,
          STOCH_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCH_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCH_K: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SLOW_STOCH_K: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          STOCH_D: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator5: true,
          STOCHRSI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCHRSI_BUY_LEVEL: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_SELL_LEVEL: {
            defaultValue: '0.8',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCHRSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator6: true,
          STDV: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.5, 3],
            rangeInput: [0.1, 30],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '100',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
        },
      },
      RT: {
        description: 'Reversal trading',
        parameters: {
          RT_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_GAIN: {
            defaultValue: '1.5',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_BUY_UP_LEVEL: {
            defaultValue: '0',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_ONCE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_ONCE_AND_CONTINUE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_MAXBAG_PROTECTION: {
            defaultValue: '10',
            inputType: 'range',
            rangeLowHigh: [0, 25],
            rangeInput: [0, 100],
          },
          RT_TREND_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      'Stop loss': {
        description: 'Handle losing trades',
        parameters: {
          STOP_LIMIT: {
            defaultValue: '99',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 5],
            rangeInput: [-100, 99999],
          },
          SL_DISABLE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      Misc: {
        description: 'Various other settings',
        parameters: {
          MARKET_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_STOP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTBUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTSELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_BUYBACK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_FOK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator: true,
          PANIC_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator1: true,
          TRADES_TIMEOUT: {
            defaultValue: '0',
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator2: true,
          NBA: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator3: true,
          TAKE_PROFIT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_PROFIT_ONLY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator4: true,
          TAKE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TBUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator5: true,
          LIQUIDITY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_TAKER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_GAIN: {
            defaultValue: true,
            inputType: 'boolean',
          },
          MAX_INVESTMENT: {
            defaultValue: '1',
            inputType: 'string',
          },
        },
      },
    },
    */
    /*
    'Double CCI+RSIOMA (futures)': {
      info: {
        description: 'Run Double CCI+RSIOMA JS strategy code. All order triggers are defined by your code.',
        docsUrl: 'https://docs.gunthy.org/docs/built-in-strategies/custom-strategies/',
        market: 'futures',
        license: ['one', 'standard', 'standardtv', 'pro', 'protv', 'ultimate', 'promoultimate', 'br', 'mm'],
        buyMethod: "gain",
        sellMethod: "gain",
        category: 'Scalping'
      },
      'Pair name': {
        description: 'Set which pair to trade',
        parameters: {
          PAIR_NAME: {
            label: 'Pair name',
            defaultValue: 'USDT-BTC',
            tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
            
The general format is: BASECOIN-QUOTECOIN
            
All pairs with BTC as base currency are written like: BTC-ETH, BTC-ATOM, BTC-ETH
With a BTC-XXX pair, the aim is realize growth in BTC balance.
            
All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
            inputType: 'string',
          },
        },
      },
      'Core Settings': {
        description: 'Configure core settings',
        parameters: {
          RSI_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          SMA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          EMA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          MA_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          SHORT_PERIOD: {
            defaultValue: '8',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          LONG_PERIOD: {
            defaultValue: '16',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          PERIOD1: {
            defaultValue: '6',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 16],
            rangeInput: [0, 48],
          },
          PERIOD2: {
            defaultValue: '12',
            tooltip: `The proid to do something`,
            inputType: 'range',
            rangeLowHigh: [0, 12],
            rangeInput: [0, 48],
          },
          STOP_LOSS: {
            defaultValue: 'ha',
            tooltip: `select stop loss method`,
            options: ['ha', 'fibo', 'pips'],
            inputType: 'select',
          },
        }
      },
      'Buy settings': {
        description: 'Primary buy trigger',
        parameters: {
          supportresistance: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SupRes_SPREAD: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 100],
            },
            SupRes_MAX: {
              defaultValue: '0',
              inputType: 'string',
            },
          },
          bb: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emotionless: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          gain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          ichimoku: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            KIJUN_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_BUY: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          pp: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_BUY: {
              defaultValue: '1000',
              inputType: 'string',
            },
          },
          tsa: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          tssl: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUY_LEVEL: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUY_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
          },
          stepgain: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            BUYLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            BUYLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            BUYLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
          },
          adx: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          atrts: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          bbta: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            LOW_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
          },
          emaspread: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
          },
          macd: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          macdh: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
          smacross: {
            BUY_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SINGLE_BUY: {
              defaultValue: false,
              inputType: 'boolean',
            },
          },
        },
      },
      'Sell settings': {
        description: 'Primary sell trigger',
        parameters: {
          bb: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emotionless: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          gain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          ichimoku: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KUMO_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            TENKAN_SELL: {
              defaultValue: true,
              inputType: 'boolean',
            },
            KIJUN_SELL: {
              defaultValue: false,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          pp: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            PP_SELL: {
              defaultValue: '1000',
              inputType: 'string',
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tsa: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          tssl: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELL_RANGE: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.01, 5],
              rangeInput: [0.01, 50],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          stepgain: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            SELLLVL: {
              defaultValue: '1', // only used as fallback, normal behavior is to use exchange specific values
              options: [1, 2, 3],
              inputType: 'select',
            },
            SELLLVL1: {
              defaultValue: '0.3',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL2: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            SELLLVL3: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          adx: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          atrts: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            STOP_LIMIT: {
              defaultValue: '99',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          bbta: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            HIGH_BB: {
              defaultValue: '0.1',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 50],
              rangeInput: [-500, 500],
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          emaspread: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macd: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          macdh: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          smacross: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            DOUBLE_CHECK_GAIN: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
          supportresistance: {
            SELL_ENABLED: {
              defaultValue: true,
              inputType: 'boolean',
            },
            GAIN: {
              defaultValue: '1',
              inputType: 'range',
              step: 0.01,
              rangeLowHigh: [0.1, 5],
              rangeInput: [-100, 500],
            },
            COUNT_SELL: {
              defaultValue: '9999',
              inputType: 'range',
              rangeLowHigh: [1, 50],
              rangeInput: [1, 999999],
            },
          },
        },
      },
      'Balance settings': {
        description: 'Configure how much the strategy can invest',
        parameters: {
          TRADING_LIMIT: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `The amount to invest for each individual buy order.`,
            inputType: 'string',
          },
          TL_PERC: {
            defaultValue: '0',
            inputType: 'range',
            step: 1,
            rangeLowHigh: [0, 100],
            rangeInput: [0, 100],
          },
          TL_ALLIN: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '0.00000001', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum order value. Needed in case TL_ALLIN does not work. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair.`,
            inputType: 'string',
          },
          FUNDS_RESERVE: {
            defaultValue: '0',
            inputType: 'string',
          },
          KEEP_QUOTE: {
            defaultValue: '0',
            tooltip: `Number of units to not sell and keep in balance. Setting 0.01 on USDT-BTC makes the bot not sell the last 0.01 BTC in balance.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_BUY: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to prevent buy orders that are too small to buy. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair.`,
            inputType: 'string',
          },
          MIN_VOLUME_TO_SELL: {
            defaultValue: '10', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Minimum notional order value. Used to ignore balances that are too small to sell. \n\nDefault value is an estimation, please verify on your exchange what the actual minimum order value is for this pair. `,
            inputType: 'string',
          },
        },
      },
      Indicators: {
        description: 'Indicators used in selected buy and sell methods',
        parameters: {
          adx: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ADX_LEVEL: {
              defaultValue: '25',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 70],
              rangeInput: [1, 99],
            },
            DI_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          atrts: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            ATRX: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            ATR_PERIOD: {
              defaultValue: '14',
              inputType: 'range',
              step: 1,
              rangeLowHigh: [10, 50],
              rangeInput: [1, 400],
            },
          },
          bb: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          bbta: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate bollinger bands`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
            STDV: {
              defaultValue: '2',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.5, 3],
              rangeInput: [0.1, 30],
            },
          },
          supportresistance: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SMAPERIOD: {
              defaultValue: '30',
              tooltip: `Number of candles used to calculate support and resistance targets`,
              inputType: 'range',
              rangeLowHigh: [5, 80],
              rangeInput: [1, 500],
            },
          },
          emaspread: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMAx: {
              defaultValue: '0.5',
              inputType: 'range',
              step: 0.1,
              rangeLowHigh: [0.1, 5],
              rangeInput: [0.1, 20],
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          emotionless: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          ichimoku: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            TENKAN_PERIOD: {
              defaultValue: '9',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            KIJUN_PERIOD: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            SENKOUSPAN_PERIOD: {
              defaultValue: '52',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            DISPLACEMENT: {
              defaultValue: '26',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          gain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macd: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          macdh: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            MACD_SHORT: {
              defaultValue: '5',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_LONG: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            MACD_SIGNAL: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          pp: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets the chart candle timeframe. has no effect on primary buy or sell trigger with pp, only used for confirming indicators.`,
              inputType: 'select',
            },
          },
          smacross: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            SLOW_SMA: {
              defaultValue: '20',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            FAST_SMA: {
              defaultValue: '10',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          tsa: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
          },
          tssl: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
          stepgain: {
            PERIOD: {
              defaultValue: '15', // only used as fallback, normal behavior is to use exchange specific values
              tooltip: `Sets which candles are used to calculate auto step size and trailing range. \n\nA lower value increases trading frequency and risk.\n\nThis strategy additionally uses a hardcoded trend module that watches 15m, 1h and 4h timeframes.`,
              inputType: 'select',
            },
            EMA1: {
              defaultValue: '16',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
            EMA2: {
              defaultValue: '8',
              inputType: 'range',
              rangeLowHigh: [2, 50],
              rangeInput: [1, 400],
            },
          },
        },
      },
      TrailMe: {
        description: 'Use additional price trailing',
        parameters: {
          TRAIL_ME_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_BUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator: true,
          TRAIL_ME_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator1: true,
          TRAIL_ME_RT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TRAIL_ME_RT_SELL_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          TM_RT_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_SELL_UP: {
            defaultValue: '0.3',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
        },
      },
      DCA: {
        description: 'Dollar cost averaging',
        parameters: {
          DOUBLE_UP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          DOUBLE_UP_CAP: {
            defaultValue: '1',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 100],
          },
          DU_CAP_COUNT: {
            defaultValue: '0',
            inputType: 'range',
            rangeLowHigh: [0, 10],
            rangeInput: [0, 999],
          },
          DU_BUYDOWN: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          DU_METHOD: {
            defaultValue: 'HIGHBB',
            options: ['HIGHBB', 'RSI'],
            inputType: 'select',
            freeSolo: true,
          },
          RSI_DU_BUY: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
        },
      },
      'Confirming indicators': {
        description: 'Additional indicator conditions',
        parameters: {
          ADX_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          ADX_LEVEL: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          DI_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator: true,
          BTC_PND_PROTECTION: {
            defaultValue: false,
            inputType: 'boolean',
          },
          BTC_PND_PERIOD: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          BTC_MONEY_FLOW: {
            defaultValue: '25',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          separator1: true,
          EMASPREAD: {
            defaultValue: false,
            inputType: 'boolean',
          },
          EMAx: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.1, 5],
            rangeInput: [0.1, 20],
          },
          separator2: true,
          MFI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MFI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          MFI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator3: true,
          RSI_BUY_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_SELL_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RSI_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [1, 99],
          },
          RSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          RSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator4: true,
          STOCH_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCH_BUY_LEVEL: {
            defaultValue: '30',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_SELL_LEVEL: {
            defaultValue: '70',
            inputType: 'range',
            rangeLowHigh: [1, 99],
            rangeInput: [-1001, 99],
          },
          STOCH_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCH_K: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          SLOW_STOCH_K: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          STOCH_D: {
            defaultValue: '3',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator5: true,
          STOCHRSI_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          STOCHRSI_BUY_LEVEL: {
            defaultValue: '0.2',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_SELL_LEVEL: {
            defaultValue: '0.8',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.01, 0.99],
            rangeInput: [0.01, 0.99],
          },
          STOCHRSI_METHOD: {
            defaultValue: 'oscillator',
            options: ['oscillator', 'cross'],
            inputType: 'select',
          },
          STOCHRSI_LENGTH: {
            defaultValue: '14',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          separator6: true,
          STDV: {
            defaultValue: '2',
            inputType: 'range',
            step: 0.1,
            rangeLowHigh: [0.5, 3],
            rangeInput: [0.1, 30],
          },
          SMAPERIOD: {
            defaultValue: '50',
            inputType: 'range',
            rangeLowHigh: [2, 50],
            rangeInput: [1, 400],
          },
          CANDLES_LENGTH: {
            defaultValue: '400',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
          EMA_LENGTH: {
            defaultValue: '100',
            inputType: 'range',
            rangeLowHigh: [1, 400],
            rangeInput: [1, 400],
          },
        },
      },
      RT: {
        description: 'Reversal trading',
        parameters: {
          RT_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_BUY_LEVEL: {
            defaultValue: '2',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_GAIN: {
            defaultValue: '1.5',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_BUY_UP_LEVEL: {
            defaultValue: '0',
            step: 0.1,
            inputType: 'range',
            rangeLowHigh: [0.1, 10],
            rangeInput: [0.1, 100],
          },
          RT_ONCE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_ONCE_AND_CONTINUE: {
            defaultValue: false,
            inputType: 'boolean',
          },
          RT_MAXBAG_PROTECTION: {
            defaultValue: '10',
            inputType: 'range',
            rangeLowHigh: [0, 25],
            rangeInput: [0, 100],
          },
          RT_TREND_ENABLED: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      'Stop loss': {
        description: 'Handle losing trades',
        parameters: {
          STOP_LIMIT: {
            defaultValue: '99',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 5],
            rangeInput: [-100, 99999],
          },
          SL_DISABLE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
        },
      },
      Misc: {
        description: 'Various other settings',
        parameters: {
          MARKET_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_STOP: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTBUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_RTSELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_BUYBACK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_DU: {
            defaultValue: false,
            inputType: 'boolean',
          },
          MARKET_FOK: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator: true,
          PANIC_SELL: {
            defaultValue: false,
            inputType: 'boolean',
          },
          separator1: true,
          TRADES_TIMEOUT: {
            defaultValue: '0',
            inputType: 'string',
          },
          IGNORE_TRADES_BEFORE: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator2: true,
          NBA: {
            defaultValue: '0',
            inputType: 'string',
          },
          separator3: true,
          TAKE_PROFIT: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_PROFIT_ONLY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TP_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator4: true,
          TAKE_BUY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          TBUY_RANGE: {
            defaultValue: '0.5',
            inputType: 'range',
            step: 0.01,
            rangeLowHigh: [0.1, 1],
            rangeInput: [0.01, 100],
          },
          separator5: true,
          LIQUIDITY: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_TAKER: {
            defaultValue: false,
            inputType: 'boolean',
          },
          LIQUIDITY_GAIN: {
            defaultValue: true,
            inputType: 'boolean',
          },
          MAX_INVESTMENT: {
            defaultValue: '1',
            inputType: 'string',
          },
        },
      },
    }
    */
  },

  // static and non exchange specific data needed to render new strat editor
  setupWizard: {
    exchange: {
      info: {
        description:
          'This page helps you connect your bot to your exchange account(s), validate your license and more global bot settings.',
        docsUrl: 'https://wiki.gunthy.org/about/supported-exchanges/create-api/',
      },
      settings: {
        description: '',
        parameters: {
          key: {
            defaultValue: '20', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Exchange API key, sometimes called 'public key'. \n\nMake sure this API key is newly created and only used for Gunbot\n\nMake sure your API key has trading permissions.\n\nWhen the key is registered on the license system, it gets prefilled.`,
            name: 'API key',
            inputType: 'string',
          },
          secret: {
            defaultValue: '40',
            tooltip: `Exchange API secret, sometimes called 'private key'. \n\nFor security reasons, the bot encrypts your API secret after saving.`,
            inputType: 'string',
          },
          passphrase: {
            defaultValue: '',
            tooltip: `Exchange API passphrase, sometimes called 'API password'.`,
            inputType: 'string',
          },
          clientId: {
            defaultValue: '',
            tooltip: `Exchange client ID. A unique identifier for your exchange account.'.`,
            inputType: 'string',
          },
          privKey: {
            defaultValue: '',
            tooltip: `Private key of the wallet connected to defi exchange. To be able to force closing positions and withdrawing from the exchange, it is needed to regularly sign messages on L2 with your current position information. To do this, Gunbot needs to know the wallet private key. If you do not want to use this functionality, enter 0 as value.`,
            inputType: 'string',
          },
          walletAddress: {
            defaultValue: '',
            tooltip: `Wallet address of the wallet connected to defi exchange.`,
            inputType: 'string',
          },
          starkPrivateKey: {
            defaultValue: '',
            tooltip: `Starkware private key.`,
            inputType: 'string',
          },
          delay: {
            defaultValue: '1',
            tooltip: `Forces additional delay between rounds of fetching trading data from the exchange. \n\nThis setting can be useful if exchange API rate limits get exceeded, only change if absolutely needed.`,
            name: 'Processing delay',
            inputType: 'range',
            rangeLowHigh: [0, 60],
          },
          rateLimit: {
            defaultValue: 1000,
            tooltip: `Forces additional delay in milliseconds between API calls. Decrease to make your bot request data faster, increase if you notice that the exchange throws rate limiting errors. Leave on default if you have no idea what this means.`,
            name: 'API rate limit delay',
            inputType: 'range',
            rangeLowHigh: [0, 9999],
          },
          TRADING_FEES: {
            defaultValue: '0.1',
            tooltip: `Fees as a percentage of each order, used to calculate break even prices. \n\nIf there are separate maker and taker fees on the exchange, enter the highest of these fee levels.\n\nMake sure this value matches or exceeds the fee level on your exchange.`,
            name: 'Trading fees',
            inputType: 'range',
            rangeLowHigh: [0, 1],
          },
          isEncrypted: {
            defaultValue: false,
            tooltip: `API secret is currently encrypted and shown as read only.`,
            name: 'API secret encrypted',
            inputType: 'boolean',
          },
          pay_with_utility_token: {
            defaultValue: false,
            tooltip: `Enable to pay trading fees with the exchange utility token`,
            name: 'Pay fees with utility token',
            inputType: 'boolean',
          },
          testnet: {
            defaultValue: false,
            tooltip:
              'Use the testnet of this exchange, instead of the real exchange platform. \n\nYou need to use a registered API key, specifically created for this testnet.',
            name: 'Use testnet',
            inputType: 'boolean',
          },
          market: {
            defaultValue: 'spot', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select the market type to trade on this exchange.`,
            inputType: 'select',
            name: 'Market type',
            values: [
              {
                value: 'spot',
                name: 'Spot',
                exchangeBlacklist: [
                  'bybit',
                ],
              },
              {
                value: 'futures',
                name: 'Futures',
                exchangeWhitelist: [
                  'ftx',
                  'binanceFutures',
                  'dydx',
                  'bitmex',
                  'bitmex_testnet',
                  'krakenFutures',
                  'futures_gunthy',
                  'okex5',
                  'okx',
                  'okgunbot',
                ],
              },
              {
                value: 'delivery',
                name: 'Coin-m futures',
                exchangeWhitelist: [
                'binanceFutures',
                'futures_gunthy'],
              },
              {
                value: 'swap',
                name: 'Swap',
                exchangeWhitelist: ['okx', 'okex5', 'okgunbot', 'bitget'],
              },
              {
                value: 'unified_spot',
                name: 'Spot. Unified',
                exchangeWhitelist: ['bybit'],
              },
              {
                value: 'unified_inverse',
                name: 'Inverse (Coin-m futures). Unified',
                exchangeWhitelist: ['bybit'],
              },
              {
                value: 'unified_linear',
                name: 'Linear (USDT futures). Unified',
                exchangeWhitelist: ['bybit'],
              },
              {
                value: 'normal_spot',
                name: 'Spot. Normal',
                exchangeWhitelist: ['bybit'],
              },
              {
                value: 'normal_linear',
                name: 'Linear (USDT futures). Normal',
                exchangeWhitelist: ['bybit'],
              },

            ],
          },
          accountType: {
            defaultValue: 'Main account', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select the appropriate account type the API key is from.`,
            inputType: 'select',
            name: 'Account type',
            values: [
              {
                value: 'main',
                name: 'Main account',
              },
              {
                value: 'sub',
                name: 'Subaccount',
              },
            ],
          },
          exchangeName: {
            // Gunbot ONE exchanges
            defaultValue: 'binance', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select which exchange to connect.`,
            inputType: 'select',
            values: [
              {
                value: 'binance',
                name: 'Binance',
                logo: 'binance.svg',
              },
              {
                value: 'binanceus',
                name: 'Binance US',
                logo: 'binance.us.png'
              },
              {
                value: 'huobi',
                name: 'Huobi Global',
                logo: 'huobi.svg'
              },
              {
                value: 'mex_gunthy',
                name: 'Mex Gunthy',
                logo: 'mex_gunthy.png',
              },
              {
                value: 'okex5',
                name: 'OKX',
                logo: 'okx.png',
              },
              {
                value: 'okgunbot',
                name: 'okGunbot',
                logo: 'okgunbot.png',
              },
            ],
          },
          exchangeNameMore: {
            // GB standard exchanges that are not in ONE
            defaultValue: 'binance', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select which exchange to connect.`,
            inputType: 'select',
            values: [
              {
                value: 'futures_gunthy',
                name: 'Gunthy Futures',
                logo: 'futures_gunthy.png',
              },
              {
                value: 'cryptocom',
                name: 'Crypto.com',
                logo: 'crypto.com.webp',
              },
              {
                value: 'bybit',
                name: 'Bybit',
                logo: 'bybit.svg',
              },
              {
                value: 'bitstamp',
                name: 'Bitstamp',
                logo: 'bitstamp.svg',
              },
              {
                value: 'bitget',
                name: 'Bitget',
                logo: 'bitget.svg',
              },
              {
                value: 'binanceFutures',
                name: 'Binance Futures',
                logo: 'futures_binance.svg',
              },
              {
                value: 'hitbtc',
                name: 'HitBTC',
                logo: 'hitbtc.svg',
              },
              {
                value: 'krakenFutures',
                name: 'Kraken Futures',
                logo: 'krakenfutures.png',
              },
              {
                value: 'kucoin',
                name: 'KuCoin',
                logo: 'kucoin.png',
              },
              {
                value: 'mexc',
                name: 'MEXC',
                logo: 'mexc.svg',
              },
              /*{
                value: 'txbit',
                name: 'Txbit',
                logo: 'txbit.png',
              },
              {
                value: 'bittrex',
                name: 'Bittrex',
                logo: 'bittrex.svg',
              },*/
              {
                value: 'bitfinex',
                name: 'Bitfinex',
                logo: 'bitfinex.svg',
              },
              {
                value: 'bitmex',
                name: 'BitMEX',
                logo: 'bitmex.svg',
              },
              {
                value: 'bitmex_testnet',
                name: 'BitMEX testnet',
                logo: 'bitmex.svg',
              },
              {
                value: 'cex',
                name: 'CEX',
                logo: 'cex.svg',
              },
              {
                value: 'kraken',
                name: 'Kraken',
                logo: 'kraken.svg',
              },
              {
                value: 'poloniex',
                name: 'Poloniex',
                logo: 'poloniex.svg',
              },
              {
                value: 'gateio',
                name: 'Gate.io',
                logo: 'gateio.png',
              },
              {
                value: 'coinbase',
                name: 'Coinbase Advanced',
                logo: 'coinbase.avif',
              },
            ],
          },
          exchangeNameDefi: {
            // DeFi exchanges
            defaultValue: 'binance', // only used as fallback, normal behavior is to use exchange specific values
            tooltip: `Select which exchange to connect.`,
            inputType: 'select',
            values: [
              {
                value: 'dydx',
                name: 'dYdX',
                logo: 'dydx.svg'
              },
            ],
          },
        },
      },
    },
  },

  exchangeConfig: {
    key: '',
    secret: '',
    masterkey: '',
    mastersecret: '',
    passphrase: '',
    master_passphrase: '',
    clientId: '',
    market: 'spot',
    delay: 5,
    rateLimit: 1000,
    filteredBase: [],
    filteredQuote: [],
    filteredPair: [],
    TRADING_FEES: '0.1',
    includeCoins: '',
    pay_with_utility_token: false,
    autoPairs: false,
    tick_interval: 30,
    tick_time: false,
    testnet: false,
    isEncrypted: false,
    walletAddress: '',
  },

  // supported period values per exchange (most used, not complete)
  periodMatrix: {
    binance: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440, 4320, 10080],
    bybit: [1,3,5,15,30,60,120,240,360,720,1440,10080],
    mex_gunthy: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440, 4320, 10080],
    binanceus: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440],
    binanceFutures: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440],
    dydx: [1, 5, 15, 30, 60, 120, 240, 1440],
    futures_gunthy: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440],
    bitfinex: [1, 5, 15, 30, 60, 180, 360, 720, 1440, 10080, 20160],
    ftx: [1, 5, 15, 30, 60, 240, 1440],
    okex5: [1, 3, 5, 15, 30, 60, 120, 240, 360, 480, 720, 1440, 10080],
    okgunbot: [1, 5, 15, 60, 240],
    bitmex: [1, 5, 60, 1440],
    bitmex_testnet: [1, 5, 60, 1440],
    cex: [5, 15],
    gdax: [1, 5, 15, 60, 360, 1440],
    coinbase: [1, 5, 15, 30, 60, 120, 360, 1440],
    huobi: [1, 5, 15, 30, 60, 240, 1440],
    kraken: [1, 5, 15, 30, 60, 240, 1440],
    krakenFutures: [1, 5, 15, 30, 60, 240, 720, 1440, 10080],
    kucoin: [1, 3, 5, 15, 30, 60, 120, 240, 360, 480, 720],
    poloniex: [1, 5, 10, 15, 30, 60, 120, 240, 360, 720, 1440, 10080],
    cryptocom: [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440, 4320, 10080],
    other: [1, 5, 15, 60, 240],
  },

  mvtsMatrix: {
    binance: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
    binanceus: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      USD: 1,
      TUSD: 1,
      USDC: 1,
      USDT: 1,
      BUSD: 1,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 1,
    },
    mex_gunthy: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
    cryptocom: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
    okex5: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
    okgunbot: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
    ftx: {
      BNB: 0.05,
      BTC: 0.0001,
      ETH: 0.005,
      TRX: 100,
      XRP: 10,
      DOGE: 30,
      AUD: 10,
      EUR: 10,
      GBP: 10,
      AUD: 10,
      BRL: 10,
      TUSD: 10,
      USDC: 10,
      USDT: 10,
      BUSD: 10,
      BIDR: 20000,
      RUB: 100,
      TRY: 10,
      DAI: 10,
      IDRT: 20000,
      UAH: 100,
      NGN: 500,
      VAI: 10,
      USDP: 10,
    },
  },

  // list of exchange names and supported market types
  exchangesWithUsableTickers: {
    binance: {
      prettyName: 'Binance',
      spot: true,
      futures: false,
      delivery: false,
    },
    binanceus: {
      prettyName: 'Binance US',
      spot: true,
      futures: false,
      delivery: false,
    },
    ftx: {
      prettyName: 'FTX',
      spot: true,
      futures: true,
      delivery: false,
    },
    ftxus: {
      prettyName: 'FTX US',
      spot: true,
      futures: false,
      delivery: false,
    },
    mex_gunthy: {
      prettyName: 'Mex Gunthy',
      spot: true,
      futures: false,
      delivery: false,
    },
    cryptocom: {
      prettyName: 'Crypto.com',
      spot: true,
      futures: false,
      delivery: false,
    },
    okex5: {
      prettyName: 'OKX',
      spot: true,
      futures: true,
      swap: true,
      delivery: false,
    },
    okgunbot: {
      prettyName: 'okGunbot',
      spot: true,
      futures: true,
      swap: true,
      delivery: false,
    },
    hitbtc: {
      prettyName: 'HitBTC',
      spot: true,
      futures: false,
      delivery: false,
    },
    kucoin: {
      prettyName: 'KuCoin',
      spot: true,
      futures: false,
      delivery: false,
    },
    kraken: {
      prettyName: 'Kraken',
      spot: true,
      futures: false,
      delivery: false,
    },
    bitfinex: {
      prettyName: 'Bitfinex',
      spot: true,
      futures: false,
      delivery: false,
    },
    cex: {
      prettyName: 'CEX',
      spot: true,
      futures: false,
      delivery: false,
    },
    bitfinex: {
      prettyName: 'Bitfinex',
      spot: true,
      futures: false,
      delivery: false,
    },
    poloniex: {
      prettyName: 'Poloniex',
      spot: true,
      futures: false,
      delivery: false,
    },
    bittrex: {
      prettyName: 'Bittrex',
      spot: true,
      futures: false,
      delivery: false,
    },
    huobi: {
      prettyName: 'Huobi Global',
      spot: true,
      futures: false,
      delivery: false,
    },
    binanceFutures: {
      prettyName: 'Binance Futures',
      spot: false,
      futures: true,
      delivery: true,
    },
    /*
    dydx: {
      prettyName: 'dYdX',
      spot: false,
      futures: true,
      delivery: false,
    },
    */
    futures_gunthy: {
      prettyName: 'Futures Gunthy',
      spot: false,
      futures: true,
      delivery: true,
    },
    gdax: {
      prettyName: 'Coinbase Pro',
      spot: true,
      futures: false,
      delivery: false,
    },
    gateio: {
      prettyName: 'Gate.io',
      spot: true,
      futures: false,
      delivery: false,
    },
    bybit: {
      prettyName: 'Bybit',
      spot: false,
      futures: false,
      delivery: false,
      unified_spot: true,
      unified_linear: true,
      unified_inverse: true,
      normal_spot: true,
      normal_linear: true,
    },
    bitget: {
      prettyName: 'Bitget',
      spot: true,
      swap: true,
      futures: false,
      delivery: false,
      inverse: false,
    },
    coinbase: {
      prettyName: 'Coinbase Advanced',
      spot: true,
      swap: false,
      futures: false,
      delivery: false,
      inverse: false,
    },
    mexc: {
      prettyName: 'MEXC',
      spot: true,
    },
  },
};
